@import '~styles/mixins.sass';

.mcq-cnt {
	margin-top: 11px; }

.answer-options-cnt {
	@extend %row;
	flex-wrap: wrap;
	padding: unset; }

.question-text > p {
	margin-top: 0; }

.option-cnt {
	@extend %col-xs-6;
	padding: unset;
	min-height: 56px;
	margin: 8px 0;
	display: flex;
	align-items: center; }

.option-text, .selected-option-text {
	border-radius: 2px;
	padding: 4px 24px;
	min-height: 56px;
	width: 100%;
	cursor: pointer;
	display: flex;
	align-items: center;
	height: 100%; }

.option-text:hover {
	background-color: rgba(213, 217, 231, 0.2); }

.option-cnt:nth-child(odd) {
	padding-right: 16px; }

.option-cnt:nth-child(even) {
	padding-left: 16px; }

@media only screen and (max-width: 480px) {
	.answer-options-cnt {
		flex-direction: column; }
	.option-cnt:nth-child(odd), .option-cnt:nth-child(even) {
		padding-left: 0px;
		padding-right: 0px; }
	.option-cnt {
		max-width: 100%;
		flex-basis: 100%; }
	.option-text, .selected-option-text {
		padding: 5px 10px; } }


