.quiz-review-btn {
	width: 175px;
	margin-top: 20px; }

.quiz-review-btn > div {
	height: 32px;
	box-sizing: border-box; }

.quiz-pie-chart-legends-cnt {
	display: flex;
	flex-direction: column;
	justify-content: center; }

.quiz-review-btn.disabled > div {
	cursor: not-allowed !important;
	opacity: 0.5 !important; }
