@import '~styles/mixins.sass';

.text-based-question-cnt {
	width: 100%; }

.text-area-cnt {
	width: 100%; }

.text-area {
	height: 106px;
	width: 100%;
	border-radius: 4px;
	border: solid 1px #d5d8e2;
	background-color: rgba(213, 216, 226, 0.15);
	resize: none;
	padding: 10px;
	box-sizing: border-box;
	overflow-y: auto; }
