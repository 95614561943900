@import '~styles/mixins.sass';

.duration-container {
    margin-bottom: 20px;
    padding-left: 0;
    padding-right: 0; }
.duration-container .title {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    max-width: 350px; }
.duration-container .time {
    padding-left: 10px;
    box-sizing: border-box; }
.icon-time-container {
    display: flex;
    margin-top: 15px; }

.clock-icon-wrapper {
    width: 24px;
    height: 24px; }
