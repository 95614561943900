@import '~styles/mixins.sass';

.upload-image-container {
    box-sizing: border-box; }

.upload-image-icon {
    border-radius: 100%;
    // overflow: hidden
    cursor: pointer;
    width: 80px;
    height: 80px;
    position: relative; }

.upload-buttons-container {
    display: flex; }

.image-preview {
    min-width: 80px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 100%;
    overflow: hidden; }

.upload-button {
    width: 178px;
    height: 35px;
    margin-left: 10px; }

.camera-icon-cnt {
    height: 24px;
    width: 24px;
    border-radius: 100%;
    background-color: white;
    border: 0.72px solid rgba(151, 151, 151, 0.158062);
    box-sizing: border-box;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.163723);
    position: absolute;
    right: -5px;
    bottom: 10px;
    display: flex; }

.reset-button {
    width: 110px;
    height: 35px;
    margin-left: 10px; }

.image-info-action-container {
    @include display-flex-column; }

.image-info-container {
    display: flex;
    margin-left: 10px;
    margin-bottom: 10px; }

.image-info-text {
    color: #2e344b; }

.image-error-text {
    color: red;
    margin-left: 10px; }

.image-uploading {
    border: dashed thin grey; }

.tooltip-container {
    position: relative; }

.tooltip-text {
    position: absolute;
    background: rgb(60, 70, 109);
    top: 0px;
    color: white;
    left: 320px;
    width: 200px;
    font-size: 14px;
    padding: 5px;
    border-radius: 4px; }

.disable-css {
    @include disabled-button; }
