@import '~styles/mixins.sass';

$largest-px: 1000px;
$second-largest-px: 900px;
$third-largest-px: 800px;

.intro-container {
	// min-height: 640px
	width: 100%;
	position: relative;
	@extend %row;
	@include center-all;
	overflow-x: hidden; }

// @media ( min-height: $largest-px )
// 	.intro-container
// 		min-height: 920px
// @media ( min-height: $second-largest-px) and (max-height: $largest-px)
// 	.intro-container
// 		min-height: 850px
// @media ( min-height: $third-largest-px) and (max-height: $second-largest-px)
// 	.intro-container
// 		min-height: 690px

.intro-container-background {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }

.intro-container-gradient {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }

.intro-container-content {
	box-sizing: border-box;
	// position: absolute
	z-index: 3;
	width: 100%;
	height: 100%;
	@include col-landingpage;
	@include center-all; }

@media ( min-height: $largest-px ) {
	.intro-container-content {
		padding-top: 100px;
		padding-bottom: 80px; } }
@media ( min-height: $second-largest-px) and (max-height: $largest-px) {
	.intro-container-content {
		padding-top: 60px;
		padding-bottom: 40px; } }
@media ( min-height: $third-largest-px) and (max-height: $second-largest-px) {
	.intro-container-content {
		padding-top: 40px;
		padding-bottom: 20px; } }

.user-greetings {
	padding-top: 15px;
	padding-bottom: 17px; }

.user-greetings-line {
	width: 200px;
	height: 1px;
	margin-bottom: 19px; }

.journey-info {
	@include display-flex-center-mixin;
	flex-direction: column; }

.get-started {
	@include display-flex-center-mixin;
	margin-bottom: 20px; }

.journey-title {
	padding-bottom: 20px;
	@include display-flex-center-mixin;
	width: 100%; }

.journey-sub-title {
	min-height: 37px;
	padding-bottom: 16px;
	@include display-flex-center-mixin; }

.rating {
	padding-bottom: 30px;
	@include display-flex-center-mixin; }

.rating-stars {
	width: 195px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between; }

.rating-value {
	padding-left: 20px; }

.journey-desc {
	width: 75%;
	box-sizing: border-box;
	max-height: 135px;
	text-align: center;
	overflow: hidden;
	p {
		margin: unset; } }

.read-more-link {
	display: none;
	text-decoration: underline;
	cursor: pointer;
	font-weight: bold; }

.duration-container {
	@include display-flex-center-mixin;
	width: 100%;
	height: 84px;
	margin-top: 15px; }

.duration {
	display: block;
	width: 280px;
	box-sizing: border-box;
	padding-right: 25px; }

.study {
	width: 280px;
	box-sizing: border-box;
	padding-left: 25px; }

.duration-study-line {
	width: 1px;
	height: 100%; }

.image {
	width: 32px;
	height: 32px; }

.buttons-container {
	@include display-flex-center-mixin;
	width: 100%;
	align-items: flex-start;
	margin-top: 35px;
	// height: 85px
	gap: 50px; }

.single-button-outer-container {
	@include display-flex-column;
	@include display-flex-center-mixin; }

.single-button-container-left {
	// width: 50%
	// height: 100%
	box-sizing: border-box;
	padding-right: 25px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start; }

.single-button-container-right {
	width: 50%;
	// height: 100%
	box-sizing: border-box;
	padding-left: 25px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column; }

.single-button-container-center {
	// width: 50%
	height: 100%;
	box-sizing: border-box;
	padding-left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column; }


.button {
	@include display-flex-center-mixin;
	width: 287px;
	height: 52px;
	box-sizing: border-box;
	border-radius: 5px; }

.terms-conditions {
	padding-top: 15px;
	text-align: left;
	text-decoration: underline; }

.underline {
	text-decoration: underline; }

.duration-content {
	@include display-flex-center-mixin;
	margin-bottom: 10px; }

.duration-value {
	margin-left: 20px;
	text-transform: uppercase; }

.title-content {
	@include display-flex-center-mixin;
	height: 24px; }

.locked-icon {
	width: 20px;
	height: 20px;
	margin-right: 8px; }

.lock-explanation {
	text-align: left;
	margin-top: 5px; }

@media screen and (max-width: 480px) {
	.journey-title {
		text-align: center; }
	.journey-desc {
		width: 90%; }
	.study {
		padding-left: unset; }
	.buttons-container {
		flex-direction: column;
		align-items: center; } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
	.journey-title {
		text-align: center; }
	.journey-desc {
		width: 90%; }
	.study {
		padding-left: unset; }
	.buttons-container {
		flex-direction: column;
		align-items: center; } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
	.journey-title {
		text-align: center; }
	.journey-desc {
		width: 90%; }
	.study {
		padding-left: unset; } }
