.document-container {
  overflow: hidden;
  height: calc(100vh - 400px);
  min-height: 480px;
  width: 100%;
  margin: 20px auto;
  position: relative; }

.div {
  padding: 10px 0px; }

.feedback-parent-cnt {
  position: absolute;
  height: calc(100% - 20px);
  width: 100%;
  top: 13px; }

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  .document-container {
    min-height: 250px; } }
