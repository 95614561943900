@import '~styles/mixins.sass';

.complete-summary-container {
    width: 100%;
    padding-bottom: 48px;
    padding-top: 48px;
    @extend %row;
    @include center-all; }

.detailed-analysis-container {
    width: 100%;
    @extend  %row;
    padding-left: 0;
    padding-right: 0;
    @include center-all; }

.show-details-button-container {
    width: 100%;
    @include display-flex-center-mixin;
    margin-bottom: 40px; }

.show-button-content {
    @include display-flex-center-mixin;
    cursor: pointer; }

.show-button-arrow {
    width: 12px;
    height: 8px; }

.detailed-name {
    padding-right: 10px; }
