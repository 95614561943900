@import '~styles/mixins.sass';

.scale-rating-question-cnt {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center; }

.question-text {
	margin: 8px 0;
	text-align: left; }

.rating-scale-cnt {
	width: fit-content;
	box-sizing: border-box;
	position: relative;
	.rating-scale-inner-cnt {
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		border-radius: 5px;
		border: solid 1px #d5d8e2;
		.individual-score {
			height: 43px;
			min-width: 50px;
			@include display-flex-center-mixin;
			border-left: solid 1px #d5d8e2;
			box-sizing: border-box;
			cursor: pointer; }
		.individual-score:first-child {
			border-left: unset; } } }

.min-max-label-cnt {
	position: relative;
	width: 100%;
	height: 30px;
	display: flex;
	justify-content: space-between;
	align-content: center;
	margin-top: 7px; }

@media only screen and (max-width: 480px) {
	.individual-score {
		height: 20px !important;
		min-width: 20px !important;
		max-width: 20px !important; }
	.rating-scale-inner-cnt {
		width: 100% !important; } }


@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
	.individual-score {
		height: 30px !important;
		min-width: 30px !important;
		max-width: 30px !important; }
	.rating-scale-inner-cnt {
		width: 100% !important; } }
