@import '~styles/mixins.sass';

.container {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	@include display-flex-center-mixin; }

.tabs-container {
	overflow: hidden;
	box-sizing: border-box;
	@include display-flex-start; }

.tab-container {
	cursor: pointer;
	box-sizing: border-box;
	@include display-flex-center-mixin;
	&:first-child {
		margin-left: unset !important; } }

.tab-content {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap; }

.arrow-container {
	width: 25px;
	height: 100%;
	box-sizing: border-box;
	@include display-flex-center-mixin; }

.arrow-content {
	width: 8px;
	height: 14px;
	cursor: pointer; }

.unselected-tabs {
	border: none !important;
	opacity: 0.7;
	font-weight: normal; }
