@import '~styles/mixins.sass';

.building-data-strategy-cnt {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white; }

.building-data-strategy-content-cnt {
	width: 100%;
	max-height: calc(100vh - 94px);
	overflow: auto; }

.building-data-strategy-content {
	height: 100%;
	width: 100%;
	max-width: 1280px;
	margin: auto;
	.fill-card-header {
		height: 55px;
		background-color: #f5f6f9;
		@include display-flex-center-mixin;
		margin: 30px 0 20px 0; }
	.cards-outer-cnt {
		width: 100%;
		max-width: 1160px;
		margin: auto;
		background-color: #ece4ff;
		margin-bottom: 50px;
		.cards-cnt-header {
			width: 100%;
			height: 50px;
			background-color: #7157a9;
			@include display-flex-center-mixin; }
		.cards-inner-cnt {
			padding: 20px;
			box-sizing: border-box;
			border: dashed 1px #97979733;
			border-top: none; } } }

.top-data-type-cards-cnt {
	@include display-flex-space-between;
	align-items: center;
	.container-header {
		width: 120px;
		height: 60px;
		text-align: center;
		width: 50px;
		@include display-flex-center-mixin;
		.header-text {
			min-width: 125px;
			transform: rotate(270deg); } }
	.individual-cards-cnt {
		width: 100%;
		@include display-flex-space-between;
		padding: 0 40px 0 60px;
		.individual-data-type-card-cnt {
			max-width: 270px;
			min-width: 270px;
			position: relative;
			.header {
				margin-bottom:  56px;
				text-align: center; }
			.tool-items-list-card-cnt {
				position: relative; }
			.connector-line {
				position: absolute;
				width: 0;
				border-left: 1px dashed #7157A9CC;
				top: 34px;
				height: 1000px;
				left: 134.5px; } } } }

.role-based-cards-cnt {
	@include display-flex-space-between;
	width: 100%;
	background-color: #f4fff8;
	margin-top: 40px;
	.role-based-cards-inner-cnt {
		width: 100%;
		border: dashed 1px #3a9d60;
		border-left: none; }
	.value-proposition-cnt {
		width: 50px;
		background-color: #3a9d60;
		@include display-flex-center-mixin;
		text-align: center;
		.value-proposition-text {
			transform: rotate(270deg);
			min-width: 224px; } }
	.individual-role-based-cards {
		@include display-flex-center-mixin;
		padding: 30px 40px 30px 0;
		box-sizing: border-box;
		border-bottom: dashed 1px #3a9d60;
		&:last-child {
			border: none; }
		.cards-cnt {
			width: 100%;
			@include display-flex-space-between;
			.individual-role-based-card-cnt {
				max-width: 270px;
				min-width: 270px; } }
		.role-text-cnt {
			@include display-flex-center-mixin;
			max-width: 60px;
			text-align: center;
			.role-text {
				transform: rotate(270deg);
				min-width: 224px; } } } }
