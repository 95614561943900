@import '~styles/mixins.sass';

.navigation-container {
    width: 100%;
    @extend %row;
    @include center-all;
    height: 57px; }

.navigation-container-relative {
	width: 100%;
	@extend %row;
	@include center-all;
	height: 57px;
	position: absolute;
	z-index: 20;
	top: 123px; }

.navigation-tabs-container {
    @include col-landingpage;
    height: 100%;
    position: relative; }

.tabs-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2; }

.line-container {
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    z-index: 1;
    border-radius: 2px; }

.each-tab {
    height: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    cursor: pointer;
    padding-right: 15px; }

.each-tab-name {
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px; }

.each-tab-line {
    height: 4px;
    width: 100%; }
