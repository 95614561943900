@import '~styles/mixins.sass';

.reports-btn-cnt {
	// width: 100%
	display: flex;
	justify-content: flex-start; }

.download-button {
	min-width: 270px;
	width: 100%;
	max-width: 300px;
	padding-bottom: 2px;
	box-sizing: border-box; }

.download-button-new-style {
	min-width: 220px;
	height: 48px;
	width: 100%;
	max-width: 220px;
	padding-bottom: 2px;
	box-sizing: border-box; }

.progress-cnt {
	min-width: 270px;
	width: 100%;
	max-width: 300px;
	text-align: center;
	> div {
		margin: auto; } }

.share-on-linked-button {
	min-width: 200px;
	height: 46px;
	padding: 0 10px;
	box-sizing: border-box;
	background-color: #0073B1;
	border-radius: 4px;
	@include display-flex-center-mixin;
	margin-left: 20px;
	cursor: pointer;
	box-shadow: 0 14px 16px 0 rgba(0, 0, 0, 0.19);
	.linked-in-logo {
		width: 20px;
		height: 20px;
		border-radius: 2px;
		margin-right: 10px; } }

.wait-msg-cnt {
	min-width: 200px;
	height: 46px;
	@include display-flex-center-mixin;
	margin-left: 20px; }
