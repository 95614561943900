@import '~styles/mixins.sass';

.container {
  display: flex;
  flex-direction: column;
  @include center-all;
  width: 100%; }

.celebration-image {
  height: 215px;
  width: 437px; }

