@import '~styles/mixins.sass';

.toast-container {
    width: 591px;
    min-height: 100px;
    margin-bottom: 24px;
    border-radius: 4px;
    background-image: linear-gradient(to bottom, #ffffff, #fafaff);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 16px 20px 0 rgba(0, 0, 0, 0.18);
    position: relative;
    overflow: hidden; }

.toast-close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 2;
    width: 10px;
    height: 10px; }

.toast-content-container {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex; }


.toast-border-left {
    width: 4px;
    margin-right: 20px;
    height: 100%; }

.toast-content {
    width: calc(100% - 24px);
    padding: 15px 0;
    box-sizing: border-box; }

.toast-content-heading {
    width: 80%;
    padding-bottom: 7px;
    display: flex;
    align-items: flex-start; }

.toast-heading-icon {
    width: 20px;
    height: 20px; }

.toast-heading-text {
    padding-left: 10px; }

.toast-content-message {
    padding-left: 30px;
    width: 90%; }

@media only screen and (max-width: 480px) {
    .toast-container {
        width: 100%;
        min-height: 125px; } }
