@import '~styles/mixins.sass';

.digital-tool-guide-cnt {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0,0,0,0.1);
	.guide-content-cnt {
		width: 455px;
		background-color: white;
		position: absolute;
		right: 0;
		top: 94px;
		height: calc(100vh - 94px);
		max-height: calc(100vh - 94px);
		font-family: Open Sans;
		font-size: 14px;
		z-index: 1000;
		.guide-content-header {
			width: 100%;
			height: 86px;
			background-image: linear-gradient(101deg, #477bc0, #4a53ae);
			@include display-flex-space-between;
			align-items: center;
			padding: 0 20px;
			box-sizing: border-box;
			.header-title {
				@include display-flex-start; }
			.icon-cnt {
				height: 16px;
				width: 16px; }
			.help-icon {
				margin: 5px; } }
		.guide-example-cnt {
			width: 100%;
			height: calc(100% - 86px);
			overflow-y: auto;
			overflow-x: hidden;
			padding: 20px 40px;
			box-sizing: border-box; }
		.guide-content {
			width: 100%;
			text-align: justify; }
		p {
			color: #262626; }
		img {
			cursor: pointer; }
		a {
			word-break: break-all; }
		.example-cnt {
			margin-top: 50px; } }
	.guide-overlay {
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		z-index: 0; } }

.fullscreen-image-cnt {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 10000;
	background-color: #000000BF;
	.image-cnt {
		height: 100%;
		@include display-flex-center-mixin;
		img {
			height: 90%; } } }
