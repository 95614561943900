@import '~styles/mixins.sass';

.individual-phase-item-cnt {
    margin-top: 32px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    background-color: white;
    border-radius: 4px;
    border: solid 1px rgba(151, 158, 183, 0.27);
    &:hover {
        box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.11), 0 4px 16px 0 rgba(0, 0, 0, 0.06); } }

.individual-phase-item {
    height: 177px;
    box-sizing: border-box;
    padding: 24px 35px 24px 24px;
    @include display-flex-center-mixin; }


.image-container {
    width: 129px;
    height: 100%;
    @include display-flex-center-mixin;
    overflow: hidden;
    border-radius: 4px; }

.content-container {
    width: calc(100% - 129px);
    height: 100%;
    box-sizing: border-box;
    padding: 5px 0 2px 16px;
    text-align: left; }

.title, .desc {
    width: 100%; }

.title {
    padding-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.desc {
    padding-bottom: 14px;
    height: 42px; }

.progress-container {
    height: 35px; }

.outer-bar {
    width: 100%;
    height: 8px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px; }

.inner-bar {
    border-radius: 8px;
    height: 100%;
    min-width: 8px; }

.progress-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }

.dev-phase-timeline-cnt {
    position: absolute;
    left: -73px;
    top: 70px; }

.dev-phase-timeline-outer-circle {
    border-width: 4px;
    border-style: solid;
    // border-color: rgb(213, 217, 231)
    border-color: transparent;
    border-image: initial;
    padding: 4px;
    box-sizing: border-box;
    border-radius: 100%;
    background-color: #f2f3f7;
    cursor: pointer; }

.dev-phase-timeline-inner-circle {
    height: 24px;
    width: 24px;
    border-radius: 100%;
    background-color: #979eb7; }

.dev-phase-timeline-connector {
    width: 4px;
    background-color: rgb(213, 217, 231);
    margin: auto; }

.completion-status {
    background-color: #76ca1a;
    color: white;
    height: 20px;
    border-radius: 4px;
    padding: 0 7px;
    box-sizing: border-box;
    position: absolute;
    top: 35px;
    left: 24px;
    border-radius: 0 4px 4px 0;
    text-transform: uppercase; }

.single-phase-container {
    @include col-journeyDashboard;
    @include display-flex-center-mixin; }
