@import '~styles/mixins.sass';

.course-feedback-questions-list {
    width: 100%;
    height: calc(100% - 43px); }

.save-feedback-btn-cnt {
    max-width: 855px;
    display: flex;
    justify-content: center; }

.question {
    @include item-feedback-ques;
    @include center-all; }

.individual-question-cnt {
    width: 100%;
    max-width: 855px;
    min-height: 163px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    margin: 20px 0;
    padding: 24px;
    box-sizing: border-box; }

.feedback-action-cnt {
    width: 100%;
    max-width: 855px;
    @include display-flex-center-mixin;
    margin-bottom: 50px;
    .skip-feedback-cnt, .submit-feedback-cnt {
        height: 48px;
        @include display-flex-center-mixin; }
    .skip-feedback-cnt {
        color: #d44980;
        cursor: pointer; }
    .submit-feedback-cnt {
        margin-left: 30px;
        text-transform: uppercase; } }

.completion-status-cnt {
    @include display-flex-center-mixin;
    width: 100%;
    max-width: 855px;
    min-height: 125px;
    position: relative;
    margin: 30px 0;
    border-radius: 4px;
    overflow: hidden;
    .completion-status-content {
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center; }
    .feedback-completion-tick-mark-cnt {
        width: 33px;
        height: 33px; }
    .completion-bg {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        min-height: 125px;
        width: 100%; } }

.tickmark-cnt {
	width: 24px;
	height: 24px;
	margin: 0 5px; }

@media only screen and (max-width: 480px) {
    .individual-question-cnt {
        padding: 12px;
        max-width: 261px;
        width: 261px; } }
