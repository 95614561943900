@import '~styles/mixins.sass';

.container-class {
    width: 100%; }

.area-graph-container {
    width: 100%;
    @include center-all;
    @extend %row; }

.area-graph {
    @extend %col-md-10; }
