@import '~styles/mixins.sass';

.row {
	@extend %row;
	padding: 0; }

.column {
	@extend %col-xs;
	padding: unset; }

.content-cnt {
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	min-height: calc(100vh - 310px); }

.content-cnt.sidebar-open {}

.content-left-side {
	width: 75%;
	padding-right: 10px;
	box-sizing: border-box; }

.content-left-side.sidebar-open {}

.content-right-side {
	width: 25%;
	box-sizing: border-box;
	max-width: 320px;
	margin-top: 40px; }

.content-right-side.sidebar-open {}

.session-details-cnt {
	padding: 0 15px;
	border-left: solid 1px #d5d8e2;
	border-right: solid 1px #d5d8e2; }

.session-details-cnt.sidebar-open {}



.date-time-cnt, .day-duration-cnt {
	display: flex;
	justify-content: space-between;
	padding: 0; }

.date-time-cnt, .address-cnt {
	margin-top: 13px; }

.column.time, .duration.column {
	text-align: right; }

.button {
	height: 48px;
	margin-bottom: 25px; }

.button > div {
	margin: 0 auto;
	width: 90% !important;
	min-width: unset !important;
	box-sizing: border-box; }

.add-to-calendar-cnt {
	min-height: 75px; }

.address-cnt {
	padding-left: 24px;
	box-sizing: border-box; }

.resources-cnt, .facilitator-cnt {
	margin-top: 65px; }

.resources-cnt-header, .facilitator-cnt-header {
	margin-bottom: 16px; }

.resources-list {
	@extend %row;
	padding: unset;
	padding: 24px 0;
	min-height: 112px; }

.resources-list {
	padding: 24px;
	min-height: 88px;
	max-width: 665px; }

.each-resource-cnt {
	height: 68px;
	width: 100%;
	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
	padding: 10px;
	text-decoration: none;
	box-sizing:  border-box;
	margin-top: 15px;
	background-color: white;
	display: flex;
	align-items: center; }

.each-resource-cnt:first-child {
	margin-top: unset; }

.file-icon-cnt {
	height: 40px;
	max-width: 40px;
	min-width: 40px;
	border-radius: 100%;
	overflow: hidden; }

.resource-name {
	padding-left: 15px;
	box-sizing:  border-box;
	width: 100%; }

.download-icon {
	height: 15px;
	width: 15px; }

.facilitator-details {
	min-height: 30px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.16);
	border-radius: 5px;
	margin-bottom: 15px; }

.facilitator-profile, .facilitator-bio {
	@extend %row;
	padding: unset; }

.facilitator-profile {
	padding: 16px 24px 5px 24px;
	box-sizing: border-box; }

.facilitator-avatar {
	@extend %col-xs;
	min-width: 80px;
	max-width: 100px;
	padding: unset; }

.facilitator-avatar img {
	border-radius: 100%;
	height: 80px;
	width: 80px; }

.facilitator-bio {
	padding: 16px 24px;
	box-sizing: border-box; }

.row.rating {
	height: 22px;
	max-width: 160px;
	flex-wrap: unset; }

.facilitator_rating {
	margin-left: 5px; }

@media screen and (max-width: 1439px) {
	.content-right-side.sidebar-open {
		min-width: 320px;
		margin-top: 30px;
		margin-bottom: 50px;
		max-width: unset;
		width: 100%;
		display: flex;
		align-items: center; }
	.content-cnt.sidebar-open {
		flex-direction: column; }
	.content-left-side.sidebar-open {
		width: 100%; }
	.session-details-cnt.sidebar-open {
		display: flex;
		width: 100%;
		border: unset;
		justify-content: space-between;
		align-items: center;
		padding: unset; }
	.button {
		margin: unset; }
	.button > div {
		width: fit-content !important; } }
