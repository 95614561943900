@import '~styles/mixins.sass';

.info-container {
    // width: 16px
    // height: 16px
    position: relative;
    padding-right: 15px; }

.info-icon {
    width: 100%;
    height: 100%;
    text-decoration: underline; }

.tooltip-container {
    width: 350px;
    position: absolute;
    top: 50%;
    left: calc(100% - 10px);
    display: flex;
    justify-content: center;
    align-items: flex-start; }

.tooltip-arrow {
    width: 0;
    height: 0; }

.tooltip-content {
    box-sizing: border-box;
    border-top-right-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 7px;
    width: calc(100% - 7px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left; }

.help-cursor {
    cursor: help; }
