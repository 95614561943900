@import '~styles/mixins.sass';

.simple-popup-cnt {
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	.popup-content {
		height: 100%;
		width: 100%;
		overflow: auto;
		position: relative;
		box-sizing: border-box; }
	.popup-content-cnt {
		min-width: 900px;
		max-width: 900px;
		height: 85vh;
		margin: auto;
		background-color: white;
		position: relative;
		z-index: 10;
		margin-top: 7vh;
		border-radius: 4px;
		.popup-close-icon {
			position: absolute;
			top: -18px;
			right: -18px;
			height: 40px;
			width: 40px;
			z-index: 100; } }

	.popup-overlay-bg {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.4);
		border: 1px solid #979797; } }
