@import '~styles/mixins.sass';

.container-class {
    width: 100%;
    @include display-flex-center-mixin;
    @include display-flex-column;
    font-family: 'Open Sans', sans-serif; }

.content-container {
	width: 100%;
	padding-top: 56px;
	@extend %row;
	@include center-all;
	box-sizing: border-box; }

.title-container {
	@include col-journeyDashboard;
	box-sizing: border-box;
	padding-top: 0;
	padding-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between; }

.boxes-container {
	@include col-journeyDashboard;
	box-sizing: border-box;
	padding-top: 0;
	display: flex;
	align-items: center;
	justify-content: space-between; }

.each-box {
	width: 48%;
	height: 393px;
	border-radius: 4px;
	@include display-flex-center-mixin; }
