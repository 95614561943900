.tag-box {
    border-radius: 4px;
    border: solid 1px #d5d8e2;
    padding: 10px;
    display: flex;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer; }

.circle {
    height: 12px;
    width: 12px;
    border-radius: 50%; }

.tag-text {
    padding-right: 10px;
    padding-left: 10px;
    margin-top: -3px;
    text-align: left; }

.icon {
    height: 12px;
    width: 12px; }

