.stat-container {
    height: 17px;
    display: flex;
    justify-content: flex-start;
    padding-top: 20px;
    padding-bottom: 20px; }

.icon {
    padding-right: 3px;
    width: 15px; }

.text {
    padding: 4px; }
