@import '~styles/mixins.sass';

.user-journeys-cnt {
	width: 100%;
	position: relative;
	background-color: white;
	min-height: calc(100vh - 66px);
	padding-top: 115px;
	box-sizing: border-box;
	.welcome-user-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 189px;
		z-index: 0;
		> div {
			height: 100%; } } }

.user-journeys-content {
	width: 100%;
	background-color: white;
	position: relative;
	min-height: calc(100vh - 217px);
	.tabs-cnt {
		width: 100%;
		border-bottom: 2px solid rgba(87,86,86, 0.1);
		> div {
			width: 100%;
			max-width: 1280px;
			margin: auto;
			padding: 0 130px;
			box-sizing: border-box;
			height: 54px; } } }

.journey-list-cnt {
	width: 100%;
	margin-bottom: 50px;
	.inner-cnt {
		width: 100%;
		max-width: 1280px;
		margin: auto;
		padding-top: 1px;
		box-sizing: border-box;
		.journeys-list {
			width: 100%;
			padding: 0 130px;
			box-sizing: border-box;
			margin-top: 56px;
			.list-header {
				font-family: Open Sans;
				font-size: 24px;
				line-height: 28px;
				color: #282828;
				font-weight: 500; }
			.list-content {
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
				> div {
					margin-left: 22px;
					margin-top: 22px; } } } } }

.request-new-journey {
	min-width: 325px;
	max-width: 325px;
	height: 230.5px;
	border-radius: 2px;
	box-sizing: border-box;
	@include display-flex-center-mixin; }


.back-to-home {
	width: 100%;
	position: absolute;
	top: 0;
	.journey-header {
		width: 100%;
		max-width: 1280px;
		padding: 0 130px;
		padding-top: 15px;
		box-sizing: border-box;
		margin: auto; } }

.crumbs-cnt {
	width: 100%;
	max-width: 1280px;
	padding: 0 130px;
	padding-top: 20px;
	box-sizing: border-box;
	margin: auto;
	@include display-flex-start;
	.journey-label {
		padding-left: 10px;
		box-sizing: border-box; } }

.user-welcome-note {
	height: 110px;
	display: flex;
	align-items: center;
	padding: 0 130px;
	width: 100%;
	max-width: 1280px;
	margin: auto;
	box-sizing: border-box; }

.journey-filters-and-search-cnt {
	width: 100%;
	min-height: 50px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(87, 86, 86, 0.1);
	box-sizing: border-box;
	padding: 0 130px 20px 130px;
	margin-top: 15px;
	gap: 65px;
	.journey-filters-cnt {
		width: 100%;
		display: flex; }
	.journey-search-cnt {
		width: 262px;
		height: 38px;
		input {
			width: 100%;
			height: 100%;
			padding: 0 10px;
			box-sizing: border-box;
			border: 1px solid rgba(87, 86, 86, 0.1);
			border-radius: 20px; } } }

.not-enrolled-msg {
	padding: 10px 130px 20px 130px;
	font-family: Open Sans;
	font-weight: 600;
	font-style: italic;
	font-size: 18px;
	line-height: 25px; }

@media screen and (max-width: 480px) {
	.journey-list-cnt {
		.inner-cnt {
			.journeys-list {
				padding: 0 30px;
				.list-content > div {
					margin-left: unset; } } } }
	.journey-filters-and-search-cnt {
		padding: 10px 15px 10px 15px !important;
		flex-direction: column;
		gap: 30px !important;
		align-items: flex-start !important;
		.journey-filters-cnt {
			flex-direction: column;
			& > div {
				margin-bottom: 10px; } }
		.journey-search-cnt {
			margin-left: unset;
			width: 100% !important; } }
	.crumbs-cnt {
		padding: 0 30px; }
	.back-to-home {
		.journey-header {
			padding: 0 30px; } }
	.user-welcome-note {
		padding: 0 30px; }
	.user-journeys-content {
		.tabs-cnt > div {
			padding: 0 30px; } }
	.request-new-journey {
		max-width: 100%;
		min-width: 100%; }
	.not-enrolled-msg {
		padding: 10px 15px 10px 15px; } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
	.journey-list-cnt {
		.inner-cnt {
			.journeys-list {
				.list-content {
					& > div {
						margin-left: unset; } } } } } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
	.journey-list-cnt {
		.inner-cnt {
			.journeys-list {
				padding: 0 20px;
				.list-content {
					& > div:nth-child(odd) {
						margin-left: unset; } } } } } }


@media only screen and (min-device-width : 1024px) {
	.journey-list-cnt {
		.inner-cnt {
			.journeys-list {
				.list-content {
					> div {
						&:nth-child(3n+1) {
							margin-left: unset; } } } } } } }
