.ie11-warning {
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    height: 32px;
    padding: 8px;
    padding-left: 35px;
    padding-right: 20px;
    box-sizing: border-box;
    z-index: 100;
    display: flex;
    justify-content: space-between; }

.old-browser-icon {
    position: absolute;
    left: 13px;
    top: 10px; }
