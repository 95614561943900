@import '~styles/mixins.sass';

.journey-learnings-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 50px; }

.journey-learnings-content {
    width: 100%;
    box-sizing: border-box;
    // margin-bottom: 40px
    overflow-y: hidden; }

.no-phases {
    padding: 20px; }
