.control-container {
    width: 181px;
    height: 28px;
    border-radius: 21px;
    margin-right: 24px;
    margin-top: -5px;
    padding: 5px;
    box-sizing: border-box;
    cursor: default !important; }

input[type="checkbox"].switch {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 27px;
  height: 8px;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  position: relative;
  outline: 0;
  cursor: pointer;
  margin: 4px 7px 0px 12px;

  &:before {
    position: absolute;
    content: "";
    -webkit-transition: all .25s;
    transition: all .25s;
    width: 15px;
    height: 15px;
    background: #808080;
    border-radius: 50%;
    top: 50%;
    left: -2px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }

  &:checked {
    background: rgba(255, 255, 255, 0.4);

    &:before {
      left: 15px;
      background: #ffffff; } } }

@media screen and (max-width: 480px) {
  .control-container {
    display: none; } }

@media only screen and (max-height: 575.98px) and (orientation: landscape) {
  .control-container {
    transform: scale(0.85); } }


@media only screen and (min-device-width : 490px) and (max-device-width : 1024px) and (orientation : portrait) {
  .control-container {
    transform: scale(0.85); } }
