@import '~styles/mixins.sass';

.info-container {
    width: 24px;
    height: 24px;
    position: relative; }

.info-icon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    cursor: help; }

.tooltip-container {
    width: 100px;
    position: absolute;
    top: -43px;
    left: -39px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

.tooltip-arrow {
    width: 0;
    height: 0; }

.tooltip-content {
    box-sizing: border-box;
    border-radius: 4px;
    padding: 7px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
