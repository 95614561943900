.button-style {
    width: 188px;
    display: flex;
    height: 48px;
    cursor: pointer; }

.button-text {
    margin: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-transform: capitalize; }
