@import '~styles/mixins.sass';

.journey-card-cnt {
	min-width: 240px;
	max-width: 240px;
	// height: 206px
	background: linear-gradient(180deg, #FFFFFF 0%, #FAFAFF 100%);
	border: 0.72px solid rgba(151, 151, 151, 0.158062);
	box-sizing: border-box;
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.163723);
	border-radius: 2px;
	padding: 0 6px;
	position: relative;
	.journey-details-cnt {
		width: 100%;
		height: 200px;
		padding: 26px 10px 11px 10px;
		box-sizing: border-box;
		.image-name-cnt {
			width: 100%;
			height: 74px;
			@include display-flex-align-items-center;
			img {
				height: 100%;
				min-width: 74px;
				width: 74px;
				border-radius: 2px;
				object-fit: cover; }
			.name {
				width: 100%;
				height: 100%;
				margin-left: 14px;
				overflow: hidden; } }
		.other-details-cnt {
			width: 100%;
			// height: 33px
			margin-top: 12px;
			margin-bottom: 5px;
			min-height: 20px;
			// @include display-flex-center-mixin
			.duration-cnt, .study-details-cnt {
				width: 100%;
				@include display-flex-align-items-center;
				height: 20px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				.icon {
					width: 12px;
					height: 12px;
					margin-right: 5px; } } }
		.request-status {
			background-color: #F2F3F7;
			border-radius: 4px;
			width: 100%;
			height: 35px;
			@include display-flex-center-mixin; } }
	.journey-action-cnt {
		width: 100%;
		height: 45px;
		border-top: 1px solid #BFC3D2;
		display: flex;
		justify-content: space-around;
		align-items: center; }
	.format-tag {
		@include display-flex-center-mixin;
		position: absolute;
		height: 19px;
		border-radius: 15px;
		padding: 0 10px;
		box-sizing: border-box;
		top: 5px;
		right: 0px; }
	.styleA-completed {
		@include display-flex-center-mixin;
		position: absolute;
		height: 20px;
		top: 0px;
		margin-left: -6px;
		padding: 2.5px; } }


.journey-progress-cnt {
	width: 100%;
	height: 35px;
	.progress-bar {
		width: 100%;
		height: 6px;
		background-color: rgba(196, 196, 196, 0.4);
		border-radius: 6px;
		position: relative;
		.completed-progress {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			border-radius: 6px; } } }

.locked-div {
	position: absolute;
	top: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
	@include display-flex-center-mixin;
	border-radius: 4px; }

.image-cnt {
	position: relative;
	width: 74px;
	height: 100%; }

.locked-icon {
	width: 26px;
	height: 32px; }

.websession-bell-icon-cnt {
	height: 11px;
	width: 11px;
	margin-top: 1px;
	margin-right: 2px; }

.websession-details-cnt {
	display: flex;
	justify-content: space-between; }

.websession-time-cnt {
	width: 275px; }

.websession-bell-icon-cnt {
	height: 11px;
	width: 11px;
	margin-top: 1px;
	margin-right: 2px; }

.websession-details-cnt {
	display: flex;
	justify-content: space-between; }

.active-websession-text {
	width: 190px; }

.active-websession-join-button-cnt {
	width: 72px; }

.join-session-button {
	display: flex;
	width: 72px;
	height: 25px;
	border-radius: 4px;
	cursor: pointer;
	align-items: center;
	justify-content: space-around; }

.websession-active-icon-cnt {
	height: 100%;
	width: 21px;
	display: flex;
	align-items: center;
	justify-content: center; }
