@import '~styles/mixins.sass';
.inst-container {
    @include content;
    display: flex;
    flex-direction: column;
    line-height: 1.5em;
    text-align: left;
    padding: 10px 0;
    margin-bottom: 20px; }

.inst-text {
    padding-top: 20px;
    padding-bottom: 20px; }
