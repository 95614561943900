.hash-container {
    padding: 20px 0px;
    text-align: center;
    margin-top: 20px; }

.hash {
    width: 308px;
    height: 39px;
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    background: none; }

.batchInputStatus {
    margin-top: 15px; }
