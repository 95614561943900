@import '~styles/mixins.sass';

.footer-container {
	display: flex;
	@include display-flex-center-mixin;
	width: 100%;
	height: 42px;
	box-sizing: border-box; }

.footer-container-inner {
	display: flex;
	flex-basis: 83.33333%;
	max-width: 83.33333%;
	@include display-flex-center-mixin;
	justify-content: space-between;
	box-sizing: border-box; }

.footer-image {
	@include display-flex-center-mixin;
	margin-right: 0px; }

.footer-image .image {
	width: 90px;
	height: 18px;
	object-fit: contain; }

.footer-content {
	@include display-flex-center-mixin;
	margin-left: 5px; }

.knolskape-link {
	margin-right: 3px;
	margin-left: 3px; }

.tagline {
	display: inline; }

@media screen and (max-width: 660px) {
	.tagline {
		display: none; }
	.footer-container-inner {
		flex-basis: 100%;
		max-width: 100%;
		justify-content: center; } }

.copyright-and-logo {
	display: flex; }

.social-media-links {
	display: flex; }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
		.footer-container {
			justify-content: center; }
		.tagline {
			display: none; }
		.social-media-links {
			display: none; } }
