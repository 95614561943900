@import '~styles/mixins.sass';

.next-product-container {
    margin-top: 90px;
    @extend %row;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 90px;
    background: white; }
.product {
    margin-top: 5px;
    letter-spacing: 1px;
    display: flex;
    justify-content: flex-start; }
.content {
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: auto 30px; }

.product-next {
    display: flex; }
.icon {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 20px; }
.line {
    width: 1px;
    height: 60px;
    margin: auto 0px;
    opacity: 0.3;
    background-color: #979797; }

.product-main-label-cnt {
    max-width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
.disable-section {
    opacity: 0.3;
    // pointer-events: none
    cursor: not-allowed;
    position: relative; }

.disable-tooltip {
    position: absolute;
    top: -20px;
    right: 15px;
    background: black;
    color: white;
    box-sizing: border-box;
    padding: 5px;
    border-radius: 4px;
    width: 300px; }

.lock-icon-cnt {
    height: 20px;
    width: 17px;
    float: left;
    margin-right: 6px;
    margin-top: 4px; }

.content-feedback {
    @extend .content;
    padding: 25px;
    border-radius: 4px;
    background-color: #f8f9fb;
    padding-left: 96px; }

.product-next-feedback {
    @extend .product-next;
    text-align: left; }

.display-flex {
    display: flex; }

.disable-tooltip-feedback {
    @extend .disable-tooltip;
    top: 50px;
    right: -285px; }

.next-item-cnt {
    position: fixed;
    bottom: 100px;
    right: 20px;
    width: 70px;
    height: 70px;
    box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.11), 0 10px 11px 0 rgba(0, 0, 0, 0.19);
    border: solid 0.5px rgba(212, 73, 128, 0.07);
    background-color: white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1001;
    .arrow-cnt {
        width: 23px;
        height: 16px; } }

@media screen and (min-width: 1440px) {
    .next-item-cnt {
        right: 110px; } }

.next-item-tooltip-cnt {
    position: absolute;
    right: 0;
    bottom: 80px;
    .next-item-tooltip {
        min-width: 260px;
        min-height: 20px;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border-radius: 4px;
        padding: 10px;
        box-sizing: border-box; }
    .arrow-down {
        position: absolute;
        right: 27px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent; } }

.lock-message-cnt {
    width: 200px; }

@media only screen and (max-width: 480px) {
    .next-item-cnt {
        position: absolute;
        bottom: 55px;
        right: 15px;
        width: 45px;
        height: 45px; } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
    .next-item-cnt {
        position: absolute;
        bottom: 95px;
        right: 25px;
        width: 50px;
        height: 50px; } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
    .next-item-cnt {
        position: absolute;
        bottom: 60px;
        right: 25px;
        width: 50px;
        height: 50px; } }
