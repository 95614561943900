.container {
	margin: 40px 0px 34px 0px; }

.body {
	margin-top: 16px;
	font-family: Mukta !important; }

.body p, .body div, .body span, .body a, .body li {
	font-family: Mukta !important; }

.audio-container {
	padding: 30px;
	padding-bottom: 15px;
	text-align: center; }

.audio-no-of-playbacks-note {
	margin-top: 5px; }
