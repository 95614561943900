@import '~styles/mixins.sass';

.card-container {
    width: 100%;
    height: 209px;
    box-sizing: border-box;
    padding-left: 16px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.1); }

.card-title {
    width: 100%;
    height: 66px;
    @include display-flex-start;
    box-sizing: border-box;
    text-transform: uppercase; }

.card-details {
    width: 100%;
    height: 141px;
    display: flex;
    position: relative;
    justify-content: center; }

.bg-container {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 0; }

.card-score-container {
    @include display-flex-center-mixin;
    // padding-left: 53px
    box-sizing: border-box;
    width: 45%;
    position: relative;
    padding-right: 10px;
    box-sizing: border-box; }

.card-week-update {
    @include display-flex-start;
    padding-left: 5px;
    box-sizing: border-box;
    width: 55%;
    z-index: 1; }

.score-content {
    width: 100%;
    @include display-flex-center-mixin; }

.sub-score-content {
    position: absolute;
    right: 44px;
    bottom: 16px; }

.arrow-up {
  width: 0;
  height: 0;
  border-left: 8.1px solid transparent;
  border-right: 8.1px solid transparent; }

.arrow-down {
    width: 0;
    height: 0;
    border-left: 8.1px solid transparent;
    border-right: 8.1px solid transparent; }

.rank-logo-container {
    display: flex;
    padding-right: 10px;
    width: 24px;
    height: 24px; }
