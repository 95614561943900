@import '~styles/mixins.sass';
.assesment-container {
    width: 100%;
    // height: calc(100vh - 59px)
    box-sizing: border-box;
    overflow: hidden; }

.loader {
    display: flex;
    align-items: center;
    height: 800px;
    justify-content: center; }
.header {
    width: 100%;
    height: 50px;
    border-bottom: solid 1px;
    margin-bottom: -1px; }

.side-bar-product-container {
    display: flex;
    width: 100%;
    margin-bottom: -1px;
    position: relative;
    height: 100%; }

.menu-container {
    // position: absolute
    height: 100%;
    overflow: hidden;
    z-index: 2;
    background-color: white;
    box-shadow: 1px 0 0 0 #d5d8e2; }

.side-bar-collapsed {
    width: 71px;
    // position: fixed
    height: 100vh;
    background-color: #ffffff;
    left: 0;
    top: 0;
 }    // top: 67px
.assesment-items-collapsed {
    width: 100%;
    display: flex;
 }    // margin-left: 71px

.side-bar-expanded {
    width: 350px;
    // height: 100vh
    // position: fixed
    top: 0;
    left: 0; }

.assesment-items-expanded {
    display: flex;
    margin-left: 350px; }

.content-row {
    @extend %row;
    @include center-all;
    height: 100%;
    width: 100%;
    // position: absolute
    // z-index: 1
    overflow-y: hidden;
    padding-left: 0;
    padding-right: 0;
    z-index: 0; }

.all-content-row {
    @extend %row;
    @include center-all;
    height: calc(100% - 159px);
    min-height: calc(100vh - 225px);
    width: 100%;
    //overflow-y: auto
    padding-left: 0;
    padding-right: 0; }

.content-column {
    @include col-assessmentpage; }

.title-width-expanded {
    width: 75%; }

.title-width-collapsed {
    width: 100%; }

.sticky-header {
    @extend %row; }

.completed-title-container {
    @extend %row;
    @include center-all;
    background-color: white;
    width: 100%;
    height: 79px;
    position: relative; }

.completed-title-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.completed-content {
    @include padding-left-right-zero;
    @include col-assessmentpage;
    @extend %row;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 2;
    justify-content: flex-start; }
