@import '~styles/mixins.sass';

.container-class {
    width: 100%;
    @include display-flex-center-mixin;
    @include display-flex-column;
    font-family: 'Open Sans', sans-serif; }

.content-container {
	width: 100%;
	padding-top: 56px;
	@extend %row;
	@include center-all;
	box-sizing: border-box;
	padding-bottom: 94px; }

.outer-content {
	@extend %col-sm-10;
	@extend %col-md-10; }

.lock-container {
	@include display-flex-center-mixin; }

.title-container {
	@include col-journeyDashboard;
	box-sizing: border-box;
	padding-top: 0;
	padding-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between; }

.boxes-container {
	@include col-journeyDashboard;
	box-sizing: border-box;
	padding-top: 0;
	display: flex;
	align-items: center;
	justify-content: space-between; }

.each-box {
	width: 48%;
	height: 393px;
	border-radius: 4px;
	@include display-flex-center-mixin; }

.filter-click-button {
	text-transform: uppercase;
	@include display-flex-center-mixin;
	@include display-flex-column; }

.filter-title {
	width: 100%;
	padding-bottom: 24px;
	@include display-flex-end-vertical; }


.drop-down-container {
	width: 98%;
	height: 160px;
	padding-top: 60px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	box-sizing: border-box;
	display: flex;
	padding-left: 32px;
	align-items: center;
	color: #979eb7; }

.cursor-pointer {
	cursor: pointer; }

.filter-submit-button {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-end;
	margin-right: 64px;
	align-items: center; }

.filter-submit-text {
	width: 168px;
	height: 32px;
	border-radius: 4px;
	border: solid 1px rgba(212, 73, 128, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	color: #d44980;
	cursor: pointer; }
