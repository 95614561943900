@import '~styles/mixins.sass';

.my-journeys-btn-cnt {
	margin: 0 15px;
	height: 30px;
	div {
		height: 100% !important;
		padding: 0 20px;
		box-sizing: border-box; } }

@media screen and (max-width: 480px) {
	.my-journeys-btn-cnt {
		display: none; } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
	.my-journeys-btn-cnt {
		width: 150px;
		div {
			padding: unset; } } }
