.button-style {
    min-width: 94px;
    max-width: 120px;
    display: flex;
    min-height: 26px;
    height: 26px;
    cursor: pointer;
    padding: 0 15px; }

.button-text {
    height: 14px;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center; }
