@import '~styles/mixins.sass';

.section-header {
	width: 100%;
	height: 50px;
	border-radius: 8px;
	box-shadow: 0 20px 25px 0 rgba(0, 0, 0, 0.2), 0 10px 10px 0 rgba(0, 0, 0, 0.04);
	border: solid 1px rgba(151, 151, 151, 0.02);
	margin-bottom: 28px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center; }
