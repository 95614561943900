@import '~styles/mixins.sass';

.low-high-container {
    @include col-measures; }

.header-tabs-container {
    width: 100%;
    height: 50px;
    position: relative; }

.header-tabs {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    z-index: 2; }

.tabs-line {
    width: 100%;
    height: 4px;
    border-radius: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1; }

.each-tab {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-right: 20px; }

.tab-title {
    box-sizing: border-box;
    // padding-left: 15px
    // padding-right: 15px
    padding-bottom: 8px; }

.each-tab-line {
    width: 100%;
    height: 4px;
    border-radius: 2px; }

.content-container {
    width: 100%;
    text-align: left; }

.score-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start; }

.left-container {
    box-sizing: border-box;
    width: 100%;
    padding-top: 32px;
    padding-bottom: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap; }

.right-container {
    width: calc(100% - 305px);
    box-sizing: border-box;
    padding: 48px 5px 0 48px;
    text-align: left; }

.right-title {
    width: 100%;
    padding-bottom: 13px; }

.right-desc {
    width: 100%; }

.competency-box {
    // margin: 0 10px
    width: 302px;
    height: 204px;
    box-sizing: border-box;
    margin-bottom: 24px;
    margin-right: 32px;
    overflow: visible; }

.competency-header {
    width: 100%;
    height: 51px;
    box-sizing: border-box;
    padding: 0 15px 0 35px;
    @include display-flex-center-mixin;
    border-top-left-radius: 4px;
    border-top-left-radius: 4px; }

.competency-score-box {
    width: 100%;
    height: calc(100% - 51px);
    box-sizing: border-box;
    padding: 15px;
    @include display-flex-center-mixin; }

.competency-icon {
    width: 24px;
    height: 24px; }

.competency-name {
    width: calc(100% - 39px);
    padding-left: 10px;
    box-sizing: border-box;
    text-align: left; }

.competency-info {
    width: 15px;
    height: 15px; }
