@import '~styles/mixins.sass';

.pre-container {
    min-height: 435px;
    width: 100%;
    background-color: white;
    @include display-flex-center-mixin;
    overflow-x: hidden; }

.pre-image {
    width: 64px;
    height: 103px;
    margin-bottom: 40px; }

.pre-content {
    @include col-landingpage;
    @extend %col;
    @include center-all;
    align-items: center;
    box-sizing: border-box;
    padding-top: 70px;
    padding-bottom: 110px; }

.pre-content .title {
    padding-bottom: 40px; }

.desc-container {
    width: 100%;
    @extend %row;
    @include center-all; }

.single-preReq {
    @extend %row;
    @extend %col-lg-4;
    @extend %col-md-6;
    @extend %col-sm-8;
    @include center-all;
    align-items: center;
    padding-bottom: 18px; }

.single-preReq-image {
    height: 20px;
    width: 20px;
    margin-right: 5px; }

.single-preReq-content {
    margin-left: 10px; }

.pre-content-loader {
    @extend .pre-content;
    padding-bottom: 0;
    height: 435px; }

.pre-requisite-image {
    width: 20px;
    height: 20px; }
