@import '~styles/mixins.sass';

.content-cnt {
	width: 100%;
	box-sizing: border-box;
	height: calc(100vh - 59px);
	overflow-y: auto;
	.content-header {
		width: 100%;
		position: relative;
		height: 110px;
		padding-top: 25px;
		box-sizing: border-box;
		.content {
			@include col-journeyDashboard;
			@include display-flex-align-items-center;
			padding: 0;
			margin:	auto;
			align-items: center;
			height: auto;
			margin-top: 8px; }
		.breacrumbs-cnt {
			@include col-journeyDashboard;
			padding: 0;
			margin:	auto;
			display: flex;
			align-items: flex-start;
			width: 100%;
			height: 20px;
			position: relative;
			span {
				padding: 0 10px; }
			span:not(:first-child) {
				border-left: 0.72px solid rgba(151, 151, 151, 0.3); }
			span:first-child {
				padding-left: unset; } } }
	.content {
		@include col-journeyDashboard;
		width: 100%;
		margin:	auto;
		height: calc(100% - 110px);
		padding-top: 30px;
		box-sizing: border-box; }

	.details-box {
		width: 100%;
		background: #FFFFFF;
		border: 1px solid rgba(151, 151, 151, 0.4);
		box-sizing: border-box;
		border-radius: 2px;
		padding: 0 8px 10px 8px;
		margin-bottom: 20px;
		.details-header {
			height: 55px;
			width: 100%;
			@include display-flex-space-between;
			align-items: center;
			padding: 0 15px;
			box-sizing: border-box; }
		.details-content {
			width: 100%;
			@include display-flex-start;
			flex-wrap: wrap;
			border-top: 1px solid rgba(151, 151, 151, 0.4);
			padding: 20px 15px;
			box-sizing: border-box; }
		.user-title {
			width: 90px;
			max-width: 90px;
			.title-cnt {
				width: 100%; } }
		.basic-details-item-cnt {
			min-width: 30%;
			max-width: 30%;
			margin: 12px 0;
			margin-left: 5%;
			&:nth-child(3n + 1) {
				margin-left: 0; }
			input {
				width: 100% !important;
				height: 38px !important;
				box-sizing: border-box; } }
		.demographics-item-cnt {
			min-width: 30%;
			max-width: 30%;
			margin: 12px 0;
			margin-left: 5%;
			&:nth-child(3n + 1) {
				margin-left: 0; } }
		.demographics-item-cnt .demographics-item {
			width: 100%;
			min-height: 38px;
			@include display-flex-align-items-center; } }

	.header-actions-cnt {
		display: flex;
		div {
			margin-left: 15px; } }

	.details-box.profile {
		@include display-flex-space-between;
		box-sizing: border-box;
		padding: 27px 25px;
		background: linear-gradient(180deg, #FFFFFF 0%, #FAFAFF 100%);
		border: 0.72px solid rgba(151, 151, 151, 0.3);
		.avatar-cnt {
			min-width: 80px; }
		.user-details {
			width: 100%;
			padding: 0 35px;
			box-sizing: border-box; } }

	.reset-password-cnt {
		@include col-journeyDashboard;
		margin:	auto;
		align-items: center;
		background: linear-gradient(180deg, #FFFFFF 0%, #FAFAFF 100%);
		border: 0.72px solid rgba(151, 151, 151, 0.3);
		box-sizing: border-box;
		padding: 50px 0;
		margin-top: 50px;
		position: relative;
		.passwords-input-cnt, .change-password-btn-cnt {
			@include display-flex-center-mixin;
			width: 100%; }
		.passwords-input-cnt {
			align-items: flex-start; }
		.old-pswd-cnt, .new-pswd-cnt {
			width: 50%;
			max-width: 275px;
			input {
				width: 100%;
				height: 38px; } }
		.new-pswd-cnt {
			margin-left: 100px;
			.new-pswd-inner-cnt:nth-child(2) {
				margin-top: 30px; } }
		.change-password-btn-cnt {
			margin-top: 30px; }
		.password-error-cnt {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			display: flex;
			justify-content: center;
			height: 20px;
			.error-text {
				height: 100%;
				@include display-flex-center-mixin;
				padding: 0 20px;
				border-radius: 4px; } } }
	.password-rules-cnt {
		@include col-journeyDashboard;
		margin:	auto;
		align-items: center;
		background: linear-gradient(180deg, #FFFFFF 0%, #FAFAFF 100%);
		border: 0.72px solid rgba(151, 151, 151, 0.3);
		box-sizing: border-box;
		padding: 20px 30px;
		margin-top: 50px; } }

.option-cnt {
	@include display-flex-start;
	cursor: pointer;
	&:hover {
		background-color: pink; } }

.disabled {
	pointer-events: none; }

.details-content.disabled .dob-cnt {
	input {
		border: none !important; } }

.subscribe-option input {
	cursor: pointer;
	margin-left: 10px; }

.overlay {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	background-color: rgba(0, 0, 0, 0.1); }


@media only screen and (max-width: 480px) {
	.content-cnt {
		.content-header {
			height: 125px;
			.breacrumbs-cnt {
				box-sizing: border-box; } }
		.password-rules-cnt {
			margin-top: 0;
			padding: 10px 20px 10px 20px; }
		.reset-password-cnt {
			margin-top: 0;
			padding: 30px 0;
			.passwords-input-cnt {
				flex-direction: column;
				align-items: center;
				.old-pswd-cnt {
					width: 80%;
					max-width: 80%; }
				.new-pswd-cnt {
					width: 80%;
					max-width: 80%;
					margin-top: 20px;
					margin-left: 0px; } } } } }


@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
	.content-cnt {
		.content-header {
			.content {
				margin-top: unset; } } } }
