.module-lines-cnt {
	background-color: #f8f9fb;
	border: solid 1px #e4e5e6; }

.individual-module-line-cnt {
	min-height: 54px;
	padding: 0 18px 0 24px;
	cursor: pointer;
	&:hover {
		background-color: #f1f1f1; } }

.individual-module-line-cnt.feedback {
	height: 54px; }

.individual-module-line {
	display: flex;
	justify-content: space-between;
	line-height: 54px;
	border-top: solid 1px #e4e5e6; }

.individual-module-line .name-cnt {
	text-align: left;
	display: flex;
	align-items: center; }

.individual-module-line .name-cnt .name {
	padding-left: 16px; }

.individual-module-line .completion-status {
	text-align: right; }

.individual-module-line-cnt:first-child .individual-module-line {
	border-top: unset; }

.view-more-btn {
	border-top: solid 1px rgba(151, 151, 144, 0.2);
	margin: 0 18px 0 24px; }

.view-more-btn, .view-less-btn {
	height: 38px;
	line-height: 38px;
	cursor: pointer; }

.lock-icon-cnt {
	height: 13px;
	width: 10px;
	margin-top: -3px;
	margin-left: 9px;
	margin-right: 9px; }

.course-feedback-line {
	width: 100%;
	height: 100%;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	box-sizing: border-box;
	border-top: solid 1px #e4e5e6; }

.feedback-icon-cnt {
	height: 15px;
	width: 16px;
	margin: 0 10px 0 7px; }

.loader-container {
	height: 30px;
	padding: 10px; }

@media screen and (max-width: 480px) {
	.view-more-btn, .view-less-btn {
		padding-left: 10px; } }
