@import '~styles/mixins.sass';

.content-cnt {
	padding: 0;
	min-height: calc(100vh - 310px);
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap; }

.content-wrapper {
	width: 100%;
	padding-right: 10px;
	box-sizing: border-box; }

.content {
	width: 100%; }

.content.sidebar-open {
	display: flex;
	flex-direction: column-reverse; }

.content.sidebar-closed {
	display: flex;
	align-items: flex-start;
	justify-content: center; }

.session-desc-cnt {
	width: 100%; }

.metadata-cnt.sidebar-opened {
	width: 100%;
	@include display-flex-align-items-center;
	flex-wrap: wrap;
	.duration-details, .group-size-details, .level-details {
		width: 33%;
		padding-right: 42px;
		box-sizing: border-box; }
	.metadata-header {
		width: 100%;
		height: 25px;
		@include display-flex-align-items-center; }
	.metadata-content {
		width: 100%;
		height: 40px;
		@include display-flex-align-items-center; } }

.metadata-cnt.sidebar-closed {
	min-width: 310px;
	max-width: 350px;
	padding: 0 16px;
	box-sizing: border-box;
	height: 350px;
	@include display-flex-column;
	justify-content: space-around;
	.duration-details, .group-size-details, .level-details {
		width: 100%; }
	.metadata-header {
		width: 100%;
		height: 25px;
		@include display-flex-align-items-center; }
	.metadata-content {
		width: 100%;
		height: 40px;
		@include display-flex-align-items-center; } }

.button-cnt {
	max-width: 300px;
	margin-top: 20px; }

.extra-details-cnt {
	width: 100%;
	.description {
		margin-top: 30px; } }

.content.sidebar-open .extra-details-cnt {
	margin-top: 50px; }

.content.sidebar-closed .extra-details-cnt {
	padding-right: 30px;
	box-sizing: border-box; }
