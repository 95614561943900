@import '~styles/mixins.sass';

.complete-container {
	width: 100%;
	// height: calc(100vh - 59px)
	// min-width: 1024px
	overflow-x: hidden;
	@include noselect;
	overflow-y: auto; }

.no-phases {
	padding: 20px; }
