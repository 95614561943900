@import '~styles/mixins.sass';

.loading-container {
    @include display-flex-center-mixin;
    width: 100%;
    height: 100%;
    min-height: 300px;
    background-color: rgba(9, 16, 39, 0.05);
    flex-direction: column; }

.loading-text {
    margin-top: 16px;
    color: #979eb7;
    font-size: 16px;
    font-family: 'Open Sans'; }
