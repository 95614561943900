@import '~styles/mixins.sass';

.drivers-for-digital-blur-cnt {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white; }

.drivers-for-digital-blur-content-cnt {
	width: 100%;
	max-height: calc(100vh - 94px);
	overflow: auto; }

.drivers-for-digital-blur-content {
	max-width: 1280px;
	margin: auto;
	margin-bottom: 50px;
	.step-header-text {
		height: 55px;
		background-color: #f5f6f9;
		@include display-flex-center-mixin;
		margin: 30px 0 20px 0; }
	.cards-outer-cnt {
		width: 100%;
		max-width: 1120px;
		margin: auto; }
	.driver-cards-cnt {
		width: 100%;
		@include display-flex-space-between;
		.driver-header-cnt {
			@include display-flex-center-mixin;
			width: 50px;
			background-color: #4a5eb3;
			text-align: center;
			.driver-header-text {
				transform: rotate(270deg);
				min-width: 300px; } }
		.all-driver-cards-cnt {
			width: 100%;
			.individual-driver-cards-cnt {
				@include display-flex-space-between;
				padding: 30px 0;
				box-sizing: border-box;
				border: 1px dashed #4a5eb3;
				border-left: none;
				&:not(:first-child) {
					border-top: none; }
				.individual-driver-header-cnt {
					@include display-flex-center-mixin;
					width: 80px;
					text-align: center;
					.individual-driver-header-text {
						transform: rotate(270deg);
						min-width: 170px; } }
				.cards-cnt {
					width: 100%;
					@include display-flex-space-between;
					.tool-items-list-card-cnt {
						width: 50%;
						.card {
							max-width: 270px;
							min-width: 270px;
							margin: auto; } } } } } }
	.driver-cards-header-cnt {
		width: 990px;
		margin-left: 130px;
		@include display-flex-center-mixin;
		height: 100px;
		.cards-column-header {
			width: 50%;
			position: relative;
			.cards-column-header-text {
				width: 100%;
				max-width: 270px;
				margin: auto;
				@include display-flex-center-mixin; }
			.cards-column-connector {
				position: absolute;
				top: 25px;
				left: 50%;
				width: 0;
				border-left: 1px dashed #979797;
				height: 1300px; } } } }
