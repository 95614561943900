@import '~styles/mixins.sass';

.detailed-container {
    @include col-measures;
    padding-bottom: 64px; }

.detailed-analysis-title {
    width: 100%;
    text-align: left;
    padding-bottom: 10px; }

.detailed-analysis-sub-title {
    width: 100%;
    text-align: left;
    padding-bottom: 45px; }

.detailed-analysis-content {
    width: 100%;
    margin-bottom: 32px; }

.download-report-container {
    width: 100%;
    border-radius: 4px;
    padding: 30px 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between; }

.header-container {
    width: 100%; }

.tabs-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden; }

.single-tab-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 68px;
    margin-right: 20px; }

.single-tab {
    box-sizing: border-box;
    height: 43px;
    // max-width: 200px
    padding: 0 20px;
    @include display-flex-center-mixin;
    border-radius: 21.5px;
    margin-bottom: 10px;
    width: 220px;
    .text {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; } }

.tab-arrow {
    width: 0;
    height: 0;
    margin-right: 20px; }

.download-report-text {
    width: 75%;
    display: flex;
    align-items: center;
    justify-content: flex-start; }

.download-report-button {
    height: 32px;
    @include display-flex-center-mixin;
    padding: 0 15px;
    border-radius: 4px;
    cursor: pointer; }

.arrow-container {
    width: 25px;
    height: 100%;
    box-sizing: border-box;
    @include display-flex-center-mixin;
    .arrow-content {
        width: 8px;
        height: 14px;
        cursor: pointer; } }
