@import '~styles/mixins.sass';

.footer-container {
    width: 100%;
    @extend %row;
    @include center-all;
    height: 250px;
    position: relative;
    overflow-x: hidden; }

.footer-content {
    position: absolute;
    z-index: 3;
    width: 100%;
    // @extend %row
    // @include center-all
    height: 100%;
    box-sizing: border-box;
    padding-top: 30px;
    padding-bottom: 30px;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 64px;
    justify-content: center; }

.footer-content .title {
    // @extend %row
    // @include col-landingpage
    // @include col-start
    padding-bottom: 25px;
    margin-left: 15px;
    text-align: left;
    line-height: 1.45; }

.footer-content .desc {
    // @extend %row
    // @include col-landingpage
    // @include col-start
    padding-bottom: 15px;
    margin-left: 15px;
    text-align: left; }

.app-links-container {
    @extend %row;
    @include col-landingpage;
    @include col-start; }

.single-app-link {
    width: 118px;
    height: 35px;
    margin-right: 20px;
    border-radius: 4px; }

.footer-background {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.logo-knolskape {
    position: absolute;
    z-index: 2;
    width: 128px;
    height: 31px;
    top: 24px;
    left: 24px; }

.loader-content {
    @include display-flex-start;
    @include col-journeyDashboard;
    box-sizing: border-box;
    position: relative;
    height: 100%; }

@media screen and (max-width: 480px) {
    .footer-content {
        padding-left: 10px; } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
    .footer-content {
        padding-left: 10px; } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
    .footer-content {
        padding-left: 10px; } }
