.outer {
    position: relative;
    min-height: 98px;
    height: auto;
    width: 100%; }

.outer.without-date {
    min-height: 78px; }

.module-outer {
    height: 78px; }

.status-container {
    display: flex;
    width: 100%;
    padding-right: 16px;
    padding-left: 24px;
    box-sizing: border-box; }

.collapsed-container {
    padding-left: 20px; }

.main-container {
    width: 100%;
    height: 100%;
    cursor: pointer;
    &:hover .item-divider {
        background: none; } }

.column-container {
    display: flex;
    flex-direction: column; }
.type-duration {
    display: flex;
    justify-content: space-between; }
.text-container {
    //margin-top: -27px
    width: 100%;
    padding-left: 10px; }
.module-text-container {
    width: 100%;
    padding-left: 10px; }
.name {
    padding-left: 12px;
    padding-bottom: 4px;
    box-sizing: border-box;
    min-width: 286px;
    white-space: nowrap; }

.module-name {
    min-width: 240px;
    width: 95%;
    margin-top: 17px; }

.items-completed {
    visibility: show; }

.dot {
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin-left: -8px;
    position: absolute;
    top: 35%;
    z-index: 10; }

.line {
    width: 2px;
    height: 100%;
    border-radius: 1px;
    margin-left: 1px;
    position: absolute; }

.inter-module-line {
    height: 100%;
    width: 1px;
    border-radius: 1px;
    margin-left: 1px;
    position: absolute; }

.width {
    width: 90%; }

.module-text-container {
    width: 86.5%; }

.progress-circle-cnt {
    box-sizing: border-box;
    padding-top: 25px; }

.module-outer .dot {
    top: 20px; }

.type {
    padding-top: 10px;
    padding-right: 10px; }

.lock-icon-cnt {
    height: 13px;
    width: 11px;
    margin-right: 6px;
    display: inline-block; }

.module-item-title {
    display: flex;
    align-items: center; }

.locked-item {
    cursor: not-allowed; }

.item-divider {
    height: 1px;
    width: 260px;
    background: rgba(151, 151, 151, 0.2);
    margin-left: 12px;
    margin-top: 8px; }

.completed-label {
    text-transform: capitalize; }

.recommended-cnt {
    margin-left: 5px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding-left: 5px;
    position: relative; }

.recommended-cnt:after {
	content: '';
	position: absolute;
	height: 70%;
	top: 15%;
	left: 0;
	width: 1px;
	background-color: #d5d8e2; }

.recommended-icon-cnt {
    width: 12px;
    height: 13px;
    margin-top: -3px; }

.module-item-title .title-inner {
    overflow: hidden;
    text-overflow: ellipsis; }

.date {
    padding-top: 5px;
    width: 286px;
    word-wrap: break-word;
    white-space: normal; }
