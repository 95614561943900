@import '~styles/mixins.sass';

.product-title-container {
    @extend %row;
    @include center-all;
    background-color: white;
    width: 100%;
    position: relative; }

.product-title-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.content {
    @include padding-left-right-zero;
    @include col-assessmentpage;
    @extend %row;
    height: 100%;
    display: flex;
    align-items: flex-start;
    z-index: 2;
    justify-content: center;
    max-width: 1280px; }

.title-action {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding-left: 5px; }


.title-holder {
    @include assessment-item-title;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; }

.button-helper {
    // margin-left: auto
    margin-right: 25px;
    min-width: 283px; }

.phase-title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 10px; }

.button-holder {
    @include assessment-item-title-button;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    text-transform: uppercase; }

.button-holder-expired {
    @include assessment-item-title-button;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    text-transform: uppercase;
    opacity: 0.5;
    pointer-events: none; }

.button-help {
    padding-top: 5px;
    font-style: italic;
    font-weight: bold;
    box-sizing: border-box; }

.loader-container {
    height: 159px; }

.loader-content {
    @extend .content;
    justify-content: flex-start; }

.helper-text {
    min-width: 240px;
    max-width: 300px;
    margin: 0px; }

.home-link-cnt {
    @include col-assessmentpage;
    @extend %row;
    max-width: 1280px;
    text-align: left;
    max-width: 1280px;
    position: relative;
    z-index: 10;
    padding-left: 5px;
    box-sizing: border-box; }
