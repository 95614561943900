@import '~styles/mixins.sass';

.container-class {
    width: 100%;
    padding-bottom: 50px; }

.outer-graph-container {
    width: 100%;
    @include center-all;
    @extend %row; }

.graph-container {
    width: 100%;
    @include center-all;
    @extend %row; }

.graph-content {
    @extend %col-md-6;
    display: flex; }

.progress-container {
    width: 100%;
    @include display-flex-start;
    @include display-flex-column;
    padding-bottom: 62px; }

.outer-dev-graph-container {
    width: 100%;
    @include center-all;
    @extend %row; }

.dev-graph-container {
    width: 100%;
    @extend %col-sm-10; }

.dev-graph-content {
    @include center-all;
    @extend %row;
    display: flex;
    padding-bottom: 100px; }

.course-progress-container {
    @extend %col-sm-10;
    @extend %col-md-6;
    @include display-flex-start;
    @include display-flex-column; }

.section-progress-container {
    @extend %col-sm-10;
    @extend %col-md-6; }

.dev-activity-progress-container {
    padding-bottom: 100px; }

.customised-legend {
    width: 100%;
    box-sizing: border-box;
    padding: 0 136px; }

.legend-container {
    @include display-flex-center-mixin; }

.legend-content:nth-child(2n+1) {
    padding-right: 24px; }

.legend-circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 5px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5); }

.display-none {
    display: none; }
