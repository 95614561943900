@import '~styles/mixins.sass';

.status-container {
    display: flex;
    width: 100%; }
.main-container {
    width: 100%; }
.column-container {
    display: flex;
    flex-direction: column;
    align-items: center; }
.type-duration {
    width: 95%;
    display: flex;
    justify-content: space-between; }
.text-container {
    margin-top: -27px;
    width: 100%; }
.name {
    padding-bottom: 3px;
    box-sizing: border-box;
    max-width: 280px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap; }
.product {
    &:hover {
        cursor: pointer;
 } }        // text-decoration: underline
.dot {
    border-radius: 50%;
    width: 16px;
    height: 16px; }

.dot-lock {
    width: 15px;
    height: 15px;
    margin: 2px 0; }
.line {
    width: 2px;
    height: 82px;
    border-radius: 1px; }
.width {
    width: 90%; }

.item-status-container {
    @include display-flex-start; }

.completed-status {
    text-transform: capitalize; }

.completed-tick-container {
    @include display-flex-center-mixin;
    width: 12px;
    height: 12px;
    margin-right: 10px;
    line-height: 1; }

.text-content {
    margin-left: 20px;
    padding-bottom: 10px;
    cursor: pointer; }

.date {
    width: 280px;
    word-wrap: break-word;
    white-space: normal; }

.type-name {
    text-transform: lowercase;
    &:first-letter {
        text-transform: uppercase; } }
