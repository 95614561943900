@import '~styles/mixins.sass';

.build-a-platform-cnt {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white; }

.build-a-platform-content-cnt {
	width: 100%;
	max-height: calc(100vh - 94px);
	overflow: auto; }

.build-a-platform-content {
	width: 100%;
	max-width: 1280px;
	margin: auto;
	.step-header-text {
		height: 55px;
		background-color: #f5f6f9;
		@include display-flex-center-mixin;
		margin: 30px 0 20px 0; }
	.cards-outer-cnt {
		@include display-flex-space-between;
		width: 100%;
		max-width: 1120px;
		margin: 90px auto;
		box-sizing: border-box;
		padding: 0 40px 0 60px; } }

.individual-card-cnt {
	max-width: 270px;
	min-width: 270px;
	position: relative;
	.header {
		text-align: center;
		position: absolute;
		top: -51px;
		width: 100%;
		text-align: center; }
	.tool-items-list-card-cnt {
		position: relative; }
	.connector-line {
		position: absolute;
		width: 0;
		border-left: 1px dashed #7157A9CC;
		top: -25px;
		height: 100px;
		left: 134.5px; } }

.roles-cnt {
	@include display-flex-center-mixin;
	.individual-role-cnt {
		@include display-flex-center-mixin;
		margin-left: 20px;
		height: 40px;
		border-radius: 20px;
		padding: 0 20px;
		box-sizing: border-box;
		width: fit-content;
		box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.2);
		cursor: pointer;
		.info-icon-cnt {
			height: 20px;
			width: 20px;
			margin-right: 10px;
			position: relative;
			&:hover {
				.capsule-info-card {
					display: block; } } }
		.radio-button-cnt {
			width: 14px;
			height: 14px;
			margin-left: 10px;
			border-radius: 100%;
			box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.2); }
		.checkmark-icon-cnt {
			width: 14px;
			height: 11px;
			margin-left: 10px; } }
	.individual-role-cnt.selected {
		background-color: #46ba56; } }

.capsule-info-card {
	width: 230px;
	border-radius: 6px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	background-color: #ffffff;
	padding: 20px;
	box-sizing: border-box;
	display: none;
	position: absolute;
	top: 30px;
	left: -105px;
	z-index: 10000; }
