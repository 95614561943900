.module-item-header {
	height: 60px;
	box-sizing: border-box;
	border-bottom: 1px solid #d5d8e2;
	//color: rgba(46, 52, 75, 0.6)
	padding-top: 24px;
	padding-bottom: 12px;
	line-height: 2;
	display: flex; }

.module-item-header div {
	font-family: Open sans !important; }

.item-type-cnt {
	display: flex; }

.item-type-cnt .text {
	font-size: 14px;
	letter-spacing: normal;
	padding: 0;
	display: flex;
	align-items: center;
	line-height: unset; }

.type-label {
	margin-top: unset; }

.module-item-type-icon-cnt {
	height: 20px;
	width: 20px;
	margin-right: 7px; }

.item-duration-cnt {
	margin-left: 5px;
	position: relative;
	padding-left: 10px; }

.item-duration-cnt:after {
	content: '';
	background: rgba(46, 52, 75, 0.48);
	position: absolute;
	left: 0px;
	height: 16px;
	width: 1px;
	top: 6px; }

.item-due-at-cnt {
	margin-left: 33px;
	display: flex; }

.recommended-cnt {
	margin-left: 25px;
	display: flex;
	box-sizing: border-box; }

.add-note-cnt {
	float: right; }

.add-note-cnt img {
	height: 24px;
	float: left;
	margin-top: 4px;
	margin-right: 8PX; }

.add-note-cnt .text {
	float: left;
	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	color: #d44980;
	line-height: 32px; }

.go-to-home {
	display: flex;
	min-width: 150px;
	align-items: center;
	justify-content: center;
	border: 1px solid;
	height: 32px;
	border-radius: 4px;
	cursor: pointer; }

.recommended-icon-cnt {
	width: 13.5px;
	height: 14.5px;
	display: flex;
	align-self: center; }
