@import '~styles/mixins.sass';

.individual-phase-item-cnt {
    margin-top: 32px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    background-color: white;
    border-radius: 4px;
    border: solid 1px rgba(151, 158, 183, 0.27);
    &:hover {
        box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.11), 0 4px 16px 0 rgba(0, 0, 0, 0.06); } }

.module-cards-cnt {
    @extend %col-xs-6;
    box-sizing: border-box;
    max-width: unset;
    width: calc(66.67% - 105px);
    margin-left: 105px; }

.image-container {
    width: 129px;
    height: 100%;
    @include display-flex-center-mixin;
    overflow: hidden;
    border-radius: 4px; }

.content-container {
    width: calc(100% - 129px);
    height: 100%;
    box-sizing: border-box;
    padding: 5px 0 2px 16px;
    text-align: left; }

.title, .desc {
    width: 100%; }

.title {
    padding-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

.desc {
    padding-bottom: 14px;
    height: 42px; }

.progress-container {
    height: 35px; }

.outer-bar {
    width: 100%;
    height: 8px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px; }

.inner-bar {
    border-radius: 8px;
    height: 100%;
    min-width: 8px; }

.progress-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }

.dev-phase-timeline-cnt {
    position: absolute;
    left: -73px;
    top: 70px; }

.dev-phase-timeline-outer-circle {
    border-width: 4px;
    border-style: solid;
    // border-color: rgb(213, 217, 231)
    border-color: transparent;
    border-image: initial;
    padding: 4px;
    box-sizing: border-box;
    border-radius: 100%;
    background-color: #f2f3f7;
    cursor: pointer; }

.dev-phase-timeline-inner-circle {
    height: 24px;
    width: 24px;
    border-radius: 100%;
    background-color: #979eb7; }

.dev-phase-timeline-connector {
    width: 4px;
    background-color: rgb(213, 217, 231);
    margin: auto; }

.completion-status {
    background-color: #76ca1a;
    color: white;
    height: 20px;
    border-radius: 4px;
    padding: 0 7px;
    box-sizing: border-box;
    position: absolute;
    top: 35px;
    left: 24px;
    border-radius: 0 4px 4px 0;
    text-transform: uppercase; }

.single-phase-container {
    @include col-journeyDashboard;
    @include display-flex-center-mixin; }


.feedback-link-cnt {
    width: 100%;
    position: relative;
    height: 40px;
    margin-top: 32px;
    .feedback-text {
        height: 100%;
        background-color: white;
        max-width: 320px;
        border: solid 1px rgba(151, 158, 183, 0.25);
        border-radius: 28px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        cursor: pointer;
        &:hover {
            box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.11), 0 4px 16px 0 rgba(0, 0, 0, 0.06); } }
    .feedback-dot {
        height: 24px;
        width: 24px;
        border-radius: 100%;
        background-color: rgb(151, 158, 183);
        position: absolute;
        top: 11px;
        left: -64px;
        cursor: pointer; }
    .feedback-connector {
        width: 4px;
        background-color: #d5d9e7;
        margin: auto;
        height: 114px;
        position: absolute;
        bottom: 32px;
        left: -54px;
        z-index: 0; }
    .feedback-icon-cnt {
        height: 15px;
        width: 16px;
        margin-right: 10px; } }


@media screen and (max-width: 480px) {
    .feedback-connector {
        display: none; }
    .feedback-dot {
        display: none; }
    .module-cards-cnt {
        margin-left: unset;
        width: calc(100vw - 5%);
        flex-basis: unset;
        max-width: unset; } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
    .feedback-connector {
        display: none; }
    .feedback-dot {
        display: none; }
    .module-cards-cnt {
        margin-left: unset;
        width: calc(100vw - 5%);
        flex-basis: unset;
        max-width: unset; } }


@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
    .feedback-connector {
        display: none; }
    .feedback-dot {
        display: none; }
    .module-cards-cnt {
        margin-left: unset;
        width: calc(100vw - 5%);
        flex-basis: unset;
        max-width: unset; } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : landscape) {
    .module-cards-cnt {
        margin-left: -3%;
        width: 80%;
        flex-basis: unset;
        max-width: unset; } }
