@import "~styles/mixins.sass";

.questions-navigator-cnt {
	padding-top: 36px;
	width: 184px; }

.questions-navigator-header {
	border-bottom: solid 1px #d5d8e2;
	color: #5b5c61;
	padding-bottom: 3px; }

.quiz-question-bubbles-cnt {
	display: flex;
	flex-wrap: wrap;
	width: 184px;
	padding-top: 14px; }


.individual-question-bubble {
	width: 32px;
	height: 33px;
	margin-right: 4px;
	border-radius: 50%;
	line-height: 33px;
	text-align: center;
	position: relative;
	cursor: pointer; }

.flagged-bubble {
	height: 6px;
	width: 6px;
	background-color: #d44980;
	border-radius: 100%;
	position: absolute;
	bottom: 0;
	left: 13px; }

.correct-answered {
	color: #6cbc14;
	background-color: rgba(108,188,20,0.1); }

.wrong-answered {
	color: #d0041d;
	background-color: rgba(208,4,29,0.1); }

.user-selected {
	color: blue !important;
	background-color: rgba(34,59,146,0.1) !important; }
