@import '~styles/mixins.sass';

.header-container {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: 0 48px;
	height: 59px;
	@include display-flex-center-mixin; }

.logo-section {
	@include display-flex-center-mixin;
	box-sizing: border-box;
	width: 175px;
	height: 100%; }

.logo-container {
	width: 100%;
	height: 70%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer; }

@media screen and (max-width: 480px) {
	.individual-item-content {
		border-left: unset !important; } }

.logo-image img {
	max-width: 175px;
	height: 50px;
	object-fit: contain;
	margin-top: 6px; }

.logo-name {
	padding-left: 15px; }

.other-section {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between; }

.individual-header-component {
	height: 100%;
	@include display-flex-center-mixin; }

.individual-item-container {
	height: 100%;
	box-sizing: border-box;
	@include display-flex-center-mixin; }

.individual-item-content {
	height: 70%;
	max-height: 31px;
	padding: 0 15px;
	@include display-flex-center-mixin; }

.individual-item-image {
	height: 100%;
	max-height: 24px;
	max-width: 24px;
	overflow: hidden;
	@include display-flex-center-mixin;
	img {
		border-radius: 100%;
		overflow: hidden; } }

.individual-item-name {
	padding: 0 15px; }

.individual-item-downArrow {
	height: 100%;
	max-height: 8px;
	overflow: hidden;
	@include display-flex-center-mixin; }


.msteams-header-container {
	height: 0px;
	overflow: hidden; }

.msteams-individual-header-component {
	position: absolute;
	height: 41px;
	top: 0;
	right: 37px;
	background: white;
	z-index: 25;
	border-radius: 3px;
	box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.06);
	border: solid 1px rgba(151, 158, 183, 0.27); }

@media only screen and (max-width: 480px) {
	.header-container {
		padding: 0 3px 0 10px; } }
