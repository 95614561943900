@import '~styles/mixins.sass';
.main {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 2; }

.overlay {
    height: 100%;
    width: 100%;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.69);
    position: fixed;
    top: 0;
    z-index: 2; }

.main-container {
    position: absolute;
    width: 795px;
    min-height: 300px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    padding: 10px 30px;
    box-sizing: border-;
    z-index: 10; }

.heading {
    display: block; }

.details-container {
    margin-top: 20px;
    display: flex; }

.course-thumbnail {
    width: 187px;
    height: 187px; }

.textual-details {
    padding: 5px 20px;
    box-sizing: border-box;
    width: calc(100% - 187px); }

.course-title-cnt {
    display: flex;
    position: relative; }

.session-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden; }

.session-details {
    margin-top: 5px; }

.session-date {
    margin-top: 12px; }

.session-time {
    margin-top: 5px; }

.facilitator {
    margin-top: 10px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.description {
    margin-top: 2px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden; }

.format-tag {
    @include display-flex-center-mixin;
    position: absolute;
    height: 19px;
    border-radius: 15px;
    padding: 0 10px;
    box-sizing: border-box;
    top: 3px;
    right: 0px; }

.course-title {
    width: calc(100% - 100px); }

.active-websession-join-button-cnt {
    position: relative; }

.join-session-button {
    display: flex;
    width: 138px;
    height: 40px;
    position: absolute;
    right: 0px;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    justify-content: space-around; }

.websession-active-icon-cnt {
	height: 100%;
	width: 21px;
	display: flex;
	align-items: center;
	justify-content: center; }

.popup-close-icon {
    position: absolute;
    top: 12px;
    right: 14px;
    height: 20px;
    width: 20px;
    z-index: 100; }
