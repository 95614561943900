.success-msg-cnt {
    display: flex;
    align-items: center;
    margin-bottom: 25px; }

.checkmark {
    height: 32px;
    width: 32px;
    background-color: #2e4fb4;
    color: white;
    border-radius: 100%;
    margin-right: 10px;
    padding: 0 4px;
    box-sizing: border-box;
    border: 1px solid #daddea; }

.resubmit-section {
    text-align: center;
    margin-top: 20px; }

.resubmit-btn-cnt {
    margin: 20px auto;
    width: 200px; }

.response-container {
    padding: 10px 0px;
    box-sizing: border-box; }

.submission-details-section {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    box-sizing: border-box; }

.status-circle {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%; }

.heading {
    margin-bottom: 20px; }


.file-cards-cnt {
    display: flex;
    flex-wrap: wrap; }

.file-card {
    width: 178px;
    line-height: 1;
    border-radius: 2px;
    text-decoration: none;
    box-shadow: 0 1px 2px 0 #0000003f;
    margin-right: 16px; }

.file-card-filename {
    text-decoration: none;
    box-sizing: border-box;
    padding-left: 8px;
    padding-right: 8px;
    word-break: break-word;
    &:hover {
        text-decoration: none; } }

.file-card-image {
    width: 100%;
    height: 72px;
    text-indent: -10000px; }
