.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column; }
.icon {
    width: 100%;
    margin-bottom: 45px;
    margin-top: 45px;
    display: flex;
    justify-content: center; }
.list-items {
    width: 100%; }
.timeline-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 35px;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto; }
