@import '~styles/mixins.sass';

.card-container {
    width: 45%;
    height: 108px;
    box-sizing: border-box;
    border-radius: 4px;
    @include display-flex-start;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    position: relative; }

.card-title {
    width: 100%;
    padding-left: 32px;
    box-sizing: border-box;
    @include display-flex-start; }

.logo-container {
    padding-right: 24px;
    display: flex; }

.back-button-container {
    position: absolute;
    right: 0;
    bottom: -7px;
    width: 76px; }
