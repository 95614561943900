@import '~styles/mixins.sass';

.content-container {
	width: 100%;
	padding-top: 56px;
	@extend %row;
	@include center-all; }

.single-row-container {
	width: 100%;
	@extend %row;
	// @include center-all
	padding: 0; }

.single-row-container-start {
	width: 100%;
	@extend %row;
	@include center-all;
	padding: 0;
	justify-content: flex-start; }

.single-container {
	width: 100%;
	margin-bottom: 20px;
	@extend %col-md-6;
	@extend %col-sm-12;
	@include center-all;
	padding: 0; }

.single-container[class^='child'], .single-container[class*=' child'] {
	padding: 0; }
.single-container.child-1 {
	padding-right: 11px; }
.single-container.child-2 {
	padding-left: 11px; }

.title-container {
	width: 100%;
	box-sizing: border-box;
	padding-top: 0;
	padding-bottom: 24px;
	display: flex;
	justify-content: space-between;
	align-items: center; }

.title-container-half {
	@extend %col-md-6;
	@extend %col-sm-12;
	box-sizing: border-box;
	padding-top: 0;
	padding-bottom: 24px;
	display: flex;
	justify-content: space-between;
	align-items: center; }

.boxes-container {
	@include col-journeyDashboard;
	@include center-all;
	box-sizing: border-box;
	padding-top: 0;
	display: flex;
	justify-content: space-between; }

.single-box {
	width: 100%;
	// min-height: 283px
 }	// border-radius: 4px

.loader-content {
	height: 250px; }
