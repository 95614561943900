@import '~styles/mixins.sass';

.card {
    display: flex;
    flex-direction: column;
    height: 458px;
    width: 304px;
    min-height: 458px;
    min-width: 304px;
    max-height: 458px;
    max-width: 304px;
    // margin: 0 15px
    background-color: white;
    border: solid 1px #dcdada;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 35px 25px;
    align-items: center;
    justify-content: space-between; }

.card-image {
    width: 130px;
    height: 130px;
    @include display-flex-center-mixin;
    position: relative; }

.card-image-main {
    position: absolute;
    z-index: 2;
    width: 64px;
    height: 95px; }

.card-title {
    width: 100%;
    height: 25%;
    @include display-flex-center-mixin; }

.card-desc {
    width: 100%;
    height: 40%;
    text-align: center;
    overflow-y: auto; }

.card-desc::-webkit-scrollbar {
    width: 6px; }

/* Track */
.card-desc::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px; }

/* Handle */
.card-desc::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px; }

/* Handle on hover */
.card-desc::-webkit-scrollbar-thumb:hover {
    border-radius: 6px;
    background: #555; }
