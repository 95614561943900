@import '~styles/mixins.sass';

.product-exp-container {
    width: 100%;
    @extend %row;
    @include center-all; }

.stat-container {
    height: 65px;
    @include col-assessmentpage; }

.product-items-container {
    @include col-assessmentpage;
    height: 100%; }

.product-and-duration {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    flex-wrap: wrap; }

.product-content-open {
    height: 100%;
    @include col-show-details;
    padding-left: 0;
    padding-right: 0;
    @extend %row; }

.product-content-closed {
    height: 100%;
    @include assessment-inner-left-closed;
    padding-left: 0;
    padding-right: 0;
    @extend %row;
    min-width: 659px; }

.duration-container {
    @include assessment-inner-right-open;
    @extend %row;
    justify-content: center;
    justify-items: flex-start; }

.duration-container-closed {
    @include assessment-inner-right-closed;
    @extend %row;
    justify-content: center;
    justify-items: flex-start; }

.duration-container-row {
    @include content;
    @extend %row;
    padding-left: 0;
    padding-right: 0; }

.stat-container-show-details {
    height: 65px;
    @include col-show-details;
    padding: 0; }

.product-items-container-show-details {
    @include col-show-details;
    height: 100%;
    padding: 0; }

.product-exp-container-show-details {
    width: 100%;
    @extend %row;
    @include center-all;
    padding: 0; }
