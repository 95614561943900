@import '~styles/mixins.sass';

.next-activity-container {
    width: 100%;
    @extend %row;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 20px; }

.next-activity-container-development {
    width: 100%;
    @extend %row;
    @include center-all;
    box-sizing: border-box;
    padding: 0;
    text-align: left;
    position: relative; }

.next-activity-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1; }

.content-outer-cnt {
    @include display-flex-center-mixin;
    padding-top: 15px;
    box-sizing: border-box; }

.next-activity-content {
    @include col-journeyDashboard;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    padding: 10px 32px 20px 32px;
    box-sizing: border-box; }

.next-activity-content-development {
    @extend %col-xs-9;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    padding: 10px 32px 20px 32px;
    box-sizing: border-box; }

.next-activity-gradient {
    position: absolute; }

.content-container {
    width: 65%;
    @include display-flex-start; }

.content-container .tag-line {
    padding-bottom: 8px; }

.content-container .title {
    text-align: left;
    line-height: 1.2; }

.content-container .stats {
    padding-bottom: 2px; }

.button-container {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: flex-end; }

.button-continue {
    width: 240px;
    height: 48px;
    @include display-flex-center-mixin; }

.next-activity-loader-content {
    @extend .next-activity-content;
    justify-content: flex-start;
    height: 100%; }

.loader-container {
    height: 194px; }

@media screen and (max-width: 480px) {
    .next-activity-content {
        max-width: 100vw;
        padding-left: 15px;
        padding-right: 15px; } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
    .next-activity-content {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        flex-basis: unset !important;
        max-width: unset !important; } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
    .next-activity-content {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        flex-basis: unset !important;
        max-width: unset !important; } }
