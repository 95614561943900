@import '~styles/mixins.sass';

.offlineBar-container {
	width: 100%;
	min-height: 32px;
	@extend %row;
	@include center-all; }

.offlineBar-content {
	@include col-landingpage;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	flex-wrap: wrap;
	min-height: 32px; }

.offlineBar-left-content {
	height: 100%;
	@include display-flex-center-mixin; }

.offlineBar-right-content {
	height: 100%;
	@include display-flex-center-mixin; }

.content {
	padding-left: 10px;
	padding-right: 10px; }

.try-now-button {
	cursor: pointer; }

.offline-image {
	width: 22px;
	height: 22px; }
