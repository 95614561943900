@import '~styles/mixins.sass';

.overall-graph-container {
    width: 100%;
    @include center-all;
    @extend %row;
    padding: 0; }

.two-graph-content {
    display: flex;
    width: 100%;
    @include center-all;
    @extend %col-md-6;
    @extend %col-sm-6; }

.single-graph-content {
    width: 100%;
    padding-bottom: 55px; }

.content {
   width: 100%; }
