@import '~styles/mixins.sass';

.exit-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }

.exit-message-container {
    min-width: 363px;
    height: 149px;
    background-color: #f9f9fb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }

.buttons-holder {
    height: 63px;
    min-width: 363px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }

.button-end {
    display: flex;
    justify-content: space-around;
    width: 40%;
    margin-right: 20px; }

.button {
    padding-left: 3px; }

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 40;
    overflow: hidden;
    @include noselect;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.69); }
