@import '~styles/mixins.sass';

.info-container {
    @include display-flex-center-mixin;
    box-sizing: border-box;
    min-height: 350px;
    flex-direction: column;
    width: 100%; }

.info {
    display: flex;
    width: 88px;
    height: 88px;
    background-color: rgba(9, 16, 39, 0.15);
    border-radius: 50%;
    align-items: center;
    justify-content: center; }

.info-image {
    width: 28px; }

.info-text {
    opacity: 0.4;
    margin-top: 24px;
    word-break: break-all;
    text-align: center;
    width: 290px; }
