.main-container {
    display: flex;
    align-items: center;
    height: calc(100vh - 50px);
    position: relative;
    flex-direction: column; }

.progress-container {
    height: 35px;
    width: 400px;
    display: flex;
    align-items: center;
    margin-top: 47px; }

.outer-bar {
    width: 100%;
    height: 6px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
 }    // margin-bottom: 8px

.inner-bar {
    border-radius: 4px;
    height: 100%;
    min-width: 4px; }

.loading-container {
    display: block;
    margin-top: 150px; }

.logo-container {
    display: flex;
    justify-content: center; }

.logo {
    width: 210px; }

.success-main-container {
    display: block;
    margin-top: 150px; }

.success-container {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    align-items: center; }

.error-results-container {
    display: block;
    margin-top: 120px; }

.actions-cnt {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 80px; }

.welcome-user-bg {
    width: 100%;
    height: 150px;
    z-index: 0;
    opacity: 0.3;
    margin-bottom: 50px;
    div {
        height: 100%; } }

.progress-text {
    display: flex;
    justify-content: space-between; }

.success-image {
    margin-bottom: 25px; }

.progress-wait-text {
    width: 335px; }

@media only screen and (max-width: 480px) {
    .progress-container {
        width: 350px; }
    .loading-container {
        width: 350px; } }
