@import '~styles/mixins.sass';

.star-rating-question-cnt {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center; }

.question-text {
	margin: 8px 0;
	text-align: left; }

.rating-stars-cnt {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 50px;
	.individual-star {
		height: 28px;
		width: 28px;
		margin-right: 10px;
		cursor: pointer; } }
