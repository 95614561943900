.library-loader-cnt{
  height: 50px;
  width: 50px;
  margin: 30px auto;
}

.overflow-x {
  overflow-x: scroll;
}

.overflow-y {
  overflow-y: scroll;
}

.overflow-y .pdfViewer .page {
  margin-top: 5px;
}

.overflow-hidden {
  overflow: hidden !important;
}

#document-container {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f5f5f5;
}

#document {
  width: 100%;
  height: calc(100% - 52px);
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}

#document:focus {
  outline: none;
}

.presentation #document {
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 52px;
  left: 0;
  margin: 0;
  overflow: hidden;
  letter-spacing: normal;
  -webkit-tap-highlight-color: transparent;
}

.toolbar {
  background-color: rgb(62, 62, 62);
  width: 100%;
  height: 48px;
  color: white;
  font-size: 12px;
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 13px 24px;
  box-sizing: border-box;
}

.presentation .toolbar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.toolbar div {
  cursor: pointer;
  font-weight: bold;
  color: white;
}

.invisible-page {
  visibility: hidden;
}

.page-nav {
  width: 120px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
}

.fullscreen {
  margin-left: auto;
  cursor: pointer;
}

.zoom-controls {
  cursor: pointer;
}

.zoom-out{
  display: inline-block;
  margin-right: 9px;
}

.loading-container {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-icon {
  height: 72px;
  margin-bottom: 10px;
}

.fullscreen-cnt {
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  height: 100vh !important;
  width: 100vw !important;
}

.presentation .pdfViewer .page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.presentation .progress-container {
  position: absolute;
  bottom: 48px
}

.page-status-container {
  height: 100%;
  min-width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-status-text {
  padding: 5px;
  background: #fff;
  /* #555;*/
  color: #000 !important;
  border-radius: 3px;
}

.page-status-container input[type="text"] {
  width: 24px;
  text-align: center;
  height: 15px;
}

.page-nav-arrow-container {
  padding: 3px;
}

.right-controls{
  display: flex;
}

@media only screen and (max-height: 575.98px) and (orientation: landscape){
  .page-nav{
    transform: scale(0.85) translateX(-65%);
  }
}

@media only screen and (min-device-width : 490px) and (max-device-width : 1024px) and (orientation : portrait){
  .page-nav{
    width: 100px;
    transform: scale(0.85) translateX(-85%);
  }
  .page-status-container{
    min-width: 50px;
  }

  .sidebar-open .page-nav{
    transform: scale(0.85) translateX(-95%);
  }

  .sidebar-open .presentation-toggle-container{
    width: 60px;
  }

  .sidebar-open .toggle-label{
    display: none;
  }
  
}

/* overrides */

#document-container .pdfViewer .page {
  border-image: none;
}

#document-container .pdfViewer .page .loadingIcon {
  background: url("../../../images/loading-icon.svg") center no-repeat;
}
