@import '~styles/mixins.sass';

.exp-container {
    min-height: 592px;
    width: 100%;
    @extend %row;
    @include center-all;
    overflow-x: hidden;
    padding-top: 30px;
    padding-bottom: 20px; }

.exp-content-container {
    width: 80%;
    @include center-all;
    @extend %row;
    @include col-landingpage; }

.exp-content {
    width: 60%;
    box-sizing: border-box;
    min-height: 280px;
    padding-left: 0;
    padding-right: 40px;
    @extend %col;
    @include center-all; }

.exp-content-full-width {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px; }

.exp-content-title {
    text-align: left;
    width: 100%;
    padding-bottom: 40px; }

.exp-content-desc {
    text-align: left;
    width: 100%; }

.analytics-card-container {
    width: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    min-height: 350px; }

.card-dots-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 15px;
    width: 100%;
    max-width: 370px; }

.single-dot {
    height: 5px;
    border-radius: 5px;
    cursor: pointer; }

@media screen and (max-width: 480px) {
    .exp-container {
        padding-left: 10px;
        padding-right: 10px; }
    .exp-content-container {
        width: 100%; }
    .exp-content {
        width: 100%;
        padding-right: 10px; }
    .exp-content-title {
        padding-bottom: 10px; }
    .analytics-card-container {
        width: 100%; } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
    .exp-container {
        padding-left: 10px;
        padding-right: 10px; }
    .exp-content-container {
        width: 100%; }
    .exp-content {
        width: 100%;
        padding-right: 10px; }
    .exp-content-title {
        padding-bottom: 10px; }
    .analytics-card-container {
        width: 100%;
        align-items: center; } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
    .exp-container {
        padding-left: 10px;
        padding-right: 10px; }
    .exp-content-container {
        width: 100%; }
    .exp-content {
        width: 100%;
        padding-right: 10px; }
    .exp-content-title {
        padding-bottom: 10px; }
    .analytics-card-container {
        width: 100%;
        align-items: center; } }
