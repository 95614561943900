@import '~styles/mixins.sass';

.module-item-inner-cnt {
    @extend %col-xs-10;
    @extend %col-lg-10;
    padding-left: 104px;
    min-height: calc(100vh - 20px);// - 165px)
    margin-left: 71px;
    margin-bottom: 40px;
    // margin: 0 auto
    // box-sizing: border-box
    // width: 100%
 }    // overflow-x: hidden


.sidebar-open.module-item-inner-cnt {
    @extend %col-xs-8;
    padding-left: 104px;
    margin-left: 340px; }

.bottom {
    width: 100%;
 }    //z-index: 10

.locked-message {
    margin: 20px 0px;
    font-size: 20px; }

@media only screen and (max-width: 480px) {
    .module-item-inner-cnt {
        padding-left: 10px;
        margin-left: 60px;
        width: calc(100vw - 60px);
        margin-bottom: 70px; } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
    .module-item-inner-cnt {
        padding-left: 10px;
        margin-left: 80px;
        width: calc(100vw - 60px);
        margin-bottom: 70px; } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
    .module-item-inner-cnt {
        padding-left: 10px;
        margin-left: 80px;
        width: calc(100vw - 60px);
        margin-bottom: 70px; } }
