@import '~styles/mixins.sass';

.module-item-outer-cnt {
    @extend %row;
    padding: 0;
    min-height: calc(100vh - 124px); }

.menu-container {
    @extend %col-xs-1;
    max-width: 71px;
    z-index: 2;
    background-color: white;
    transition-duration: .5s;
    position: fixed;
    height: 100vh;
    width: 71px; }

.sidebar-open .menu-container {
    @extend %col-xs-3;
    max-width: 340px;
    min-width: 340px;
    transition: width 500ms;
    padding: 0; }

.module-item-loading {
    min-height: calc(100vh - 20px); }// - 165px)

@media only screen and (max-width: 480px) {
    .module-item-outer-cnt {
        position: relative; }
    .menu-container {
        width: 60px;
        max-width: 60px; } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
    .module-item-outer-cnt {
        position: relative; }
    .menu-container {
        width: 60px;
        max-width: 60px; } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
    .module-item-outer-cnt {
        position: relative; }
    .menu-container {
        width: 60px;
        max-width: 60px; } }
