@import '~styles/mixins.sass';

.row {
    @extend %row;
    padding: 0; }

.launch-btn-cnt {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    margin-right: 40px; }

.duration-cnt {
    width: 50%; }

.details {
    @extend %col-xs-12;
    @extend %col-lg-9;
    padding: 0; }

.sidebar-open .details {
    @extend %col-xs-12;
    padding: 0; }

.side-items {
    @extend %col-xs-12;
    @extend %col-lg-3;
    border-left: solid 1px rgba(213, 216, 226, 0.5);
    border-right: solid 1px rgba(213, 216, 226, 0.5);
    height: 200px;
    margin-top: 64px; }

.sidebar-open .side-items {
    @extend %col-xs-12;
    display: flex;
    border: none;
    padding: 0;
    height: 90px;
    margin-top: 0;
    align-items: center; }

.video-cnt {
    @extend %col-xs-12;
    padding: 0; }

.faq-cnt {
    @extend %col-xs-12;
    padding: 0;
    margin-bottom: 20px; }

.fullscreen-options-cnt {
    height: 23px;
    background: #000000;
    color: #ffffff;
    display: flex; }

.fullscreen-options-cnt-inner {
    width: 170x;
    margin-left: auto;
    margin-right: 10px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center; }

.option {
    cursor: pointer; }

.sim-cnt {
    width: 100vw;
    height: calc(100vh - 23px);
    border: none; }

.launched {
    position: fixed;
    z-index: 1000001;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgb(250, 250, 250); }

.loader-cnt > div {
    height: 200px !important;
    margin-top: 40px; }

@media only screen and (max-width: 480px) {
    .row {
        flex-direction: column; }
    .side-items {
        margin-top: 10px;
        margin-bottom: 120px; } }


@media only screen and (min-device-width : 490px) and (max-device-width : 1150px) {
    .sidebar-open .side-items {
        flex-direction: column; }
    .sidebar-open .launch-btn-cnt {
        justify-content: unset;
        margin-right: unset; } }

