
.tool-items-outer-cnt {
	position: fixed;
	top: 0px;
	left: 0px;
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100001; }

.tool-items-cnt {
	min-height: 85px;
	box-sizing: border-box;
	// overflow: hidden
	width: 664px;
	height: 608px;
	border-radius: 6px;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
	border: solid 1px #ffffff;
	background-color: #ffffff;
	z-index: 10;
	position: relative; }

.items-list-scrollable-cnt {
	width: 100%;
	max-height: 475px;
	overflow-y: auto;
	padding: 0 40px;
	box-sizing: border-box; }

/* width */
.items-list-scrollable-cnt::-webkit-scrollbar {
	position: absolute;
	right: -10px;
	top: 0;
	width: 2px; }

/* Track */
.items-list-scrollable-cnt::-webkit-scrollbar-track {
	border-radius: 3px; }

/* Handle */
.items-list-scrollable-cnt::-webkit-scrollbar-thumb {
	background: rgba(0,0,0, 0.3);
	border-radius: 3px; }

/* Handle on hover */
.items-list-scrollable-cnt::-webkit-scrollbar-thumb:hover {
	background: rgba(0,0,0, 0.2); }

.item-list-header {
	height: 60px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	padding-left: 30px;
	box-sizing: border-box;
	border-radius: 6px 6px 0 0;
	.header-text {
		width: 100%;
		display: flex;
		justify-content: flex-start; }
	.color-palette-cnt {
		height: 35px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba(255, 255, 255, 0.1);
		padding-left: 15px;
		border-radius: 17px 0 0 17px;
		.color-circle {
			height: 20px;
			width: 20px;
			border-radius: 100%;
			margin-right: 20px;
			border: 1px solid rgba(255, 255, 255, 0.5);
			cursor: pointer;
			display: none; }
		.palette-icon-cnt {
			height: 20px;
			width: 20px;
			margin-right: 20px; }
		&:hover .color-circle {
			display: block; } } }

.individual-item {
	width: 100%;
	min-height: 35px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	border-bottom: solid 1px rgba(0,0,0,0.1);
	.close-btn-cnt {
		width: 15px;
		height: 15px;
		visibility: hidden; }
	&:hover .close-btn-cnt {
		visibility: visible; }
	&:last-child {
		border: none; } }

.individual-input-box {
	width: 100%;
	border: none;
	outline: none;
	height: 59px;
	border: none;
	background-color: transparent;
	&:focus ~ .individual-note {
		display: block; } }

.individual-note {
	position: absolute;
	bottom: 0;
	right: 0;
	display: none; }

.input-box-cnt {
	width: 100%;
	margin-top: 5px;
	padding: 0 40px;
	box-sizing: border-box;
	position: absolute;
	bottom: 0;
	left: 0;
	margin-bottom: 20px;
	.input-box {
		width: 100%;
		border-radius: 19px;
		height: 38px;
		padding: 0 155px 0 20px;
		box-sizing: border-box;
		outline: none;
		border: 1px solid rgba(0, 0, 0, 0.2);
		&:focus ~ .current-input-note {
			display: block; } }
	.current-input-note {
		position: absolute;
		bottom: 8px;
		right: 45px;
		display: none; } }

.close-popup-btn-cnt {
	position: absolute;
	top: 0;
	right: -30px;
	height: 18px;
	width: 18px; }

.modal {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1; }
