@import '~styles/mixins.sass';

.individual-phase-item-cnt {
    margin-top: 32px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    background-color: white;
    border-radius: 4px;
    min-width: 800px;
    padding: 18px 32px 21px 24px;
    border: solid 1px rgba(151, 158, 183, 0.27);
    height: 177px;
    min-height: 167px;
    @include display-flex-column;
    &:hover {
        box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.11), 0 4px 16px 0 rgba(0, 0, 0, 0.06); } }

.module-sub-cnt {
    @include display-flex-center-mixin; }

.module-sub-cnt.header {
    height: 20px;
    margin-bottom: 8px; }

.module-sub-cnt.content {
    height: 100%; }

.module-sub-cnt.footer {
    height: 18px; }

.completion-status-cnt {
    width: 136px;
    margin-right: -32px;
    margin-top: -6px;
    display: flex;
    justify-content: flex-end; }

.image-container {
    width: 80px;
    height: 80px;
    position: relative;
    img {
        width: 100%;
        height: 100%; } }

.content-container {
    @include display-flex-column;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin-right: 24px;
    text-align: left; }

.title, .desc {
    width: 100%; }

.title {
    white-space: nowrap;
    overflow: hiden;
    text-overflow: ellisis; }
.des {
    padding-bottom: 14px;
    height: 42px; }

.module-index-cnt {
    width: 100%;
    text-align: left;
    display: flex; }

.lock-msg-cnt {
    display: flex;
    .locked-label {
        padding: 0 10px;
        margin-left: 10px; }
    .locked-msg {
        padding-left: 10px; } }

.duration-label {
    padding-left: 10px;
    margin-left: 10px; }

.no-lock-message-duration-label {
    visibility: visible; }

.progress-container {
    height: 35px;
    width: 100%;
    display: flex;
    align-items: center; }

.outer-bar {
    width: 100%;
    height: 4px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
 }    // margin-bottom: 8px

.inner-bar {
    border-radius: 4px;
    height: 100%;
    min-width: 4px; }

.progress-content {
    min-width: 132px;
    padding-left: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    justify-content: flex-end;
    position: relative; }

.dev-phase-timeline-cnt {
    position: absolute;
    left: -73px;
    top: 70px; }

.dev-phase-timeline-outer-circle {
    border-width: 4px;
    border-style: solid;
    // border-color: rgb(213, 217, 231)
    border-color: transparent;
    border-image: initial;
    padding: 4px;
    box-sizing: border-box;
    border-radius: 100%;
    background-color: #f2f3f7;
    cursor: pointer; }

.dev-phase-timeline-inner-circle {
    height: 24px;
    width: 24px;
    border-radius: 100%;
    background-color: #979eb7; }

.dev-phase-timeline-connector {
    width: 4px;
    background-color: rgb(213, 217, 231);
    margin: auto; }

.completion-status {
    background-color: #76ca1a;
    color: white;
    height: 20px;
    border-radius: 4px;
    padding: 0 8px 0 10px;
    box-sizing: border-box;
    border-radius: 4px 0 0 4px;
    text-transform: uppercase; }

.locked-div {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 80px;
    @include display-flex-center-mixin;
    border-radius: 4px; }

.locked-icon {
    width: 26px;
    height: 32px; }

.completion-tag {
    color: white;
    height: 20px;
    border-radius: 4px;
    padding: 0 7px;
    box-sizing: border-box;
    position: absolute;
    top: 12px;
    left: 0px;
    border-radius: 0 4px 4px 0;
    text-transform: uppercase; }

.optional-activities-count {
    position: absolute;
    width: 130px;
    right: 0px;
    text-align: right; }

@media screen and (max-width: 480px) {
    .dev-phase-timeline-cnt {
        display: none; }
    .individual-phase-item-cnt {
        min-width: calc(100vw - 14%);
        padding-left: 10px;
        padding-right: 10px; }
    .locked-msg {
        display: none; }
    .module-index-cnt > :first-child {
        min-width: 20%;
        font-weight: 600; }
    .completion-status-cnt {
        margin-right: -10px; }
    .duration-label {
        flex-basis: 60%; }
    .locked-label {
        border-right: none !important; }
    .duration-label.no-lock-message-duration-label {
        flex-basis: 60%; } }


@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
    .dev-phase-timeline-cnt {
        display: none; }
    .individual-phase-item-cnt {
        min-width: calc(100vw - 14%);
        padding-left: 10px;
        padding-right: 10px; }
    .locked-msg {
        display: none; }
    .module-index-cnt > :first-child {
        min-width: 10%;
        font-weight: 600; }
    .completion-status-cnt {
        margin-right: -10px; }
    .duration-label {
        flex-basis: 60%; }
    .locked-label {
        border-right: none !important; }
    .duration-label.no-lock-message-duration-label {
        flex-basis: 60%; } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
    .dev-phase-timeline-cnt {
        display: none; }
    .individual-phase-item-cnt {
        min-width: calc(100vw - 14%);
        padding-left: 10px;
        padding-right: 10px; }
    .locked-msg {
        display: none; }
    .module-index-cnt > :first-child {
        min-width: 10%;
        font-weight: 600; }
    .completion-status-cnt {
        margin-right: -10px; }
    .duration-label {
        flex-basis: 60%; }
    .locked-label {
        border-right: none !important; }
    .duration-label.no-lock-message-duration-label {
        flex-basis: 60%; } }
