.container {
    height: calc(100% - 35px); }

.feedback-form-container {
    max-width: 872px;
    margin: 40px auto;
    max-height: 500px;
    overflow-y: auto; }

.header {
    margin-left: 20px;
    display: flex; }

.backarrow-icon {
    width: 29px;
    height: 23px;
    cursor: pointer; }

.entity-name {
    margin-left: 30px; }
