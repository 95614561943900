@import '~styles/mixins.sass';

.api-exposure-areas-cnt {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white; }

.api-exposure-areas-content-cnt {
	width: 100%;
	max-height: calc(100vh - 94px);
	overflow: auto;
	height: 100%;
	max-width: 1280px;
	margin: auto;
	.step-header-text {
		height: 55px;
		background-color: #f5f6f9;
		@include display-flex-center-mixin;
		margin: 30px 0 20px 0; }
	.cards-outer-cnt {
		width: 100%;
		max-width: 765px;
		margin: auto;
		margin-bottom: 50px;
		display: flex;
		justify-content: space-between;
		margin-top: 100px;
		.individual-card-cnt {
			max-width: 270px;
			min-width: 270px;
			position: relative; }
		.header {
			text-align: center;
			position: absolute;
			top: -51px;
			width: calc(100% + 30px);
			left: -15px; }
		.tool-items-list-card-cnt {
			position: relative;
			margin-top: 35px; }
		.connector-line {
			position: absolute;
			width: 0;
			border-left: 1px dashed #7157A9CC;
			top: 10px;
			height: 100px;
			left: 134.5px; } } }
