.retry-link {
    text-decoration: underline;
    margin-top: 10px;
    cursor: pointer;
    display: block; }

.error-heading {
    margin-top: 25px; }

.error-message {
    margin-top: 10px; }

.error-image-cnt {
    height: 187px;
    width: 300px;
    margin: 0 auto; }
