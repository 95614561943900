.text-area-container {
    width: 100%;
    height: 67px;
    display: flex;
    flex-direction: colum;
    border: solid 1px #d5d8e2; }

.text-area {
    height: 95%;
    width: 80%;
    background-color: rgba(213, 216, 226, 0.15);
    border-right: solid 1px #d5d8e2 !important;
    border: none; }

.button-style {
    height: 100%;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(213, 216, 226, 0.15);
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
        text-decoration: underline; } }
