@import '~styles/mixins.sass';

.progress-container-closed {
    @extend %row;
    @include center-all;
    @include assessment-inner-left-closed;
    background-color: white;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 40px;
    min-height: calc(100vh - 338px); }

.progress-container-open {
    @extend %row;
    @include center-all;
    @include assessment-inner-left-open;
    background-color: white;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 40px;
    min-height: calc(100vh - 338px); }

.items-container {
    width: 100%;
    text-align: center;
    text-align: -webkit-center; }

.progress-items-container {
    height: 71px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include padding-left-right-zero;
    @include col-assessmentpage;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }

.border {
    @include padding-left-right-zero;
    @include col-assessmentpage;
    height: 4px;
    overflow: hidden;
    position: relative;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }

.border-moving {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 0px;
    -webkit-animation: progress 1.5s infinite /* Safari 4.0 - 8.0 */;
    animation: progress 1.5s infinite; }

@-webkit-keyframes progress {
    0% {
        left: 0%; }
    100% {
        left: 100%; } }

@keyframes progress {
    0% {
        left: 0%; }
    100% {
        left: 100%; } }
