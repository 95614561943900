.file-content-container {
  overflow: hidden;
  height: calc(100vh - 400px);
  min-height: 480px;
  width: 100%;
  margin: 20px auto;
  position: relative; }

.document-container {
  padding: 10px 0px; }

.download-link {
  height: 80px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px auto;
  text-decoration: none;
  color: rgb(46, 52, 75);
  &:hover {
    text-decoration: none; } }

.icon-cnt {
  height: 48px;
  width: 48px; }

.download-div {
  padding: 10px; }

.image {
  width: 100%;
  margin-top: 10px; }
