
@import '~styles/mixins.sass';

.container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 62px;
    align-items: center; }
.icon {
    height: 62px;
    cursor: pointer;
    @include display-flex-center-mixin; }

.outer-margin {
    margin-left: 21px;
    margin-right: 36px; }

.home-icon {
    @include display-flex-center-mixin;
    height: 18px;
    width: 20px;
    cursor: pointer; }

.lock-icon {
    height: 16px;
    margin-right: 5px; }

.text {
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    box-sizing: border-box;
    white-space: nowrap; }
