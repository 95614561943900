@import '~styles/mixins.sass';

.tooltip-header {
	border-bottom: 0.5px solid rgba(9, 16, 39, 0.2);
	font-size: 10px;
	opacity: 0.8;
	padding: 5px;
	width: 90%;
	display: flex;
	justify-items: flex-start; }

.tooltip-content {
	padding: 4px; }
