@import '~styles/mixins.sass';

.score-container {
    position: relative;
    width: 22px;
    height: 22px; }

.report-progress-container {
    position: relative;
    height: 50px;
    width: 50px; }
