@import '~styles/mixins.sass';

.header-container {
	@include display-flex-center-mixin;
	width: 100%;
	height: 59px; }

.hidden {
	display: none; }

.support-btn-cnt {
	position: fixed;
	top: 265px;
	height: 44px;
	padding: 0 12px;
	align-items: center;
	display: none;
	// transform: rotate(270deg)
	z-index: 10001;
	border-radius: 8px 8px 0 0;
	cursor: pointer;
	.support-icon-cnt {
		height: 15px;
		width: 15px;
		margin-right: 8px;
		transform: rotate(90deg);
		margin-bottom: 2px; } }

@media screen and (min-width: 1440px) {
	.support-btn-cnt {
		top: 430px; } }

.support-btn-cnt.right {
	right: 0;
	transform: rotate(-90deg);
	transform-origin: 100% 100%; }

@media only screen and (max-width: 480px) {
	.support-btn-cnt.right {
		transform: rotate(0);
		top: 0;
		border-radius: 0;
		height: 22px;
		padding: 0 6px;
		position: absolute; }
	.support-icon-cnt {
		margin-right: 0 !important; } }
