@import '~styles/mixins.sass';

.options-container {
    @include display-flex-center-mixin;
    height: 100px; }

.image {
    padding: 5px; }

.outside-circle {
    width: 59px;
    height: 59px;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    @include display-flex-center-mixin;
    // &:active
 }    //     animation: ripple 0.75s

.outside-circle.tdown {
    margin-left: 20px; }

.inside-circle {
    box-shadow: 0 1px 2px 4px rgba(0, 0, 0, 0.25);
    background-color: white;
    border-radius: 50%;
    height: 56px;
    width: 56px;
    @include display-flex-center-mixin;
    cursor: pointer; }

.assesment-container {
    width: 100%;
    height: calc(100vh - 59px);
    box-sizing: border-box;
    overflow-x: hidden;
    display: flex;
    flex-direction: column; }

.side-bar-product-container {
    display: flex;
    width: 100%;
    margin-bottom: -1px;
    position: relative;
    height: 100%; }

.menu-container {
    position: absolute;
    height: 100%;
    overflow: hidden;
    z-index: 2;
    background-color: white;
    box-shadow: 1px 0 0 0 #d5d8e2; }

.side-bar-collapsed {
    width: 71px;
    transition-duration: .5s;
    position: fixed;
    height: 100%;
    background-color: #ffffff;
    left: 0;
 }    // top: 67px
.assesment-items-collapsed {
    width: 100%;
    display: flex; }

.side-bar-expanded {
    width: 350px;
    transition-duration: .5s;
    height: 100%;
    position: fixed; }

.assesment-items-expanded {
	display: flex;
	margin-left: 350px;
	width: calc(100% - 350px);
	box-sizing: border-box; }

.content-row {
    @extend %row;
    @include center-all;
    height: 100%;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden; }

.feedback-scrollable-content {
    width: 100%;
    height: calc(100% - 159px);
    overflow-y: auto; }

.feedback-row {
    @extend %row;
    @include center-all;
    min-height: 544px;
    width: 100%; }

.feedback-col {
    @extend %col-lg-10;
    @extend %col-md-10;
    @extend %col-sm-10; }

.feedback-box-container {
    width: 100%;
    // min-height: 195px
    display: flex;
    flex-direction: column;
    align-items: flex-start; }

.feedback-box-content {
    width: 100%;
    min-height: 195px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.14);
    overflow: hidden;
    border-radius: 4px;
    @include display-flex-center-mixin;
    flex-direction: column; }

.main-feedback-box {
    width: 100%;
    height: 195px;
    position: relative; }

.main-feedback-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.main-feedback-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }

.main-feedback-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    @include display-flex-center-mixin;
    flex-direction: column; }

.show-details-button {
    width: 165px;
    box-sizing: border-box;
    padding: 15px 20px;
    @include display-flex-center-mixin;
    border-radius: 4px;
    text-transform: uppercase;
    margin-top: 40px; }

.assessment-result-text {
    // padding-top: 30px
    margin: 8px 0;
    text-align: center; }

.feedback-main-heading {
    padding-top: 11px; }

.ripple-holder {
    height: 90px;
    width: 90px;
    @include display-flex-center-mixin; }

.ripple-circle-1 {
    width: 59px;
    height: 59px;
    background-color: #2c4bb7;
    border-radius: 50%;
    padding: 10px;
    @include display-flex-center-mixin; }

.ripple-circle-2 {
    width: 59px;
    height: 59px;
    background-color: #5782dc;
    border-radius: 50%;
    padding: 5px; }

.ripple-circle-1-no-effect {
    width: 59px;
    height: 59px;
    border-radius: 50%;
    padding: 10px;
    background-color: none;
    @include display-flex-center-mixin; }

.ripple-circle-2-no-effect {
    width: 59px;
    height: 59px;
    background-color: none;
    border-radius: 50%;
    padding: 5px;
    @include display-flex-center-mixin; }

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5); } }
