@import '~styles/mixins.sass';

.header-cnt {
	width: 100%;
	min-height: 55px;
	max-height: 55px;
	height: 55px;
	@include display-flex-space-between;
	align-items: center;
	padding: 10px 50px;
	box-sizing: border-box;
	.organization-logo-cnt {
		height: 100%;
		img {
			height: 100%;
			width: auto; } }
	.sign-in-cnt {
		border-left: 2px solid #D6D6D6;
		height: 100%;
		padding-left: 15px;
		@include display-flex-start;
		img {
			width: 24px;
			height: 24px;
			min-width: 24px;
			margin-right: 15px; } } }
