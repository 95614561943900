@import '~styles/mixins.sass';

.tooltip-header {
	border-right: 0.5px solid rgba(9, 16, 39, 0.2);
	font-size: 10px;
	opacity: 0.8;
	padding: 5px;
	width: 50%;
	display: flex; }

.tooltip-content {
	padding: 4px; }
