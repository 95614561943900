@import '~styles/mixins.sass';

.assesment-container {
    width: 100%;
    // height: calc(100vh - 59px)
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden; }

.side-bar-product-container {
    display: flex;
    width: 100%;
    margin-bottom: -1px;
    position: relative;
    height: 100%; }

.menu-container {
    position: absolute;
    height: 100%;
    overflow: hidden;
    z-index: 2;
    background-color: white;
    box-shadow: 1px 0 0 0 #d5d8e2; }

.side-bar-collapsed {
    width: 71px;
    transition-duration: .5s;
    position: fixed;
    height: 100%;
    background-color: #ffffff;
    left: 0;
    top: 0;
    z-index: 4;
 }    // top: 67px
.assesment-items-collapsed {
    width: 100%;
    display: flex; }

.side-bar-expanded {
    width: 350px;
    transition-duration: .5s;
    // height: 100%
    // position: fixed
    top: 0;
    left: 0;
    z-index: 4; }

.assesment-items-expanded {
	display: flex;
	margin-left: 350px;
	width: calc(100% - 350px);
	box-sizing: border-box; }

.content-row {
    @extend %row;
    @include center-all;
    height: 100%;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden; }

.feedback-scrollable-content {
    padding-top: 60px;
    width: 100%;
    // height: calc(100% - 159px)
    overflow-y: auto;
    box-sizing: border-box;
 }    // z-index: 2

.feedback-row {
    @extend %row;
    @include center-all;
    min-height: calc(100vh - 328px);
    width: 100%; }

.feedback-col {
    @extend %col-lg-10;
    @extend %col-md-10;
    @extend %col-sm-10;
    max-width: 1280px; }

.feedback-box-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }

.feedback-box-content {
    margin-top: 48px;
    width: 100%;
    min-height: 195px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.14);
    overflow: hidden;
    border-radius: 4px; }

.main-feedback-box {
    width: 100%;
    height: 195px;
    position: relative; }

.main-feedback-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.main-feedback-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }

.main-feedback-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3; }

.show-details-button {
    width: 165px;
    box-sizing: border-box;
    padding: 15px 20px;
    @include display-flex-center-mixin;
    border-radius: 4px;
    text-transform: uppercase;
    margin-top: 40px; }

.assessment-result-text {
    padding-top: 30px; }

.feedback-main-heading {
    padding-top: 11px; }

.item-details-holder {
    width: 100%;
    @extend %row;
    padding: 0; }

.completed-title-container {
    @extend %row;
    @include center-all;
    background-color: white;
    width: 100%;
    height: 79px;
    position: relative; }

.completed-title-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.completed-content {
    @include padding-left-right-zero;
    @include col-assessmentpage;
    @extend %row;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 2;
    justify-content: flex-start; }

.feedback-questions-outer-cnt {
    position: fixed;
    bottom: 200px;
    right: 130px;
    z-index: 1000; }

.feedback-questions-inner-cnt {
	position: relative;
	padding: 5px;
	background-color: #f9f9fb;
	box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.06), 0 10px 11px 0 rgba(0, 0, 0, 0.09);
	border: solid 0.5px rgba(212, 73, 128, 0.07); }

.feedback-inner-cnt {
	position: relative;
	padding: 5px;
	background-color: #f9f9fb;
	box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.06), 0 10px 11px 0 rgba(0, 0, 0, 0.09);
	border: solid 0.5px rgba(212, 73, 128, 0.07); }

.close-feedback-button {
	position: absolute;
	height: 16px;
	width: 16px;
	top: -7px;
	right: -5px; }

.minimized-feedback {
	width: 34px;
	height: 34px;
	opacity: 0.8;
	border: solid 1px #d5d8e2;
	background-color: rgba(222, 224, 232, 0.51);
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer; }

.feedback-icon-cnt {
	width: 14px;
	height: 13px; }

.thank-you-note-cnt {
	display: flex;
	align-items: center; }

.tickmark-cnt {
	width: 24px;
	height: 24px;
	margin: 0 5px; }

.learning-phase-feedback-cnt {
    width: 100%; }
