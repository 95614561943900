.dev-row {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap {
    width: 100%; } }

.quiz-content-column {
	width: 70%;
	text-align: left;
	padding-right: 10px;
	box-sizing: border-box; }

.quiz-content-column.sidebar-open {
	width: 100%; }

.ongoing-attempt-text {
	font-size: 20px;
	display: flex;
	align-items: center;
	color: #76CA1A; }

.quiz-details-column {
	width: 30%;
	box-sizing: border-box;
	max-width: 320px;
	margin-top: 64px; }

.quiz-details-column.sidebar-open {
	min-width: 320px;
	margin-top: 20px;
	margin-bottom: 50px;
	max-width: 100%;
	width: 100%;
	display: flex;
	align-items: center; }

.button-cnt {
	width: 90%;
	margin: 0 auto; }

.quiz-details-column.sidebar-open .button-cnt {
	display: flex;
	justify-content: flex-end;
	width: 50%;
	margin-right: 40px; }

.quiz-details {
	padding: 10px 10px 4px 10px;
	box-sizing: border-box; }

.quiz-details-column.sidebar-open .quiz-details {
	width: 50%; }

.quiz-details-column.sidebar-open .quiz-details div:first-child {
	max-width: 350px; }

.quiz-details-inner-cnt {
	margin: 15px 0 35px 0; }

.quiz-details-column.sidebar-open .quiz-details-inner-cnt {
	margin-bottom: 10px; }

.topic-capsule {
	height: 28px;
	border-radius: 14px;
	border: solid 1px #bfc3d2;
	padding: 0 10px;
	margin-right: 6px;
	margin-bottom: 10px; }

.topics-row {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-top: 18px; }

.attempts-left {
	text-align: center; }

.quiz-details-column.sidebar-open .attempts-left {
	text-align: left;
	margin-left: 15px; }

.align-items-center {
	display: flex;
	align-items: center; }

.disabled-btn-style {
	background-color: rgba(44, 75, 183, 0.5);
	box-shadow: rgba(0, 0, 0, 0.19) 0px 14px 16px 0px;
	border-radius: 6px;
	min-width: 240px;
	max-width: 300px;
	display: -ms-flexbox;
	display: flex;
	min-height: 48px;
	cursor: not-allowed;
	text-align: center; }

.disabled-btn-style .text {
	width: 100%; }

.incomplete-submission-score-cnt {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	box-sizing: border-box;
	padding: 25px 20px 25px 0; }

.survey-review-btn-cnt {
	width: 100%;
	display: flex;
	justify-content: center; }

.timer-time {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	flex-direction: column; }

.points {
	font-size: 34px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.6;
	letter-spacing: normal;
	text-align: center;
	color: #d44980; }

.text {
	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.67;
	letter-spacing: normal;
	text-align: center;
	color: #d44980;
	text-transform: uppercase; }

.quiz-score-btn-cnt {
    width: 100%; }

.quiz-score-section {
    display: flex;
    justify-content: flex-end; }
