@import '~styles/mixins.sass';

.user-stats {
	width: 100%;
	height: 84px;
	margin-top: -40px;
	border-radius: 8px;
	box-shadow: 0 20px 25px 0 rgba(0, 0, 0, 0.2), 0 10px 10px 0 rgba(0, 0, 0, 0.04);
	border: solid 1px rgba(151, 151, 151, 0.02);
	margin-bottom: -22px;
	padding: 20px 30px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.individual-stats {
		height: 100%;
		border-left: 1px solid white;
		@include display-flex-center-mixin;
		justify-content: flex-start;
		padding: 0 20px;
		box-sizing: border-box;
		max-width: 20%;
		&:first-child {
			border: unset;
			padding-left: unset; }
		&:first-child .stat-label {
			margin-left: unset; }
		.stat-label {
			margin-right: 20px; } }
	.multi-stat-cnt {
		height: 100%;
		width: 100%;
		.stat-cnt {
			display: flex;
			align-items: center; }
		.stat-label {
			margin: 0 20px; } }
	.course-catalogue-link {
		@include display-flex-end;
		align-items: center;
		width: 100%; } }
