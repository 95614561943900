@import '~styles/mixins.sass';

.score-container {
    position: relative;
    width: 120px;
    height: 120px; }

.score-content {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end; }

.score-progressbar {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0; }

.score-value {
    padding-bottom: 7px; }

.score-by {
    margin-bottom: 7px;
    height: 1px;
    width: 51px; }

.score-total {
    padding-bottom: 8px; }

.score-competency {
    height: 16px;
    box-sizing: border-box;
    padding: 1px 7px;
    margin-bottom: 5px;
    display: flex;
    justify-content: center; }

