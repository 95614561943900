$FAQ-title-font-size: 20px;
@import '~styles/mixins.sass';

.FAQ-container {
    width: 100%;
    @extend %row;
    @include center-all; }

.FAQ-container-for-feedback {
    width: 100%;
    @extend %row;
    @include padding-left-right-zero;
    @include center-all;
    padding-left: 0p; }

.FAQ-title {
    padding-top: 40px;
    padding-bottom: 20px;
    @include col-assessmentpage;
    text-align: left;
    font-weight: bold; }

.FAQ-title-for-feedback {
    padding-top: 40px;
    padding-bottom: 20px;
    width: 100%;
    text-align: left;
    font-weight: bold; }

.row {
    @include col-assessmentpage;
    overflow: hidden;
    display: block; }

.row-for-feedback {
    @include content;
    overflow: hidden;
    display: block;
    // padding-left: 0
 }    // padding-right: 0

.all-q-a-open {
    @include assessment-inner-left-open;
    @include padding-left-right-zero;
    flex-direction: column; }

.all-q-a-closed {
    @include assessment-inner-left-closed;
    @include padding-left-right-zero;
    flex-direction: column; }

.loader-column {
    @include assessment-inner-left-open;
    @include padding-left-right-zero;
    flex-direction: column;
    width: 600px;
    height: 200px; }

.full-width {
    @include content;
    width: 100%;
    @include padding-left-right-zero;
    justify-content: flex-start;
    flex-direction: column; }
