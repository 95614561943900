.fullScreen {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
.iframe-container {
    position: fixed;
    z-index: 10001;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #f2f3f7; }
.options {
    color: white;
    width: 100px; }
.product {
    width: 100%;
    height: 96vh; }
.iframe-header {
    width: 100%;
    height: 4vh;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
.item {
    display: flex;
    box-sizing: border-box;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px; }

.resp-container {
    width: 100%;
    height: 96vh;
    overflow: hidden; }

.resp-container-iphone {
    width: 100%;
    height: -webkit-fill-available;
    padding-bottom: 4vh;
    border: none; }

.icon {
    height: 13px;
    width: 13px;
    margin: 2px; }

.header-left {
    width: 90%;
    height: 100%;
    align-items: center;
    display: flex;
    background-color: rgba(0, 0, 0, 0.69);
    justify-content: flex-end; }

.header-right {
    width: 10%;
    height: 100%;
    align-items: center;
    display: flex;
    padding: 0 1vw 0 1vw;
    box-sizing: border-box;
    cursor: pointer;
    background-color: #000000;
    justify-content: center;
    gap: 1vw; }

.support-btn-cnt {
    align-items: center;
    display: flex;
    background-color: rgba(0, 0, 0, 0.69);
    padding: 0 10px;
    width: fit-content;
    height: 100%;
    cursor: pointer; }

.support-image-cnt {
    height: 20px;
    width: 20px;
    margin: 0 5px;
    transform: rotate(90deg);
    svg {
        margin-top: 2px;
        margin-left: 2px; }
    img {
        width: 100%;
        height: 100%; } }

.exit-item {
    display: flex; }

.overlay {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.69);
    position: fixed;
    z-index: 2; }

.loader-cnt {
    position: absolute;
    top: 0;
    width: 100%;
    height: 96vh;
    z-index: -100; }

@media only screen and (max-width: 480px) {
    .header-right {
        width: 55%; } }
