.feedback-outer-cnt {
	position: fixed;
	bottom: 200px;
	right: 35px;
	z-index: 10;
	width: fit-content; }

.feedback-inner-cnt {
	position: relative;
	padding: 5px;
	background-color: white;
	border-radius: 4px;
	box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.06), 0 10px 11px 0 rgba(0, 0, 0, 0.09);
	border: solid 0.5px rgba(212, 73, 128, 0.07); }
.close-feedback-button {
	position: absolute;
	height: 16px;
	width: 16px;
	top: -7px;
	right: -5px; }

.minimized-feedback {
	width: 34px;
	height: 34px;
	// opacity: 0.8
	border: solid 1px #d5d8e2;
	background-color: rgba(222, 224, 232, 0.51*0.8);
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer; }

.feedback-icon-cnt {
	width: 14px;
	height: 13px; }

.thank-you-note-cnt {
	display: flex;
	align-items: center; }

.tickmark-cnt {
	width: 24px;
	height: 24px;
	margin: 0 5px; }

@media only screen and (max-width: 480px) {
	.feedback-outer-cnt {
		position: absolute;
		bottom: 57px;
		right: unset;
		left: 75px; }
	.feedback-icon-cnt {
		width: 20px;
		height: 20px; }
	.feedback-inner-cnt {
		width: 70%;
		margin-left: -5px; }
	.close-feedback-button {
		height: 20px;
		width: 20px;
		top: -10px;
		right: 70px; } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
	.feedback-outer-cnt {
		position: absolute;
		bottom: 60px;
		right: unset;
		left: 90px; }
	.feedback-icon-cnt {
		width: 20px;
		height: 20px; }
	.feedback-inner-cnt {
		width: 100%;
		margin-left: -5px; }
	.close-feedback-button {
		height: 20px;
		width: 20px;
		top: -5px;
		right: -15px; } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
	.feedback-outer-cnt {
		position: absolute;
		bottom: 95px;
		right: unset; }
	.feedback-icon-cnt {
		width: 20px;
		height: 20px; }
	.feedback-inner-cnt {
		width: 100%;
		margin-left: -5px; }
	.close-feedback-button {
		height: 20px;
		width: 20px;
		top: -5px;
		right: -15px; } }

@media only screen and (min-device-width : 768px) and (max-device-width: 1024px) and (orientation : landscape) {
	.feedback-outer-cnt {
		position: relative;
		right: unset;
		bottom: 0; } }

@media screen and (min-width: 1440px) {
	.feedback-outer-cnt {
		right: 130px; } }
