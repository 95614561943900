@import '~styles/mixins.sass';

.q-a-container {
    @include content;
    padding-top: 20px;
    flex-direction: column;
    padding-bottom: 20px;
    border-bottom: solid 1px;
    cursor: pointer; }

.question-container {
    display: flex;
    justify-content: space-between;
    text-align: left; }

.question {
    font-weight: bold;
    text-align: left;
    line-height: 1.2; }

.answer-hidden {
    width: 90%;
    display: none; }

.answer-visible {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    text-align: left; }

.icon {
    cursor: pointer; }
