@import '~styles/mixins.sass';

.container {
    width: 100%;
    min-height: 520px;
    @extend %row;
    @include padding-left-right-zero; }

.video-container {
    @include content;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px; }
.video-player {
    display: flex;
    justify-content: flex-start;
    @include content;
    padding-left: 0;
    padding-right: 0;
    height: 500px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative; }

.image-container {
    @include content;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px; }
.image-player {
    display: flex;
    justify-content: flex-start;
    @include content;
    padding-left: 0;
    padding-right: 0;
    height: 500px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px; }
