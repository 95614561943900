.dialog-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	align-items: center;
	display: flex;
	z-index: 1000; }

.dialog-overlay {
	position: absolute;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	background-color: rgba(40, 40, 40, 0.9); }

.dialog-content-cnt {
	z-index: 10;
	transform: translate3d(0, 0, 0); //this is for safari since z-index support is not proper.
	width: 500px;
	margin: auto;
	background-color: #f9f9fb;
	border-radius: 4px;
	overflow: hidden; }

.dialog-message-container {
	text-align: center;
	min-height: 100px;
	padding: 20px 30px;
	display: flex;
	align-items: center; }

.action-buttons-cnt {
	height: 63px;
	background-color: #fff;
	display: flex;
	justify-content: flex-end;
	align-items: center; }

.action-buttons-cnt div {
	margin-right: 30px;
	cursor: pointer; }

.loading-msg-cnt {
	height: 63px;
	background-color: #fff;
	text-align: center;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center; }
