@import '~styles/mixins.sass';

.design-a-strategy-cnt {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white; }

.design-a-strategy-content-cnt {
	width: 100%;
	max-height: calc(100vh - 94px);
	overflow: auto; }

.design-a-strategy-content {
	max-width: 1280px;
	margin: auto;
	.step-header-text {
		height: 55px;
		background-color: #f5f6f9;
		@include display-flex-center-mixin;
		margin: 30px 0 20px 0; }
	.cards-outer-cnt {
		width: 100%;
		max-width: 1120px;
		margin: 90px auto;
		padding: 20px;
		box-sizing: border-box;
		border: dashed 1px #97979780;
		display: flex;
		.value-proposition-cnt {
			width: 50px;
			@include display-flex-center-mixin;
			text-align: center;
			.value-proposition-text {
				transform: rotate(270deg);
				min-width: 125px;
				max-width: 125px; } }
		.individual-cards-cnt {
			width: 100%;
			@include display-flex-space-between;
			padding: 0 40px 0 60px;
			box-sizing: border-box; } } }

.individual-card-cnt {
	max-width: 270px;
	min-width: 270px;
	position: relative;
	.header {
		text-align: center;
		position: absolute;
		top: -70px;
		width: 100%;
		text-align: center; }
	.tool-items-list-card-cnt {
		position: relative; }
	.connector-line {
		position: absolute;
		width: 0;
		border-left: 1px dashed #7157A9CC;
		top: -45px;
		height: 100px;
		left: 134.5px; } }

.innovation-levers-cnt {
	width: 100%;
	background-color: #f5f6f9;
	border-radius: 6px;
	margin-top: 40px;
	@include display-flex-center-mixin;
	.economicValue-levers-cnt, .experienceValue-levers-cnt {
		border-right: solid 1px #ffffff; }
	.economicValue-levers-cnt, .exponentialValue-levers-cnt {
		min-width: 35%;
		max-width: 35%;
		.individual-lever {
			margin-left: 60px; } }
	.experienceValue-levers-cnt {
		min-width: 30%;
		max-width: 30%;
		.individual-lever {
			margin-left: 50px; } }
	.levers-cnt-header {
		@include display-flex-center-mixin;
		background-color: #e6e9f3;
		height: 62px; }
	.levers-cnt {
		padding: 10px 0;
		box-sizing: border-box;
		.individual-lever {
			@include display-flex-center-mixin;
			height: 40px;
			border-radius: 20px;
			padding: 0 20px;
			background-color: #ffffff;
			box-shadow: 1px 2px 4px 0 rgba(73, 97, 180, 0.2);
			max-width: 320px;
			margin-top: 20px;
			margin-bottom: 20px;
			width: fit-content;
			cursor: pointer;
			.info-icon-cnt {
				height: 20px;
				width: 20px;
				margin-right: 10px;
				position: relative;
				&:hover {
					.capsule-info-card {
						display: block; } } }
			.add-icon-cnt {
				width: 14px;
				height: 14px;
				margin-left: 10px; }
			.checkmark-icon-cnt {
				width: 14px;
				height: 11px;
				margin-left: 10px; } }
		.individual-lever.selected {
			background-color: #46ba56; } } }

.capsule-info-card {
	width: 230px;
	border-radius: 6px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	background-color: #ffffff;
	padding: 20px;
	box-sizing: border-box;
	display: none;
	position: absolute;
	top: 30px;
	left: -105px;
	z-index: 10000; }
