@import '~styles/mixins.sass';

.no-journey-error-comp {
	width: 100%;
	min-height: 200px;
	.image-cnt {
		width: 275px;
		height: 199px;
		margin: auto; }
	.no-journey-label {
		@include display-flex-center-mixin;
		margin-top: 50px; } }
