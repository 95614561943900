@import '~styles/mixins.sass';

.info-filters-container {
    width: 100%;
    // @extend %row
    // @include center-all
    min-height: 75px; }

.info-filters-content {
    // @extend %col-md-10
    @include display-flex-space-between; }

.info-content {
    @include display-flex-start;
    @include display-flex-column;
    width: 80%;
    box-sizing: border-box;
    padding-left: 54px;
    text-align: left; }

.title-content {
    width: 100%;
    padding-bottom: 15.4px; }

.subtitle-content {
    width: 100%;
    display: flex; }

.filters-container {
    @include display-flex-end;
    width: 20%; }

.filters-content {
    display: flex;
    height: 20px;
    border-radius: 4px; }

.filters-text {
    @include display-flex-center-mixin;
    height: 100%;
    min-width: 42px;
    padding: 0 5px; }

.border-filter {
    border-right: solid 1px #979eb7; }

.logo-container {
    display: flex;
    padding-right: 6px; }
