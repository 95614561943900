@import '~styles/mixins.sass';

.quiz-header-cnt {
	@extend %row;
	height: 64px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
	justify-content: space-between;
	position: absolute;
	width: 100%;
	top: 0;
	left: 0; }

.quiz-title {
	display: flex;
	align-items: center;
	cursor: pointer; }

.quiz-title .title {
	margin-right: 7px;
	max-width: 300px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap; }

.instruction-arrow-cnt {
	width: 12px; }

.timer-cnt {
	padding: 0 44px;
	text-align: center;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center; }

.quiz-actions-cnt {
	display: flex;
	align-items: center; }

.submit-btn, .exit-btn {
	height: 32px;
	border-radius: 4px;
	padding: 0 20px;
	box-sizing: border-box;
	cursor: pointer; }

.submit-btn {
	margin-left: 17px;
	box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.19);
	&:hover {
		box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.19); } }

.my-container {
	width: 450px;
	top: 55px;
	left: 27px;
	position: absolute; }

.quiz-instructions-overlay {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0; }

.quiz-instructions-cnt {
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13), 0 2px 23px 0 rgba(0, 0, 0, 0.11), 0 4px 16px 0 rgba(0, 0, 0, 0.06);
	border: solid 1px rgba(151, 158, 183, 0.27);
	background-color: white;
	padding: 35px;
	border-top: none; }

.triangle-with-shadow {
	// width: 60px
	height: 20px;
	position: relative;
	overflow: hidden;
 }	// box-shadow: 0 16px 10px -17px rgba(0, 0, 0, 0.5)

.triangle-with-shadow:after {
	content: "";
	position: absolute;
	width: 30px;
	height: 30px;
	background: white;
	transform: rotate(45deg);
	top: 7px;
	left: 25px;
	box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5); }
