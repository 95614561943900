@import '~styles/mixins.sass';

.feedback-container {
    width: 100%;
    box-sizing: border-box;
    padding-top: 48px;
    padding-bottom: 48px;
    @extend %row;
    @include center-all; }

.feedback-content {
    @include col-measures;
    height: 195px; }

.main-feedback-box {
    width: 100%;
    height: 195px;
    position: relative; }

.main-feedback-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.main-feedback-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }

.main-feedback-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3; }

.assessment-result-text {
    padding-top: 30px; }

.feedback-main-heading {
    padding-top: 11px; }
