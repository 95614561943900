@import '~styles/mixins.sass';

.complete-container {
	display: flex;
	justify-content: center;
	// height: calc(100vh - 59px)
	overflow-x: hidden;
	// overflow-y: auto
	// position: relative
	@include noselect; }

.content-container {
	// min-width: 1024px
	width: 100%;
	overflow-x: hidden; }
