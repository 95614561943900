@import '~styles/mixins.sass';

.single-phase-container {
    @include col-journeyDashboard;
    @include display-flex-center-mixin; }

.left-side {
    width: 105px;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    @include display-flex-center-mixin; }

.right-side {
    width: calc(100% - 105px);
    margin-left: 105px; }

.timeline-left-container {
    z-index: 2;
    height: 100%; }

.individual-phase-timeline-container {
    padding: 16px 0;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    @include display-flex-center-mixin; }

.individual-timeline-content {
    min-height: 177px;
    width: 100%;
    @include display-flex-center-mixin; }

.individual-timeline-item {
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 4px solid #f2f3f7;
    background-color: #4a90e2; }

.timeline-line {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 4px;
    background-color: black; }

.individual-phase-item-container {
    padding: 16px 0;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none; }

@media screen and (max-width: 480px) {
    .right-side {
        width: calc(100% - 20px);
        margin-left: unset;
        padding-right: 10px; } }
