@import '~styles/mixins.sass';

.author-intro-container {
    min-height: 835px;
    width: 100%;
    // display: flex
    // align-items: flex-end
    // justify-content: center
    position: relative;
    box-sizing: border-box;
    padding-bottom: 60px;
    padding-top: 320px;
    overflow-x: hidden;
    @extend %row;
    @include center-all;
    align-items: flex-end; }

.author-intro-background {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.message-container {
    @include display-flex-center-mixin;
    // position: absolute
    // z-index: 2
    // @extend %row
    // @include center-all
    @include col-landingpage;
    padding-top: 153px;
    min-height: 456px;
    border-radius: 5px;
    position: relative; }

.message-container-overlay {
    padding-top: 85px; }

.message-title-image-container {
    width: 100%;
    display: flex;
    padding-left: 98px;
    box-sizing: border-box;
    position: absolute;
    top: -240px;
    left: 0; }

.message-title-image-overlay {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    position: absolute;
    top: -153px;
    left: 58px; }

.message-image {
    width: 373px;
    height: 373px;
    background-size: 100% 100%;
    // position: absolute
    // top: -240px
 }    // left: 85px

.message-image-overlay {
    height: 221px;
    width: 221px;
    background-size: 100% 100%;
    // position: absolute
    // top: -4vw
 }    // left: 9vw

.message-title {
    @include display-flex-center-mixin;
    width: 50%;
    height: 128px;
    position: relative;
    top: 70px;
    right: -20px; }

.message-title-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.message-title-content {
    @include display-flex-center-mixin;
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }

.message-content {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0px 57px 40px 57px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: justify; }

.message-content-overlay {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0px 57px 40px 57px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: justify; }

.message-content .message {
    text-align: justify; }

.message-content .name, .message-content-overlay .name {
    margin-top: 40px;
    text-align: left; }

.message-content .designation, .message-content-overlay .designation {
    text-transform: uppercase;
    text-align: left;
    margin-top: 2px; }

.message-container-loader {
    @extend .message-container;
    height: 456px; }

.circle-loader-img {
    position: absolute;
    left: 97px;
    top: -259px;
    z-index: 1; }


@media screen and (max-width: 480px) {
    .message-image {
        width: 150px;
        height: 150px; }
    .message-title-image-container {
        padding-left: unset;
        flex-direction: column;
        align-items: center; }
    .message-container {
        padding-top: 25px; }
    .message-title {
        height: 30px;
        top: 30px;
        width: 100%;
        right: unset; }
    .message-title-content {
        top: -3px; }
    .message-content .message {
        text-align: left !important; } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
    .message-image {
        width: 200px;
        height: 200px; }
    .message-title {
        top: 30px;
        left: 20px;
        right: unset; }
    .message-container {
        padding-top: 0px; }
    .message-title-background {
        display: none; }
    .message-content .message {
        text-align: left !important; } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
    .message-image {
        width: 200px;
        height: 200px; }
    .message-title {
        top: 30px;
        left: 20px;
        right: unset; }
    .message-container {
        padding-top: 0px; }
    .message-content .message {
        text-align: left !important; } }
