@import '~styles/mixins.sass';

.submission-types-container {
    display: flex;
    flex-wrap: wrap;
    // border-left: 1px solid rgba(151, 151, 151, 0.4)
    // border-top: 1px solid rgba(151, 151, 151, 0.4)
    margin-top: 30px;
    border-radius: 4px; }

.submission-type-cnt {
    width: calc(33.33%);
    // border-right: 1px solid rgba(151, 151, 151, 0.4)
    // border-bottom: 1px solid rgba(151, 151, 151, 0.4)
    height: 120px;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    color: rgb(34, 59, 146);
    text-transform: uppercase;
    margin-left: -1px;
    margin-top: -1px; }

.assignment-text-area-cnt {
    height: 420px;
    padding: 25px;
    box-sizing: border-box; }

.assignment-text-area {
    resize: none;
    border: none;
    width: 100%;
    height: 90%;
    outline: none; }

.submission-heading {
    height: 50px;
    padding: 10px;
    box-sizing: border-box;
    display: flex; }

.submit-btn-cnt {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 65px;
    justify-content: center; }

.submit-btn-cnt-disabled {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 65px;
    justify-content: center;
    opacity: 0.5;
    pointer-events: none; }

.arrow-cnt {
    width: 24px;
    height: 24px;
    margin-right: 20px;
    cursor: pointer; }

.share-link-cnt {
    height: 250px;
    padding: 25px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center; }

.share-link-input {
    border: none;
    width: 70%;
    outline: none; }

.submission-type-label {
    margin-top: 10px; }

.submission-type-cnt-single {
    margin: 0 auto; }


.upload-container {
    min-height: 400px;
    margin-bottom: 20px;
    padding-top: 40px; }

.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: block;
	width: 100%; }

.upload-btn {
	border-radius: 4px;
	color: gray;
	background-color: white;
	height: 32px;
	width: 120px;
	margin: 0 auto;
	display: block; }

.upload-btn-wrapper input[type=file] {
	font-size: 100px;
	position: absolute;
	left: 0;
	top: 0;
	cursor: pointer;
	opacity: 0; }

.dropzone {
	width: 100%;
	height: 180px;
	border-radius: 4px;
	border: 1px dashed #ccc;
	margin-bottom: 40px; }

.upload-instruction {
	margin: 0 auto;
	width: 90px;
	margin-bottom: 10px;
	text-align: center;
	line-height: 1.5; }

.upload-icon {
	margin: 0 auto;
	display: block;
	margin-top: 50px;
	width: 64px; }

.uploaded-files {
    margin-top: 50px; }

.error-cnt {
    color: red;
    text-align: center; }

.uploaded-file-cnt {
    display: inline-block;
    padding: 6px;
    box-sizing: border-box;
    margin-right: 10px;
    box-shadow: 0 1px 2px 0 #0000003f; }

.remove {
    cursor: pointer; }


.submitting-message-container {
    text-align: center;
    margin-top: 90px;
    min-height: 40px;
    padding: 20px 30px;
    display: flex;
    align-items: center; }

.loading-msg-cnt {
	height: 25px;
	background-color: #fff;
	text-align: center;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center; }

.input-error {
    margin: 10px 0;
    color: red;
    text-align: center; }

.timer {
    display: flex;
    justify-content: end;
    font-family: "Open Sans";
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal; }

.time-up-msg {
    color: red;
    font-family: "Open Sans";
    font-weight: 600; }

.nlp-submission-instruction {
    display: flex;
    align-items: center;
    flex-direction: column; }
