@import '~styles/mixins.sass';

.journey-switcher-container {
	width: 450px;
	z-index: 25;
	position: absolute;
	right: -15px;
	top: 30px; }

.individual-journey {
	width: 100%;
	box-sizing: border-box;
	padding: 16px 12px;
 }	// height: 100px

.individual-journey-title-container {
	@include display-flex-center-mixin;
	padding-bottom: 5px; }

.individual-journey-progress-bar {
	width: 7%;
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.individual-journey-title {
	width: 60%;
	box-sizing: border-box;
	padding: 0 5px 0 10px;
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.individual-journey-perc-completion {
	width: 33%;
	display: flex;
	align-items: center;
	justify-content: flex-end; }

.individual-journey-desc {
	padding-bottom: 5px; }

.arrow-container {
	width: 100%;
	height: 15px;
	display: flex;
	box-sizing: border-box;
	padding-right: 20px;
	justify-content: flex-end;
	align-items: flex-end; }

.journeys-container {
	width: 100%;
	height: calc(100% - 15px);
	min-height: 197px;
	max-height: 475px;
	overflow-y: auto; }

.arrow-up {
	width: 0;
	height: 0; }

.about-journey-container {
	width: 100%;
	@include display-flex-center-mixin;
	padding: 15px 0;
	justify-content: space-around; }

.about-journey-button, .about-reset-button {
	@include display-flex-center-mixin;
	width: 139px;
	height: 32px; }

.individual-journey-loader {
	width: 100%;
	box-sizing: border-box;
	padding: 0px;
	height: 4px;
	position: relative;
	top: -8px; }
