@import '~styles/mixins.sass';

.product-exp-container {
    width: 100%;
    @extend %row;
    @include center-all; }

.stat-container {
    height: 65px;
    @include col-assessmentpage; }

.product-items-container {
    @include col-assessmentpage;
    height: 100%;
    max-width: 800px;
    margin-left: -10px; }

@media screen and (min-width: 1440px) {
	.product-items-container {
        max-width: 1280px;
        margin-left: 0; } }

.product-and-duration {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    flex-wrap: wrap; }

.product-content-open {
    height: 100%;
    @include assessment-inner-left-open;
    padding-left: 0;
    padding-right: 0;
    @extend %row; }

.product-content-closed {
    height: 100%;
    @include assessment-inner-left-closed;
    padding-left: 0;
    padding-right: 0;
    @extend %row;
    min-width: 659px; }

.duration-container {
    @include assessment-inner-right-open;
    @extend %row;
    justify-content: center;
    justify-items: flex-start; }

.duration-container-closed {
    @include assessment-inner-right-closed;
    @extend %row;
    justify-content: center;
    justify-items: flex-start; }

.duration-container-row {
    @include content;
    @extend %row;
    padding-left: 0;
    padding-right: 0; }

.loader-container {
    width: 659px;
    margin-top: 40px;
    margin-bottom: 20px;
    box-sizing: border-box;
    height: 225px; }

.video-loader-content {
    height: 400px;
    margin-bottom: 40px; }

.attachment-container, .page-container, .quiz-container {
    //height: calc(100vh - 400px)
    min-height: 280px;
    width: 100%;
    display: flex;
    align-items: center; }
