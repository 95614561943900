@import '~styles/mixins.sass';

.complete-container {
    width: 100%;
    height: calc(100vh - 59px); }

.error-container {
    @extend %row;
    @include center-all;
    width: 100%;
    height: calc(100% - 43px); }

.error-content {
    @include col-journeyDashboard;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; }

.error-image-container {
    width: 60%;
    height: 100%;
    box-sizing: border-box;
    padding-right: 50px;
    @include display-flex-center-mixin; }

.error-image {
    width: 100%; }

.error-message-container {
    width: 60%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-top: 80px; }

.error-heading {
    padding-bottom: 27px; }

.error-main-desc {
    padding-bottom: 24px; }

.error-sub-desc {
    padding-bottom: 50px; }

.button-container {
    width: 240px;
    height: 48px;
    @include display-flex-center-mixin;
    border-radius: 4px; }


.org-list-cnt {
    width: 493px;
    min-height: 238px; }
