@import '~styles/mixins.sass';

.learning-phase-item-feedback {
	width: 100%;
	margin-bottom: 20px; }

.completion-comp-background {
	width: 100%;
	max-width: 1280px;
	padding: 40px 0;
	box-sizing: border-box;
	position: relative;
	border-radius: 4px;
	.background-image {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0; }

	.buttons-cnt {
		width: 100%;
		margin-top: 30px;
		@include display-flex-center-mixin;
		position: relative;
		.report-btn-cnt {
			margin-right: 20px;
			width: 100%;
			max-width: 270px;
			> div {
				padding: 0 20px;
				box-sizing: border-box; } } } }

.feedback-popup-cnt {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	.feedback-content-cnt {
		width: 80%;
		max-width: 1280px;
		min-height: 200px;
		max-height: 80vh;
		margin: auto;
		margin-top: 10vh;
		overflow-y: auto;
		background-color: white;
		box-shadow: rgba(0, 0, 0, 0.19) 0px 14px 16px 0px; } }

.individual-report-btn-cnt {
	box-sizing: border-box;
	margin: auto;
	margin-top: 30px;
	position: relative;
	width: fit-content;
	> div {
		padding: 0 20px;
		box-sizing: border-box;
		height: 30px; } }

.action-btn-cnt {
	display: flex;
	justify-content: flex-start;
	align-self: center;
	> div {
		max-width: 270px;
		&:first-child {
			margin-right: 20px; } } }
