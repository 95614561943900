@import './flexboxgrid.scss';

@mixin noselect {
	-webkit-touch-callout: none;
	/* iOS Safari*/
	-webkit-user-select: none;
	-webkit-user-drag: none;
	/* Chrome/Safari/Opera*/
	-khtml-user-select: none;
	-khtml-user-drag: none;
	/* Konqueror*/
	-moz-user-select: none;
	-moz-user-drag: none;
	/* Firefox*/
	-ms-user-select: none;
	-ms-user-drag: none;
	/* Internet Explorer/Edge*/
	user-select: none;
	user-drag: none; }

@mixin display-flex-column {
    display: flex;
    flex-direction: column; }

@mixin display-flex-align-items-center {
    display: flex;
    align-items: center; }

@mixin display-flex-center-mixin {
    display: flex;
    align-items: center;
    justify-content: center; }

@mixin display-flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center; }

@mixin display-flex-space-between {
    display: flex;
    justify-content: space-between; }

@mixin display-flex-end-vertical {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end; }

@mixin display-flex-end {
    display: flex;
    justify-content: flex-end; }

@mixin padding-left-right-zero {
    padding-left: 0;
    padding-right: 0; }

@mixin center-all {
    @extend %center-sm;
    @extend %center-md;
    @extend %center-lg; }

@mixin col-landingpage {
    @extend %col-lg-8;
    @extend %col-md-10;
    @extend %col-sm-10; }

@mixin col-journeyDashboard {
    @extend %col-lg-8;
    @extend %col-md-10;
    @extend %col-sm-10; }

@mixin col-assessmentpage {
    @extend %col-lg-10;
    @extend %col-md-10;
    @extend %col-sm-10; }

@mixin col-assessmenttitle {
    @extend %col-lg-10;
    @extend %col-md-9;
    @extend %col-sm-12; }

@mixin col-measures {
    @extend %col-lg-10;
    @extend %col-md-10;
    @extend %col-sm-12; }

@mixin side-landingpage {
    @extend %col-lg-1;
    @extend %col-md-1; }

@mixin col-start {
    @extend %start-md;
    @extend %start-lg; }

@mixin col-end {
    @extend %end-md;
    @extend %end-lg; }

@mixin phase-item-left {
    @extend %col-lg-1;
    @extend %col-md-1;
    @extend %col-sm-1; }

@mixin phase-item-right {
    @extend %col-lg-7;
    @extend %col-md-9;
    @extend %col-sm-9; }

@mixin footer-content {
    @extend %col-lg-8;
    @extend %col-md-8; }

@mixin assessment-cards {
    @extend %col-lg-6;
    @extend %col-md-6; }

@mixin assessment-competencies {
    @extend %col-lg-7;
    @extend %col-md-7; }

@mixin assessment-inner-left {
    @extend %col-lg-9;
    @extend %col-md-12;
    @extend %col-sm-12; }

@mixin assessment-inner-left-open {
    @extend %col-lg-9;
    @extend %col-md-12;
    @extend %col-sm-12; }

@mixin assessment-inner-right-open {
    @extend %col-lg-3;
    @extend %col-md-3;
    @extend %col-sm-3; }

@mixin assessment-inner-left-closed {
    @extend %col-lg-8;
    @extend %col-md-8;
    @extend %col-sm-8; }

@mixin assessment-inner-right-closed {
    @extend %col-lg-4;
    @extend %col-md-4;
    @extend %col-sm-4; }

@mixin content {
    @extend %col-lg-12;
    @extend %col-md-12;
    @extend %col-sm-12; }

@mixin video {
    @extend %col-lg-10;
    @extend %col-md-10;
    @extend %col-sm-12; }

@mixin next-product {
    @extend %col-lg-2;
    @extend %col-md-2; }

@mixin f-a-q {
    @extend %col-lg-12;
    @extend %col-md-8; }

@mixin duration-item {
    @extend %col-lg-3;
    @extend %col-md-3;
    @extend %col-sm-12; }

@mixin development-landing {
    @extend %col-lg-12;
    @extend %col-md-12;
    @extend %col-sm-12; }

@mixin development-landing-each-box {
    @extend %col-lg-12;
    @extend %col-md-12;
    @extend %col-sm-12; }

@mixin development-date {
    @extend %col-lg-2;
    @extend %col-md-2;
    @extend %col-sm-2; }

@mixin development-dot {
    @extend %col-lg-1;
    @extend %col-md-1;
    @extend %col-sm-1; }

@mixin development-title-desc {
    @extend %col-lg-9;
    @extend %col-md-9;
    @extend %col-sm-9; }

@mixin assessment-item-title {
    @extend %col-lg-9;
    @extend %col-md-8;
    @extend %col-sm-8; }

@mixin assessment-item-title-button {
    @extend %col-lg-3;
    @extend %col-md-4;
    @extend %col-sm-4; }

@mixin item-feedback {
    @extend %col-lg-12;
    @extend %col-md-12;
    @extend %col-sm-12; }

@mixin item-feedback-ques {
    @extend %col-lg-5;
    @extend %col-md-7;
    @extend %col-sm-7; }

@mixin col-show-details {
    @extend %col-lg-12;
    @extend %col-md-12;
    @extend %col-sm-12; }

@mixin disabled-button {
    opacity: 0.2;
    pointer-events: none;
    cursor: default; }
