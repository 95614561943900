@import '~styles/mixins.sass';

.feedback-question-container {
    @include item-feedback;
    @include padding-left-right-zero;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    // box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.14)
    // border-radius: 4px
    // padding-bottom: 38px
 }    // overflow-y: auto

.question {
    @include item-feedback-ques;
    @include center-all; }

.button-style {
    padding: 25px 0;
    margin-bottom: 15px; }

.button-inner-style {
    box-shadow: 0 14px 16px 0 rgba(0, 0, 0, 0.19);
    min-width: 132px;
    min-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }

.button-text {
    height: 19px;
    margin: auto;
    box-sizing: border-box; }
