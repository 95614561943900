@import '~styles/mixins.sass';

.container {
  display: flex;
  width: 100%;
  @extend %row;
  @include center-all;
  text-align: left;
  margin-top: 35px; }

.container-inner {
  display: flex;
  width: 100%;
  @extend %col-lg-8;
  @extend %col-md-10;
  @extend %col-sm-10;
  padding: 0; }

.flex-direction-column {
  flex-direction: column; }

.container-inner-sim-course {
  display: flex;
  width: 100%;
  padding: 0; }

.congrats-message-section {
  display: flex;
  flex-direction: column;
  width: calc(100% - 695px);
  margin-right: 3%; }

.certificate-image-and-button-section {
  display: flex;
  flex-direction: column;
  width: 675px; }

.certificate-image-container {
  border-radius: 10px;
  border: 1px solid #C4537F;
  width: 675px;
  height: 340px;
  background: url(https://webassets.knolskape.com/misc/amul_garg/2023/11/20/46/samplecertificate.png);
  background-repeat: no-repeat;
  background-size: 101%;
  filter: blur(2px); }

.certificate-no-image-container {
  width: 675px;
  height: 170px; }

.certificate-buttons-cnt {
  display: flex; }


.congrats-message {
  margin-top: 18px;
  margin-bottom: 17px; }

.completion-info {
  margin-top: 16px; }

.button-container {
  margin-top: 20px; }

.celebration-image {
  width: 350px;
  margin: auto; }

.congrats-image-container {
  text-align: center;
  overflow: hidden;
  & > svg {
    margin-left: -10%; } }

.simonly-no-certificate-buttons-wrapper {
  & > div {
    justify-content: center;
    margin-top: 15px;
    & > div {
      margin-right: 0 !important; } } }
