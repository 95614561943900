@import '~styles/mixins.sass';
.main {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    z-index: 4; }

.overlay {
    height: 100%;
    width: 100%;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.69);
    position: fixed;
    top: 0;
    z-index: 2; }

.container {
    height: 100%;
    width: 400px;
    background: white;
    z-index: 10; }

.header {
    height: 110px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative; }

.modal-heading {
    margin-top: 5px; }

.session-list-item {
    width: 371px;
    min-height: 233px;
    border-radius: 3px;
    border: 1px solid #979EB7;
    margin: 10px 0;
    padding: 10px;
    box-sizing: border-box;
    position: relative; }

.list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box;
    height: calc(100% - 110px);
    overflow-y: auto; }

.session-date {
    margin-top: 12px; }

.session-title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden; }

.session-time {
    margin-top: 5px; }

.facilitator {
    margin-top: 12px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.active-item {
    & .description {
         -webkit-line-clamp: 2; } }

.description {
    margin-top: 5px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden; }

.close-icon {
    position: absolute;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
    height: 15px;
    width: 15px;
    right: 10px;
    top: 10px; }

.websession-active-icon-cnt {
	height: 100%;
	width: 21px;
	display: flex;
	align-items: center;
	justify-content: center; }

.active-websession-join-button-cnt {
    display: block; }

.join-session-button {
    display: flex;
    width: 127px;
    height: 34px;
    position: absolute;
    right: 12px;
    bottom: 12px;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    justify-content: space-around; }
