.session-tooltip-container {
  width: 297px;
  min-height: 136px;
  text-align: left;
  padding: 9px 17px;
  box-sizing: border-box;
  z-index: 5; }

.session-timing {
    margin-top: 3px; }

.session-facilitator {
    margin-top: 3px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden; }

.session-description {
    margin-top: 3px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden; }
