@import '~styles/mixins.sass';

.option-cnt {
	@include display-flex-start; }

.checkbox-cnt {
	min-height: 15px;
	max-height: 15px;
	min-width: 15px;
	max-width: 15px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	background-color: white;
	margin-right: 10px; }

.checkbox-cnt.selected {
	background-color: blue;
	padding: 2px;
	box-sizing: border-box; }
