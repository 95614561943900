.loader-cnt {
	 background-color: #f2f3f7;
	 position: relative; }

.header-loader-cnt {
	width: 100%;
	height: 66px;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
	background-color: white;
	display: flex;
	justify-content: space-between;
	padding: 0 30px;
	align-items: center;
	box-sizing: border-box;
	position: absolute;
	top: 0; }

.question-content-loader-cnt {
	display: flex;
	height: calc(100vh - 66px);
	padding-top: 66px;
	overflow-y: hidden; }

.question-list-loader-cnt {
	padding: 0 28px;
	margin-left: 13px;
	background-color: white;
	width: 60%; }

.question-loader {
	margin: 45px 0;
	border-bottom: 1px solid #ccc; }

.quiz-navigator-loader-cnt {
	margin-left: 46px;
	height: 350px; }
