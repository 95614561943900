@import '~styles/mixins.sass';

.sticky-header-container {
    width: 100%;
    @extend %row;
    padding: 0;
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0;
    height: 124px;
    box-sizing: border-box; }

.accept-invitation-container {
    width: 100%;
    @extend %row;
    @include center-all;
    box-sizing: border-box;
    height: 100%;
    position: relative; }

.accept-invitation-image {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.accept-invitation-content {
    @include col-landingpage;
    @extend %row;
    height: 100%;
    @include center-all;
    z-index: 2; }

.title-container {
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left; }

.button-container {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }

.button-accept {
    width: 240px;
    height: 48px;
    @include display-flex-center-mixin; }

.locked-icon {
	width: 20px;
	height: 20px;
	margin-right: 8px; }

.lock-explanation {
    text-align: left;
    margin-top: 5px; }

.journey-title-overflow-hidden {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }
