@import '~styles/mixins.sass';

.empathy-map-cnt {
	width: 100%;
	height: 100%; }

.empathy-map-header {
	@include display-flex-align-items-center;
	justify-content: space-between;
	height: 54px;
	background-image: linear-gradient(92deg, #477bc0, #4a53ae);
	padding: 0 30px;
	.header-actions {
		height: 100%;
		@include display-flex-align-items-center;
		justify-content: flex-end;
		.download-cnt, .guide-cnt {
			padding: 0 25px;
			height: 100%;
			position: relative;
			&::after {
				height: 50%;
				width: 1px;
				background-color: white;
				position: absolute;
				right: 0;
				top: 25%;
				content: ''; } }
		.download-cnt {
			display: none; }
		.innner-cnt {
			@include display-flex-center-mixin;
			flex-direction: column;
			align-items: center;
			height: 100%;
			width: 100%;
			cursor: pointer; }
		.submit-cnt {
			height: 29px;
			@include display-flex-center-mixin;
			background-color: white;
			border-radius: 17px;
			padding: 0 20px;
			margin-left: 20px;
			cursor: pointer; } }
	.download-icon-cnt {
		height: 21px;
		width: 22px; }
	.guide-icon-cnt {
		height: 22px;
		width: 22px; } }

.empathy-map-scrollable {
	width: 100%;
	padding-top: 95px;
	height: calc(100vh - 94px);
	overflow: auto;
	box-sizing: border-box; }

.empathy-map-content {
	width: 100%;
	max-width: 1120px;
	margin: auto;
	margin-bottom: 50px;

	.user-emotions-content {
		width: 100%;
		height: 610px;
		max-height: 610px;
		.feel-cnt {
			height: 203px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center; }
		.feel-cnt.multiple {
			flex-direction: row;
			justify-content: space-between;
			.hear-cnt, .see-cnt {
				display: flex;
				align-items: center;
				justify-content: center; }
			.hear-cnt {
				padding-left: 30px; }
			.see-cnt {
				padding-right: 30px; } } }

	.pain-gain-cnt {
		width: 100%;
		margin-top: 10px;
		display: flex;
		justify-content: space-between;
		.user-pain-content, .user-gain-content {
			width: 50%;
			max-width: 555px;
			height: 210px;
			display: flex;
			justify-content: center;
			align-items: center; }
		.user-pain-content {
			background-color: #0b9abf; }
		.user-gain-content {
			margin-left: 10px;
			background-color: #8870ab; } } }

.tool-item-list-cnt {
	width: 270px; }
