@import '~styles/mixins.sass';

.image-container {
    @include display-flex-center-mixin;
    width: 370px;
    height: 404px;
    margin-left: 100px; }

.image {
    width: 64px;
    height: 64px; }

.container {
    @include display-flex-center-mixin;
    box-sizing: border-box;
    width: 100%; }

.content-loader-container {
    @include display-flex-center-mixin;
    width: calc(100% - 470px);
    padding-top: 140px;
    height: 580px;
    box-sizing: border-box; }
