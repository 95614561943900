@import '~styles/mixins.sass';

.info-filters-container {
    width: 100%;
    // @extend %row
    // @include center-all
    min-height: 75px;
    padding-bottom: 20px; }

.info-filters-content {
    // @extend %col-md-10
    @include display-flex-column; }

.info-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding-left: 30px;
    text-align: left; }

.title-content {
    width: 80%;
    padding-bottom: 15.4px; }

.subtitle-content {
    width: 100%;
    display: flex;
    padding-left: 30px;
    text-align: left;
    box-sizing: border-box; }

.filters-container {
    @include display-flex-end;
    width: 20%; }

.filters-content {
    display: flex;
    height: 20px;
    border-radius: 4px; }

.filters-text {
    @include display-flex-center-mixin;
    height: 100%;
    min-width: 42px;
    padding: 0 5px;
    cursor: pointer;
    border-right: solid 1px rgba(151, 158, 183, 1);
    &:hover {
        background-color: rgba(151, 158, 183, 0.2); } }

.border-filter {
    border-right: solid 1px #979eb7; }

.logo-container {
    display: flex;
    padding-right: 6px; }
