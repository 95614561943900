@import '~styles/mixins.sass';

.journey-introduction-popup-cnt {
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 101;
	.popup-content {
		height: 100%;
		width: 100%;
		overflow: auto;
		position: relative;
		box-sizing: border-box; }
	.popup-content-cnt {
		min-width: 1050px;
		max-width: 1050px;
		height: 85vh;
		margin: auto;
		background-color: white;
		position: relative;
		z-index: 10;
		margin-top: 7vh;
		border-radius: 4px;
		.journey-details-cnt {
			width: 100%;
			min-height: 331px;
			height: auto;
			z-index: 10;
			padding-right: 445px;
			box-sizing: border-box;
			position: relative;
			.journey-details {
				width: 100%;
				position: relative;
				z-index: 100000;
				padding: 55px 0 10px 45px;
				box-sizing: border-box; }
			.content-bg {
				position: absolute;
				width: 100%;
				height: 100%;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center top;
				top: 0;
				left: 0; }
			.journey-description {
				width: 100%;
				min-height: 100px; }
			.journey-syllabus-cnt {
				display: flex;
				margin-top: 20px;
				.individual-details-cnt {
					border-right: 1px solid #D8D8D8;
					min-height: 52px;
					padding: 0 35px;
					box-sizing: border-box;
					&:first-child {
						padding-left: unset; }
					&:last-child {
						border-right: unset; }
					.detail-value-cnt {
						width: 100%;
						height: 32px;
						@include display-flex-align-items-center;
						.icon-cnt {
							height: 20px;
							width: 20px;
							margin-right: 10px; } } } }
			.read-more-btn {
				@include display-flex-center-mixin;
				visibility: hidden; } }
		.journey-overview-cnt {
			width: 100%;
			padding: 35px 445px 0 35px;
			box-sizing: border-box;
			margin-bottom: 20px;
			.exp-content-title {
				text-align: left;
				width: 100%;
				padding-bottom: 40px; }
			.exp-content-desc {
				text-align: left;
				width: 100%; } }
		.request-access-with-additional-details-cnt {
			position: absolute;
			top: 18px;
			min-height: calc(100% - 36px);
			right: 26px;
			min-width: 393px;
			max-width: 393px;
			z-index: 50;
			padding: 10px;
			box-sizing: border-box;
			border: 1px solid #DDDDDD;
			border-radius: 2px;
			background-color: white;
			margin-bottom: 18px;
			.video-preview-cnt {
				width: 100%;
				height: 213px;
				@include display-flex-center-mixin;
				cursor: pointer;
				border: 1px solid #333333;
				border-radius: 4px;
				background-color: rgba(0, 0, 0, 0.8);
				&:hover {
					background-color: rgba(0, 0, 0, 0.6); }
				.play-icon-cnt {
					height: 80px;
					width: 80px; } }
			.journey-thumbnail-cnt {
				width: 100%;
				height: 213px;
				@include display-flex-center-mixin;
				img {
					height: 100%;
					width: 100%;
					border-radius: 4px; } }
			.request-access-btn-cnt {
				margin-top: 10px;
				width: 100%;
				> div {
					height: 48px;
					max-width: unset; }
				.request-status {
					width: 100%;
					height: auto;
					text-transform: unset;
					.status {
						width: 100%;
						height: 100%;
						border-radius: 4px;
						height: 48px;
						@include display-flex-center-mixin;
						.status-icon-cnt {
							height: 20px;
							width: 20px;
							margin-right: 10px; } } }
				.request-again-btn-cnt {
					width: 100%;
					@include display-flex-center-mixin; } }
			.prerequisite-cnt, .skills-cnt {
				width: 100%;
				margin: 25px 0;
				padding-left: 5px;
				box-sizing: border-box;
				.each-details {
					@include display-flex-align-items-center;
					margin-top: 10px;
					.tick-icon-cnt {
						height: 20px;
						width: 20px;
						margin-right: 16px; } } } }
		.popup-close-icon {
			position: absolute;
			top: -18px;
			right: -18px;
			height: 40px;
			width: 40px;
			z-index: 100; } }

	.popup-overlay-bg {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.4);
		border: 1px solid #979797; } }

@media only screen and (max-width: 480px) {
	.journey-introduction-popup-cnt {
		.popup-content-cnt {
			min-width: 330px;
			max-width: 330px;
			.journey-details-cnt {
				padding-right: unset;
				.journey-details {
					padding: 35px 0 10px 15px; } }
			.request-access-with-additional-details-cnt {
				position: static;
				min-width: 100%;
				max-width: 100%; }
			.journey-overview-cnt {
				padding: 35px 15px 0 20px; }
			.popup-close-icon {
				height: 30px;
				width: 30px;
				right: -15px;
				top: -15px; } } } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
	.journey-introduction-popup-cnt {
		.popup-content-cnt {
			min-width: 550px;
			max-width: 550px;
			.journey-details-cnt {
				padding-right: unset;
				.journey-details {
					padding: 35px 10px 10px 25px; } }
			.journey-overview-cnt {
				padding: 35px 15px 0 25px; }
			.request-access-with-additional-details-cnt {
				position: static;
				margin: 0 auto; }
			.popup-close-icon {
				height: 30px;
				width: 30px;
				right: -15px;
				top: -15px; } } } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
	.journey-introduction-popup-cnt {
		.popup-content-cnt {
			min-width: 630px;
			max-width: 630px;
			.journey-details-cnt {
				padding-right: unset;
				.journey-details {
					padding: 35px 10px 10px 25px; } }
			.journey-overview-cnt {
				padding: 35px 15px 0 25px; }
			.request-access-with-additional-details-cnt {
				position: static;
				margin: 0 auto; }
			.popup-close-icon {
				height: 30px;
				width: 30px;
				right: -15px;
				top: -15px; } } } }
