.topic-capsule {
	height: 28px;
	border-radius: 14px;
	border: solid 1px #bfc3d2;
	padding: 0 10px;
	margin-right: 6px;
	margin-bottom: 10px; }

.topics-row {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-top: 18px;
	margin-bottom: 50px; }
