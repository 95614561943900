@import '~styles/mixins.sass';

.container {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	@include display-flex-center-mixin; }

.tabs-container {
	overflow: hidden;
	box-sizing: border-box;
	width: calc(100% - 40px);
	height: 100%;
	@include display-flex-start; }

.tab-container {
	cursor: pointer;
	box-sizing: border-box;
	height: 56px;
	min-width: 180px;
	max-width: 180px;
	overflow: hidden;
	padding: 0 3px;
	margin: 0 10px;
	@include display-flex-center-mixin;
	&:first-child {
		margin-left: unset !important; } }

.tab-content {
  display: flex;
  align-items: center; }

.tab-label {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  text-align: left;
  overflow: hidden;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical; }

.arrow-container {
	width: 40px;
	height: 56px;
	margin-top: -15px;
	border-radius: 4px;
	box-sizing: border-box;
	cursor: pointer;
	@include display-flex-center-mixin;
	&:hover svg path {
		fill: #ffffff !important; } }

.arrow-container-left {
	margin-right: 10px; }

.arrow-container-right {
	margin-left: 5px;
	margin-right: -10px; }

.arrow-content {
	width: 14px;
	height: 20px; }

.unselected-tabs {
	//border: none !important
	opacity: 0.5;
	font-weight: normal; }

.phase-status-icon-cnt {
	display: inline-block;
	width: 15px;
	height: 15px;
	margin-right: 7px;
	& img {
		width: 100%;
		height: 100%;
		margin-bottom: -2px; } }
