@import '~styles/mixins.sass';

.tooltip-header {
	display: flex;
	border-bottom: 0.5px solid rgba(255, 255, 255, 0.2);
	padding: 5px;
	font-size: 12px;
	opacity: 0.8;
	width: 90%;
	justify-items: flex-start; }

.tooltip-content {
	padding: 5px;
	display: flex;
	width: 100%;
	flex-direction: column; }

.tooltip-content-item {
	width: 100%;
	padding: 5px;
	display: flex;
	align-items: center; }

.tooltip-legend {
	border-radius: 50%;
	width: 7px;
	height: 7px;
	margin-right: 8px; }
