.question-container {
    display: flex;
    flex-direction: column;
    width: 100%; }

.question-text {
    display: flex;
    padding-bottom: 10px;
    text-align: left; }

.question-desc {
    display: flex;
    padding-bottom: 10px; }
