@import '~styles/mixins.sass';

.design-experience-journey-cnt {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white; }

.design-experience-journey-content-cnt {
	width: 100%;
	max-height: calc(100vh - 94px);
	overflow: auto; }

.design-experience-journey-content {
	height: 100%;
	width: 100%;
	max-width: 1280px;
	margin: auto;
	.fill-card-header {
		height: 55px;
		background-color: #f5f6f9;
		@include display-flex-center-mixin;
		margin: 30px 0 40px 0; }
	.cards-outer-cnt {
		width: 100%;
		max-width: 1120px;
		margin: auto;
		margin-bottom: 50px;
		.cards-cnt-header {
			width: 100%;
			height: 50px;
			@include display-flex-center-mixin; } } }

.cards-header-cnt {
	margin-left: 125px;
	@include display-flex-space-between;
	align-items: center;
	padding-right: 30px;
	box-sizing: border-box;
	.individual-cards-header-cnt {
		max-width: 270px;
		min-width: 270px;
		position: relative;
		.header-text {
			width: 100%;
			@include display-flex-center-mixin; }
		.connector {
			width: 0;
			border-left: 1px dashed #979797;
			position: absolute;
			top: 24px;
			left: 134.5px;
			height: 1350px; } } }

.role-based-cards-cnt {
	width: 100%;
	margin-top: 35px;
	.value-proposition-cnt {
		width: 50px;
		@include display-flex-center-mixin;
		text-align: center;
		.value-proposition-text {
			transform: rotate(270deg);
			min-width: 224px; } }
	.individual-role-based-cards {
		@include display-flex-center-mixin;
		padding: 30px 30px 30px 0;
		box-sizing: border-box;
		border: dashed 1px #4a5eb3;
		&:not(:first-child) {
			border-top: none; }
		.cards-cnt {
			width: 100%;
			@include display-flex-space-between;
			padding-left: 50px;
			box-sizing: border-box;
			.individual-role-based-card-cnt {
				max-width: 270px;
				min-width: 270px; } }

		.role-text-cnt {
			@include display-flex-center-mixin;
			max-width: 75px;
			text-align: center;
			.role-text {
				transform: rotate(270deg);
				min-width: 224px; } } } }
