@import '~styles/mixins.sass';

.filter-container {
	height: 100%;
	margin-right: 16px; }

.filter-info {
	margin-bottom: 16px;
	opacity: 0.5;
	color: #979eb7;
	font-size: 14px; }

.filter-drop-down {
	opacity: 0.5;
	&:hover {
		opacity: 1; } }
