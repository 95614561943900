@import '~styles/mixins.sass';

.answer-options-cnt {
	@extend %row;
	flex-wrap: wrap;
	padding: unset; }

.question-text > p {
	margin-top: 0; }

// .option-cnt
// 	@extend %col-xs-6
// 	padding: unset
// 	height: 56px
// 	margin: 8px 0
// 	border-radius: 2px
// 	display: flex
// 	align-items: center

.option-cnt {
	@extend %col-xs-6;
	padding: unset;
	min-height: 56px;
	margin: 8px 0;
	display: flex;
	align-items: center; }

.option-text, .selected-option-text {
	border-radius: 2px;
	padding: 4px 24px;
	min-height: 56px;
	width: 100%;
	cursor: pointer;
	display: flex;
	align-items: center;
	height: 100%; }

.option-text .text, .selected-option-text .text {
	width: 100%; }

.option-text:hover {
	background-color: rgba(213, 217, 231, 0.2); }

.option-cnt:has( input:checked) {
	background-color: #2c4bb7; }

.option-cnt:nth-child(odd) {
	padding-right: 16px; }

.option-cnt:nth-child(even) {
	padding-left: 16px; }

.checkmark {
	height: 16px;
	width: 16px;
	border-radius: 2px;
	background: white;
	margin-right: 16px;
	padding: 0 2px;
	box-sizing: border-box; }
