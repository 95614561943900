@import '~styles/mixins.sass';

.module-item-cards-row {
    margin-top: 48px;
    @include col-journeyDashboard;
    @include display-flex-center-mixin; }

.module-item-cards-row .module-item-cards-cnt {
    position: relative;
    width: calc(100% - 105px);
    margin-left: 105px; }

.items-connector {
    position: absolute;
    width: 2px;
    background-color: #bfc3d2;
    top: 88px;
    left: -67.5px; }

.feedback-link-cnt {
    width: 100%;
    position: relative;
    height: 40px;
    z-index: 10;
    .feedback-text {
        height: 100%;
        background-color: white;
        max-width: 320px;
        border: solid 1px rgba(151, 158, 183, 0.25);
        border-radius: 28px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        cursor: pointer;
        &:hover {
            box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.11), 0 4px 16px 0 rgba(0, 0, 0, 0.06); } }
    .feedback-dot {
        height: 22px;
        width: 22px;
        border-radius: 100%;
        background-color: rgb(151, 158, 183);
        position: absolute;
        top: 11px;
        left: -78px;
        cursor: pointer; }
    .feedback-icon-cnt {
        height: 15px;
        width: 16px;
        margin-right: 10px; } }
