@import '~styles/mixins.sass';

.measures-loader-container {
    width: 100%;
    box-sizing: border-box;
    padding-top: 0;
    padding-bottom: 0;
    @extend %row;
    @include center-all; }

.measures-loader-content {
    @include col-measures; }

.score-container {
    @include display-flex-start;
    height: 250px;
    padding-top: 40px;
    padding-left: 2px; }

.score-card-container {
    width: 302px;
    height: 204px;
    padding-right: 48px; }

.metrics-box-container {
    @include display-flex-center-mixin;
    width: 100%;
    height: 504px;
    margin: 70px 0; }

.metrics-container {
    @include display-flex-start;
    width: 100%;
    margin-top: 70px; }

.main-section-content {
    padding-top: 57px;
    padding-bottom: 44px; }

.summary-content {
    width: 100%;
 }    // height: 100%

.score-tabs-container {
    @include display-flex-start;
    width: 100%;
    padding-top: 100px; }

.summary-heading {
    @include display-flex-start;
    width: 100%; }
