@import '~styles/mixins.sass';

.quiz-question-outer-cnt {
	position: relative; }

.quiz-questions-data {
	@extend %row;
	width: 100%;
	height: 100vh;
	padding-top: 64px; }

.question-list-cnt {
	@extend %col-xs-9;
	border: solid 1px #daddea;
	padding-left: 28px;
	padding-right: 28px;
	height: calc(100vh - 64px);
	overflow-y: auto; }

.question-navigator-cnt {
	@extend %col-xs-3;
	padding-left: 49px; }


.mcq-cnt {
	margin-top: 11px; }

.answer-options-cnt {
	@extend %row;
	flex-wrap: wrap;
	padding: unset; }

.question-text > p {
	margin-top: 0; }

.option-cnt {
	@extend %col-xs-6;
	padding: unset;
	min-height: 56px;
	margin: 8px 0;
	display: flex;
	align-items: center; }

.option-text, .selected-option-text {
	border-radius: 2px;
	padding: 4px 24px;
	min-height: 56px;
	width: 100%;
	display: flex;
	align-items: center;
	height: 100%; }

.option-cnt:nth-child(odd) {
	padding-right: 16px; }

.option-cnt:nth-child(even) {
	padding-left: 16px; }

.question-data-cnt {
	padding: 68px 29px 79px 49px;
	border-bottom: solid 1px #d5d8e2; }

.question-number-row {
	display: flex;
	justify-content: space-between; }

.answer-selected-by-user {
	background-color: #2c4bb7 !important;
	color: white !important; }

.correct-answer-not-selected-by-user {
	border: 1px dashed #6cbc14 !important;
	background-color: #f1f9e8 !important;
	color: #6cbc14 !important;
	box-sizing: border-box !important; }

.correct-answer-selected-by-user {
	background-color: #6cbc14 !important;
	color: white !important;
	box-sizing: border-box; }

.wrong-answer-selected-by-user {
	background-color: #d0041d !important;
	color: white !important;
	box-sizing: border-box; }

.checkmark {
	height: 16px;
	width: 16px;
	border-radius: 2px;
	background: white;
	margin-right: 16px;
	padding: 0 2px;
	box-sizing: border-box;
	min-height: 16px;
	min-width: 16px; }

.checkmark div {
	cursor: unset !important; }

.essay_answer_review_cnt {
	width: 100%;
	overflow-wrap: break-word; }
