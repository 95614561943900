.title-cnt {
	width: 100%; }

.title-cnt .sub-title {
	margin-top: 8px;
	margin-bottom: 15px; }

.points-cnt {
	border-bottom: 1px solid #d44980;
	padding: 0 15px 10px 15px;
	height: fit-content;
	position: relative; }

.points-cnt.without-border {
	border: unset; }

.points-cnt div {
	font-family: Open sans !important; }

.points-cnt .points {
	font-size: 34px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.6;
	letter-spacing: normal;
	text-align: center;
	color: #d44980; }

.points-cnt .text {
	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.67;
	letter-spacing: normal;
	text-align: center;
	color: #d44980;
	text-transform: uppercase; }


.tooltip-container {
	// width: 350px
	min-width: 110px;
	position: absolute;
	top: 100%;
	left: -15px;
	z-index: 20; }

.tooltip-arrow {
	width: 0;
	height: 0;
	margin: auto; }

.tooltip-content {
	box-sizing: border-box;
	border-radius: 4px;
	padding: 7px;
	width: calc(100% - 7px);
	display: flex;
	justify-content: flex-start;
	align-items: center;
	text-align: left;
	margin: auto; }
