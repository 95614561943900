@import '~styles/mixins.sass';

.duration-container {
    @include content;
    @extend %row;
    height: 285px;
    width: 100%;
    display: flex;
    flex-direction: column; }
.duration-container-row {
    @include content;
    @extend %row;
    display: flex;
    justify-content: space-around;
    padding-left: 0;
    padding-right: 0;
    align-items: center;
    margin-bottom: 30px; }
.item-column {
    width: 100%;
    padding-left: 0;
    padding-right: 0; }
.item-row {
    @include duration-item;
    padding-left: 0;
    padding-right: 0; }
