.feedback-box-content {
    width: 100%;
    min-height: 195px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.14);
    overflow: hidden;
    border-radius: 4px; }

.main-feedback-box {
    width: 100%;
    height: 195px;
    position: relative; }

.main-feedback-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.main-feedback-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }

.main-feedback-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center; }
