@import '~styles/mixins.sass';

.main-text {
	position: relative; }

.tooltip {
	position: absolute;
	min-width: 191px;
	max-width: 291px;
	width: 100%;
	height: auto;
	top: 100%;
	left: 20%;
	z-index: 35; }

.tooltip-arrow-container {
	width: 100%;
	height: 10px;
	@include display-flex-center-mixin; }

.tooltip-content {
	padding: 8px 12px;
	box-sizing: border-box;
	border-radius: 4px;
	background-color: black; }
