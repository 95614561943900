.quiz-score-analytics-cnt {
	width: 100%;
	border-radius: 4px;
	min-height: 236px;
	margin-top: 20px;
	box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.14);
	border-radius: 4px; }

.quiz-score-cnt {
	height: 236px;
	width: 100%;
	display: flex;
	align-content: center;
	text-align: center;
	padding: 28px 20px;
	box-sizing: border-box;
	padding-right: unset; }

.score-cnt-with-anim {
	margin: auto; }

.incomplete-submission-score-cnt {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	box-sizing: border-box;
	padding: 25px 20px 25px 0; }

.quiz-best-score-cnt {
	width: 35.5%;
	display: flex;
	flex-direction: column;
	justify-content: center; }

.quiz-best-score-cnt > div {
	margin: 0 auto; }

.best-score-label {
	padding: 0 10px;
	border-radius: 16px;
	width: 100px; }

.last-attempts-score-cnt {
	display: flex;
	width: 62%;
	margin: 0 auto;
	justify-content: space-between;
	flex-direction: column; }

.last-attempts-scores {
	display: flex;
	justify-content: center;
	align-items: center; }

.last-attempts-scores > div:nth-child(2) {
	margin: 0 21px; }

.quiz-review-cnt {
	min-height: 100px;
	padding: 32px 0;
	text-align: center; }

.quiz-analytics-charts-cnt {
	display: flex;
	margin-top: 15px; }

.attempt-analytics-cnt {
	display: flex; }

.right-wrong-pie-chart-cnt {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center; }

.topics-bar-chart-cnt {
	min-width: 50%;
	width: 50%;
	display: flex;
	align-items: center; }

@media only screen and (max-width: 1500px) {
	.quiz-analytics-charts-cnt.sidebar-open {
		display: block; }

	.topics-bar-chart-cnt.sidebar-open {
		width: 100%;
		justify-content: center; } }

@media only screen and (max-width: 480px) {
	.quiz-review-cnt {
		display: none; } }
