.container {
	padding: 8px 0px 0px 20px;
	box-sizing: border-box;
	min-height: 45px;
	position: relative;
	cursor: pointer; }

.header-name {
	width: 95%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis; }

.progress-bar-outer {
	width: 194px;
	height: 8px;
	border-radius: 8px;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.progress-bar-inner {
  border-radius: 8px;
  height: 100%;
  min-width: 8px; }

.progress-container {
	display: flex;
	margin-bottom: 8px;
	align-items: center; }

.completed-count {
	width: 133px;
	margin-left: 5px; }

.course-progress-bar {
	height: 2px;
	position: absolute;
	left: 0;
	bottom: 0; }

.lock-icon-cnt {
  height: 13px;
  width: 11px;
  margin-right: 6px; }

.date {
    padding-top: 5px;
    width: 286px;
    word-wrap: break-word;
    white-space: normal; }
