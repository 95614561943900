@import '~styles/mixins.sass';

.toast-overlay-container {
    position: absolute;
    top: 81px;
    right: 58px;
    z-index: 101;
    overflow: hidden;
    @include noselect;
    overflow: hidden;
    // height: calc(100% - 100px)
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start; }

@media only screen and (max-width: 480px) {
    .toast-overlay-container {
        right: 0px; } }
