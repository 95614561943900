@import '~styles/mixins.sass';

.digital-blur-cnt {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white; }

.roles-cnt {
	@include display-flex-center-mixin;
	.individual-role-cnt {
		@include display-flex-center-mixin;
		margin-left: 20px;
		height: 40px;
		border-radius: 20px;
		padding: 0 20px;
		box-sizing: border-box;
		width: fit-content;
		box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.2);
		cursor: pointer;
		.info-icon-cnt {
			height: 20px;
			width: 20px;
			margin-right: 10px;
			position: relative;
			&:hover {
				.capsule-info-card {
					display: block; } } }
		.radio-button-cnt {
			width: 14px;
			height: 14px;
			margin-left: 10px;
			border-radius: 100%;
			box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.2); }
		.checkmark-icon-cnt {
			width: 14px;
			height: 11px;
			margin-left: 10px; } }
	.individual-role-cnt.selected {
		background-color: #46ba56; } }

.capsule-info-card {
	width: 230px;
	border-radius: 6px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	background-color: #ffffff;
	padding: 20px;
	box-sizing: border-box;
	display: none;
	position: absolute;
	top: 30px;
	left: -105px;
	z-index: 10000; }

.digital-blur-content-cnt {
	width: 100%;
	max-height: calc(100vh - 94px);
	overflow: auto;
	height: 100%;
	.digital-blur-content {
		max-width: 1280px;
		margin: auto;
		width: 100%;
		&:last-child {
			margin-bottom: 100px; } }
	.step-header-text {
		height: 55px;
		background-color: #f5f6f9;
		@include display-flex-center-mixin;
		margin: 30px 0 20px 0; }
	.top-row-content {
		width: 100%;
		padding: 0 80px;
		box-sizing: border-box;
		margin-top: 40px;
		.arrow-connector-cnt {
			margin-left: 56px;
			@include display-flex-center-mixin;
			padding: 0px 134px;
			box-sizing: border-box; }
		.actor-row-content-cnt {
			display: flex;
			justify-content: space-between;
			.row-horizontal-header-cnt {
				max-width: 56px;
				min-width: 56px;
				display: flex;
				align-items: center;
				justify-content: space-around;
				flex-direction: column;
				text-align: center;
				padding-top: 70px;
				box-sizing: border-box;
				.header-text {
					transform: rotate(270deg);
					min-width: 200px;
					max-width: 200px; } }
			.actor-row-content {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.individual-actor-cards-cnt {
					width: 312px;
					padding: 20px;
					box-sizing: border-box;
					border: dashed 1px #4a5eb3;
					.header {
						height: 50px;
						@include display-flex-center-mixin; }
					.actor-row-cards-cnt {
						width: 100%;
						.actor-row-card {
							max-width: 270px;
							min-width: 270px;
							&:last-child {
								margin-top: 30px; } } } } } } }
	.blur-cards-outer-cnt {
		width: 100%;
		padding: 0 80px;
		box-sizing: border-box;
		margin-top: 60px;
		.blur-banner-cnt, .blur-strategies-header-cnt {
			width: calc(100% - 130px);
			margin-left: 130px;
			@include display-flex-space-between;
			background-color: #161616;
			border-radius: 12px;
			padding: 20px 0;
			box-sizing: border-box;
			height: 126px;
			text-align: center; }
		.blur-strategies-header-cnt {
			background-color: unset; }
		.blur-banner-cnt .blur-meaning-cnt, .blur-strategies-header-cnt .individual-strategy-header {
			width: 225px;
			min-width: 225px;
			max-width: 225px;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			height: 100%; }
		.blur-strategies-header-cnt .individual-strategy-header {
			justify-content: center;
			position: relative;
			.connector {
				position: absolute;
				top: 75px;
				left: 111.5px;
				width: 0;
				border-left: 1px dashed #979797;
				height: 2450px; } }
		.blur-cards-inner-cnt {
			width: 100%;
			@include display-flex-space-between;
			margin-bottom: 100px;
			.cards-cnt-header {
				width: 50px;
				max-width: 50px;
				@include display-flex-center-mixin;
				text-align: center;
				background-color: #4a5eb3;
				.text {
					transform: rotate(270deg);
					min-width: 150px;
					max-width: 150px; } }
			.role-cards-cnt-header {
				width: 80px;
				max-width: 80px;
				@include display-flex-center-mixin;
				text-align: center;
				.text {
					transform: rotate(270deg);
					min-width: 150px;
					max-width: 150px; } }
			.role-cards-cnt {
				width: 100%; }
			.blur-cards-cnt {
				max-width: 225px;
				min-width: 225px; }
			.actor-wise-cards-cnt {
				width: 100%;
				@include display-flex-space-between;
				margin-bottom: 30px;
				&:last-child {
					margin: unset; }
				.cards {
					width: 100%;
					@include display-flex-space-between; } } } } }

.bottom-cards-holder-cnt {
	@include display-flex-center-mixin;
	.bottom-cards-holder {
		padding: 20px;
		box-sizing: border-box;
		border: dashed 1px #4a5eb3;
		width: 312px;
		&:not(:first-child) {
			margin-left: 40px; }
		.bottom-card-cnt {
			max-width: 270px;
			min-width: 270px;
			margin-top: 30px; } } }
