@import '~styles/mixins.sass';

.needle-graph-container {
    width: 100%;
    @include display-flex-space-between;
    padding-bottom: 140px; }

.needle-graph {
    width: 70%; }

.needle-graph-content {
    width: 30%;
    @include display-flex-end-vertical; }

.needle-content {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    padding: 12px;
    height: 376px;
    background-color: rgba(0, 0, 0, 0.1); }

.needle-list {
    width: 100%;
    display: flex;
    padding-bottom: 5px; }

.needle-disc {
    width: 5%;
    padding-top: 5px; }

.needle-list-content {
    width: 95%;
    padding-left: 8px;
    text-align: left;
    box-sizing: border-box; }
