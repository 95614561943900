.video-player-loader-container {
    width: 99.7%;
    height: 99.7%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }

.camera-img {
    width: 70px;
    height: 61px; }

.loading-text {
    padding: 20px; }
