.siderbar-loader-loader-cnt {
	width: 339px;
	text-align: center;
	border-right: 1px solid rgb(213, 217, 231); }

.course-name-loader {
	height: 62px;
	width: 100%;
	border-bottom: 1px solid #d5d9e7; }

.module-loader-cnt {
	width: 100%;
	background-color: #f6f7f9; }

.module-content-header-cnt {
	display: flex;
	align-items: center;
	height: 40px;
	width: 100%;
	border-bottom: solid 1px #d5d9e7; }

.partial-border-for-module {
	position: absolute;
	width: 260px;
	height: 1px;
	bottom: 0;
	left: 50px;
	background-color: #eaecf3; }
