@import '~styles/mixins.sass';

.digital-tool-header {
	@include display-flex-align-items-center;
	justify-content: space-between;
	height: 54px;
	background-image: linear-gradient(92deg, #477bc0, #4a53ae);
	padding: 0 30px;
	box-sizing: border-box;
	width: 100%;
	.header-actions {
		height: 100%;
		@include display-flex-align-items-center;
		justify-content: flex-end;
		.download-cnt, .guide-cnt {
			padding: 0 25px;
			height: 100%;
			position: relative;
			&::after {
				height: 50%;
				width: 1px;
				background-color: white;
				position: absolute;
				right: 0;
				top: 25%;
				content: ''; } }
		.download-cnt {
			display: none; }
		.innner-cnt {
			@include display-flex-center-mixin;
			flex-direction: column;
			align-items: center;
			height: 100%;
			width: 100%;
			cursor: pointer; }
		.submit-cnt {
			height: 29px;
			@include display-flex-center-mixin;
			background-color: white;
			border-radius: 17px;
			padding: 0 20px;
			margin-left: 20px;
			cursor: pointer; } }
	.download-icon-cnt {
		height: 21px;
		width: 22px; }
	.guide-icon-cnt {
		height: 22px;
		width: 22px; } }
