@import '~styles/mixins.sass';

.content-cnt {
	padding: 0;
	min-height: calc(100vh - 310px);
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap; }

.content-inner-cnt {
	@extend %col-xs-12;
	padding: 0; }

.row {
	@extend %row;
	padding: 0; }

.column {
	@extend %col-xs;
	padding: unset; }

// .session-desc-cnt //, .resources-cnt, .facilitator-cnt
// 	@extend %col-xs-8
// 	padding: 0

.session-desc-cnt, .other-session-detail-cnt {
	width: 70%;
	padding-right: 10px;
	box-sizing: border-box; }

.session-desc-cnt.sidebar-open {
	width: 100%; }

.session-desc-inner-cnt {
	padding-right: 24px;
	box-sizing: border-box; }

.session-details-cnt {
	width: 30%;
	box-sizing: border-box;
	max-width: 320px;
	margin-top: 64px;
	border-left: solid 1px #d5d8e2;
	border-right: solid 1px #d5d8e2;
	padding: 0 13px;
	box-sizing: border-box;
	// @extend %col-xs-4
	// align-items: stretch
	// height: 100%
 }	// padding: 0

.session-details-cnt.sidebar-open {
	min-width: 320px;
	margin-top: unset;
	margin: 50px 0; }

.session-details-inner-cnt {
	margin-top: 64px;
	border-left: solid 1px #d5d8e2;
	border-right: solid 1px #d5d8e2;
	padding: 0 13px;
	box-sizing: border-box;
	height: 100%; }

.date-time-cnt, .day-duration-cnt {
	@extend %row;
	padding: 0; }

.date-time-cnt, .address-cnt {
	margin-top: 13px; }

.column.time, .duration.column {
	text-align: right; }

.button {
	height: 48px; }

.button > div {
	margin: 0 auto;
	min-width: unset !important;
	box-sizing: border-box; }

.add-to-calendar-cnt {
	min-height: 75px; }

.address-cnt {
	box-sizing: border-box;
	display: flex;
 }	// align-items: center

.location-icon-cnt {
	height: 15px;
	width: 15px;
	min-width: 15px; }

.location-text {
	padding-left: 10px; }

.resources-cnt, .facilitator-cnt {
	margin-top: 65px; }

.resources-cnt-header, .facilitator-cnt-header {
	margin-bottom: 16px; }

.resources-list {
	padding: 24px;
	min-height: 88px;
	max-width: 665px; }

.each-resource-cnt {
	height: 68px;
	width: 100%;
	border-radius: 4px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
	padding: 10px;
	text-decoration: none;
	box-sizing:  border-box;
	margin-top: 15px;
	background-color: white;
	display: flex;
	align-items: center; }

.each-resource-cnt:first-child {
	margin-top: unset; }

.file-icon-cnt {
	height: 40px;
	max-width: 40px;
	min-width: 40px;
	border-radius: 100%;
	overflow: hidden; }

.resource-name {
	padding-left: 15px;
	box-sizing:  border-box;
	width: 100%; }

.download-icon {
	height: 15px;
	width: 15px; }

.facilitator-details {
	min-height: 30px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 0 4px 0 rgba(0, 0, 0, 0.16);
	border-radius: 5px;
	margin-bottom: 15px; }

.facilitator-profile, .facilitator-bio {
	@extend %row;
	padding: unset; }

.facilitator-profile {
	padding: 16px 24px;
	box-sizing: border-box; }

.facilitator-avatar {
	@extend %col-xs;
	min-width: 80px;
	max-width: 100px;
	padding: unset; }

.facilitator-avatar img {
	border-radius: 100%;
	height: 80px; }

.facilitator-bio {
	padding: 16px 24px;
	box-sizing: border-box; }

.row.rating {
	height: 22px;
	max-width: 160px;
	flex-wrap: unset; }
