@import '~styles/mixins.sass';

.quiz-question-outer-cnt {
	position: relative; }

.quiz-questions-data {
	@extend %row;
	width: 100%;
	height: 100vh;
	padding-top: 64px; }

.question-list-cnt {
	@extend %col-xs-9;
	border: solid 1px #daddea;
	padding-left: 28px;
	padding-right: 28px;
	height: calc(100vh - 64px);
	overflow-y: auto; }

.question-navigator-cnt {
	@extend %col-xs-3;
	padding-left: 49px; }

.question-label-score {
    @include display-flex-space-between; }

.questions-navigator-header {
	border-bottom: solid 1px #d5d8e2;
	color: #5b5c61;
	padding-bottom: 3px;
	text-transform: uppercase; }

.questions-navigator-cnt {
	@extend %col-xs-3;
	padding-left: 49px; }

@media only screen and (max-width: 480px) {
	.question-list-cnt {
		padding-left: 10px;
		padding-right: 10px; } }
