@import '~styles/mixins.sass';

.active-web-session-banner-container {
    width: 100%;
    @extend %row;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 20px; }

.active-web-session-banner-content {
    @include col-journeyDashboard;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    z-index: 3;
    padding: 10px 32px 20px 32px;
    box-sizing: border-box; }

.right-side-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 10px;
    box-sizing: border-box; }

.join-button-container {
    width: 232px;
    height: 46px; }

.web-session-label {
    margin-top: 15px;
    display: flex; }

.session-title {
    margin-top: 2px; }

.other-upcoming-sessions {
    cursor: pointer; }

.info-icon-cnt {
  height: 10px;
  width: 10px;
  margin-left: 4px;
  margin-top: 7px;
  cursor: pointer; }

.session-tooltip-outer {
	position: absolute;
	z-index: 4; }
