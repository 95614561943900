.button-style {
    min-width: 178px;
    max-width: 300px;
    display: flex;
    min-height: 48px;
    height: 48px;
    cursor: pointer;
    padding: 0 15px;
    border-radius: 5.8px;
    &:hover path {
        fill: white;
        stroke: white; } }

.button-text {
    height: 19px;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center; }
