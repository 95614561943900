@import '~styles/mixins.sass';

.building-agility-as-strategy-cnt {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: white; }

.building-agility-as-strategy-content-cnt {
	width: 100%;
	max-height: calc(100vh - 94px);
	overflow: auto;
	height: 100%;
	max-width: 1280px;
	margin: auto;
	.step-header-text {
		height: 55px;
		background-color: #f5f6f9;
		@include display-flex-center-mixin;
		margin: 30px 0 20px 0; }
	.cards-outer-cnt {
		width: 100%;
		max-width: 1120px;
		@include display-flex-space-between;
		margin: auto;
		margin-bottom: 50px;
		margin-top: 100px;
		border: dashed 1px #97979780;
		padding: 30px 41px;
		box-sizing: border-box;
		padding-left: 0;
		.value-proposition-header {
			width: 91px;
			@include display-flex-center-mixin;
			text-align: center;
			.value-proposition-text {
				transform: rotate(270deg);
				min-width: 100px;
				max-width: 100px; } }
		.cards-inner-cnt {
			width: 100%;
			@include display-flex-space-between; }
		.individual-card-cnt {
			max-width: 270px;
			min-width: 270px;
			position: relative; }
		.header {
			text-align: center;
			position: absolute;
			top: -65px;
			width: 100%;
			text-align: center; }
		.tool-items-list-card-cnt {
			position: relative; }
		.connector-line {
			position: absolute;
			width: 0;
			border-left: 1px dashed #7157A9CC;
			top: -41px;
			height: 100px;
			left: 134.5px; } } }
