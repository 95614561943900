@import '~styles/mixins.sass';

.outer-content {
    @extend %col-sm-10;
    @extend %col-md-10; }

.leaderboard-title {
    width: 100%;
    box-sizing: border-box;
    @include display-flex-start;
    padding-left: 12px;
    padding-bottom: 28px; }


.leaderboard-container {
    width: 100%;
    box-sizing: border-box; }

.leaderboard-list-container {
    width: 100%;
    @include display-flex-column;
    @include display-flex-center-mixin; }

.leaderboard-list {
    width: 100%;
    @include display-flex-column;
    align-items: center;
    overflow-y: auto;
    height: 100%;
    max-height: 585px; }

.leaderboard-list::-webkit-scrollbar-track {
	border-radius: 4px; }


.leaderboard-list::-webkit-scrollbar {
	width: 10px; }

.leaderboard-list::-webkit-scrollbar-thumb {
	border-radius: 4px; }

.list-item {
    width: 99%;
    height: 63px;
    min-height: 63px;
    @extend %row;
    @include display-flex-center-mixin;
    background-color: #353c54;
    margin-bottom: 2px;
    font-family: 'Open Sans';
    border-radius: 2px;
    padding: 0px; }

.list-user-item {
    width: 100%;
    padding: 6px;
    box-sizing: border-box; }

.list-legend {
    color: #979eb7;
    font-family: 'Open Sans';
    margin-bottom: 5px;
    @include display-flex-center-mixin;
    width: 97%;
    box-sizing: border-box;
    min-height: 17px; }

.legend {
    height: 0px;
    border: solid 1px #979797;
    opacity: 0.2;
    width: 45%; }

.legend-title {
    padding: 0 12px;
    cursor: pointer; }

.list-rank {
    width: 20%;
    box-sizing: border-box;
    @include display-flex-center-mixin;
    position: relative; }

.list-previous-rank {
    @include display-flex-start;
    padding-left: 3px;
    font-size: 14px; }

.list-name {
    width: 60%;
    padding-left: 80px;
    box-sizing: border-box;
    @include display-flex-start; }

.list-avatar {
    @include display-flex-end; }

.avatar-image {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background-color: white; }

.rank-variation-container {
    position: absolute;
    right: 53px;
    top: 5px;
    @include display-flex-center-mixin; }

.list-score {
    width: 20%;
    @include display-flex-center-mixin; }

.list-heading {
    width: 99%;
    min-height: 39px;
    height: 39px;
    @extend %row;
    @include display-flex-center-mixin;
    margin-bottom: 2px;
    font-family: 'Open Sans';
    border-radius: 2px;
    padding: 0px;
    text-transform: uppercase; }

.list-rank-heading {
    width: 20%;
    box-sizing: border-box;
    @include display-flex-center-mixin; }

.text-container {
    padding-left: 36px; }

.list-name-heading {
    width: 60%;
    padding-left: 143px;
    box-sizing: border-box;
    @include display-flex-start; }

.view-more {
    width: 100%;
    @extend %row;
    @include display-flex-center-mixin;
    font-family: 'Open Sans';
    line-height: 1.11;
    letter-spacing: normal;
    padding: 24px 0; }

.cursor-pointer {
    cursor: pointer; }

.arrow-up {
    width: 0;
    height: 0;
    border-left: 5.1px solid transparent;
    border-right: 5.1px solid transparent; }

.arrow-down {
    width: 0;
    height: 0;
    border-left: 5.1px solid transparent;
    border-right: 5.1px solid transparent; }
