@import '~styles/mixins.sass';

.loader-container {
    width: 100%;
    height: calc(100vh - 140px);
    @extend %row;
    @include center-all; }

.loader-content {
    @include col-journeyDashboard;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }

.loading-circle {
    margin-bottom: 25px; }

.loading-text {
    width: 588px; }

.loading-author {
    margin-top: 2px; }
