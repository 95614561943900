@import '~styles/mixins.sass';

.congratulations-message-container {
	width: 100%;
	padding-top: 30px;
	margin-bottom: 16px; }

.congratulations-message-inner {
	width: 100%;
	@extend %row;
	@include center-all; }

.inner-content {
	width: 100%;
	@extend %col-lg-8;
	@extend %col-md-10;
	@extend %col-sm-10;
	min-height: 297px;
	@include display-flex-center-mixin;
	flex-direction: column;
	border-radius: 4px;
	position: relative; }

.inner-celebrations {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0; }

.inner-main-content {
	position: absolute;
	z-index: 2;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	padding: 59px 104px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center; }

.certificate-image {
	width: 229px;
	height: 100%; }

.certificate-content-box {
	width: calc(100% - 229px);
	box-sizing: border-box;
	padding-left: 80px;
	height: 100%;
	text-align: left; }

.certificate-content {
	width: 100%;
	height: 100%; }

.title-content {
	width: 100%;
	margin-bottom: 10px; }

.title-desc {
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 25px; }

.download-button {
	padding-bottom: 2px; }

.action-btn-cnt {
	display: flex;
	justify-content: flex-start;
	align-self: center;
	> div {
		max-width: 270px;
		&:first-child {
			margin-right: 20px; } } }

@media only screen and (max-width: 480px) {
	.inner-main-content {
		padding: 20px; }
	.certificate-content-box {
		width: 100%;
		padding-left: unset; }
	.certificate-image {
		display: none; }
	.action-btn-cnt {
		flex-direction: column;
		align-items: center;
		> div {
			&:first-child {
				margin-right: unset; }
			&:nth-child(2) {
				margin-top: 8px; } } }
	.inner-content {
		min-height: 330px; } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
	.inner-main-content {
		padding: 20px; }
	.certificate-content-box {
		width: 100%;
		padding-left: unset; }
	.certificate-image {
		display: none; }
	.action-btn-cnt {
		flex-direction: column;
		align-items: center;
		> div {
			&:first-child {
				margin-right: unset; }
			&:nth-child(2) {
				margin-top: 8px; } } }
	.inner-content {
		min-height: 265px;
		flex-basis: unset !important;
		max-width: unset !important; } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
	.inner-main-content {
		padding: 20px; }
	.certificate-content-box {
		width: 100%;
		padding-left: unset; }
	.certificate-image {
		display: none; }
	.action-btn-cnt {
		flex-direction: column;
		align-items: center;
		> div {
			&:first-child {
				margin-right: unset; }
			&:nth-child(2) {
				margin-top: 8px; } } }
	.inner-content {
		min-height: 265px;
		flex-basis: unset !important;
		max-width: unset !important; } }
