.fullScreen {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }
.tool-container {
    position: fixed;
    z-index: 10001;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #f2f3f7;
    border: none; }
.product {
    width: 100%;
    position: relative;
    height: calc(100vh - 40px); }
.tool-header {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
.item {
    display: flex;
    box-sizing: border-box;
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px; }

.item:hover {
    transform: scale(1.05); }

.resp-container {
    width: 100%;
    height: 96vh;
    overflow: hidden;
    border: none; }
.icon {
    height: 13px;
    width: 13px;
    margin: 2px; }

.header-left {
    width: 90%;
    height: 100%;
    align-items: center;
    display: flex;
    background-color: rgba(0, 0, 0, 0.69);
    justify-content: flex-end; }

.support-btn-cnt {
    align-items: center;
    display: flex;
    background-color: rgba(0, 0, 0, 0.69);
    padding: 0 10px;
    width: fit-content;
    height: 100%;
    cursor: pointer; }

.support-image-cnt {
    height: 20px;
    width: 20px;
    margin: 0 5px;
    img {
        width: 100%;
        height: 100%; } }

.header-right {
    width: 10%;
    height: 100%;
    align-items: center;
    display: flex;
    padding-right: 40px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: #000000;
    justify-content: center; }

.exit-item {
    display: flex; }

.overlay {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    backdrop-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.69);
    position: fixed;
    z-index: 2; }

.loader-cnt {
    position: absolute;
    top: 0;
    width: 100%;
    height: 96vh;
    z-index: -100; }
