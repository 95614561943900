@import '~styles/mixins.sass';

.congratulations-message-container {
	width: 100%;
	padding-top: 30px;
	padding-bottom: 30px; }

.congratulations-message-inner {
	width: 100%;
	@extend %row;
	@include center-all; }

.inner-content {
	width: 100%;
	@extend %col-lg-8;
	@extend %col-md-10;
	@extend %col-sm-10;
	min-height: 155px;
	border-radius: 4px;
	box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.14);
	@include display-flex-center-mixin;
	flex-direction: column; }

.title-content {
	width: 100%;
	padding-bottom: 16px; }

.subtitle-content {
	width: 100%; }

.inner-background {
	width: 100%;
	height: 100%;
	@include display-flex-center-mixin;
	flex-direction: column; }

@media only screen and (max-width: 480px) {
	.inner-content {
		text-align: center; } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
	.inner-content {
		text-align: center; } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
	.inner-content {
		text-align: center;
		flex-basis: unset !important;
		max-width: unset !important; } }
