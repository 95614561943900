@import '~styles/mixins.sass';

.journeys-cnt {
	width: 100%;
	position: relative;
	background-color: white;
	min-height: 100vh;
	height: 100vh;
	box-sizing: border-box;
	.welcome-user-bg {
		width: 100%;
		height: 100px;
		position: relative;
		.welcome-bg {
			width: 100%;
			height: 100%;
			position: absolute; } } }

.catalogue-content {
	width: 100%;
	min-height: calc(100vh - 220px); }

.journeys-content {
	width: 100%;
	background-color: white;
	position: relative;
	.tabs-cnt {
		width: 100%;
		border-bottom: 2px solid rgba(87,86,86, 0.1);
		> div {
			width: 100%;
			max-width: 1280px;
			margin: auto;
			padding: 0 120px;
			box-sizing: border-box;
			height: 54px; } } }

.back-to-home {
	width: 100%;
	height: 100%;
	> div {
		width: 100%;
		max-width: 1280px;
		height: 100%;
		padding: 0 130px;
		box-sizing: border-box;
		margin: auto;
		@include display-flex-align-items-center; } }

.signup-header-cnt {
	width: 100%;
	background-color: black;
	height: 47px;
	min-height: 47px; }

.label-cnt {
	height: 100%;
	width: 100%;
	max-width: 1280px;
	margin: auto;
	padding: 0 130px;
	box-sizing: border-box;
	@include display-flex-align-items-center; }
