@import '~styles/mixins.sass';

.container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 62px;
    align-items: center;
    box-shadow: 1px 0 0 0 #d5d8e2;
    padding: 20px;
    box-sizing: border-box; }

.hamburger-icon {
    width: 100%;
    margin-bottom: 45px;
    margin-top: 45px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer; }

.icon {
    cursor: pointer; }

.text {
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    box-sizing: border-box;
    white-space: nowrap; }

.home-icon {
    @include display-flex-center-mixin;
    height: 18px;
    width: 20px;
    cursor: pointer; }
