@import '~styles/mixins.sass';

.leaderboard-container {
	width: 90%;
	margin: 0 auto;
	max-height: 1027px;
	border-radius: 4px;
	box-sizing: border-box; }

.no-data-container {
	@include display-flex-center-mixin;
	height: 420px; }

.title-search-container {
	width: 100%;
	height: 72px;
	box-sizing: border-box;
	@include display-flex-space-between; }

.title {
	width: 50%;
	margin: 24px; }

.search-container {
	width: 172px;
	margin: 22px 24px;
	position: relative;
	top: 0;
	left: 0;
	@include display-flex-end; }

.search-icon {
    width: 14px;
    height: 14px;
    position: absolute;
    left: 14px;
    top: 9px; }

.search-box {
	width: 172px;
	height: 32px;
	border-radius: 20px;
	// opacity: 0.2
	padding-left: 38px;
	// border: none
	outline: none;
	box-sizing: border-box;
	@include display-flex-start; }

.tabs-container {
	width: 100%;
	height: 34px;
	// padding-left: 15px
	box-sizing: border-box;
	border-top: 1px solid #EBEDF4;
	// border-bottom: 1px solid #d5d9e7
	@include display-flex-start; }

.individual-tab {
	color: blue;
	text-decoration: underline;
	cursor: pointer;
	height: 100%;
	box-sizing: border-box;
	margin: 0 5px;
	padding: 5px;
	@include display-flex-start; }

.individual-tab.selected {
	color: black;
	text-decoration: none;
	cursor: unset;
	border: 1px solid;
	border-bottom: none;
	border-radius: 4px 4px 0 0;
	background: white; }

.users-container {
	width: 100%;
	max-height: 915px;
	box-sizing: border-box;
	padding: 15px 24px;
	overflow-x: hidden;
	overflow-y: auto; }

.user-container {
	width: 100%;
	min-height: 40px;
	padding-left: 5px;
	box-sizing: border-box;
	@include display-flex-center-mixin; }

.user-container:last-child {
	border: none; }

.user-rank {
	width: 35px; }

.user-img {
	height: 24px; }

.avatar-img {
	width: 24px;
	height: 24px; }

.user-name {
	width: calc(100% - 90px);
	padding: 5px 10px;
	box-sizing: border-box;
	word-break: break-word; }

.user-score {
	width: 30px; }

.text-center {
	text-align: center; }

