@import '~styles/mixins.sass';

.user-journeys-cnt {
	width: 100%;
	position: relative;
	background-color: white;
	min-height: calc(100vh - 66px);
	padding-top: 115px;
	box-sizing: border-box;
	.welcome-user-bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 189px;
		z-index: 0;
		> div {
			height: 100%; } } }

.user-journeys-content {
	width: 100%;
	background-color: white;
	position: relative;
	min-height: calc(100vh - 217px);
	.tabs-cnt {
		width: 100%;
		border-bottom: 2px solid rgba(87,86,86, 0.1);
		> div {
			width: 100%;
			max-width: 1210px;
			margin: auto;
			padding: 0 100px;
			box-sizing: border-box;
			height: 54px; } } }

.journey-list-cnt {
	width: 100%;
	margin-bottom: 50px;
	.inner-cnt {
		width: 100%;
		max-width: 1210px;
		margin: auto;
		padding-top: 1px;
		box-sizing: border-box;
		.journeys-list {
			width: 100%;
			padding: 0 100px;
			box-sizing: border-box;
			margin-top: 50px;
			.list-header {
				font-family: Open Sans;
				font-size: 24px;
				line-height: 28px;
				color: #282828;
				font-weight: 500; }
			.list-content {
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
				> div {
					margin-left: 16px;
					margin-top: 22px;
					&:nth-child(4n+1) {
						margin-left: unset; } } } } } }

.request-new-journey {
	min-width: 325px;
	max-width: 325px;
	height: 230.5px;
	border-radius: 2px;
	box-sizing: border-box;
	@include display-flex-center-mixin; }


.back-to-home {
	width: 100%;
	position: absolute;
	top: 0;
	.journey-header {
		width: 100%;
		max-width: 1210px;
		padding: 0 100px;
		padding-top: 15px;
		box-sizing: border-box;
		margin: auto; } }

.crumbs-cnt {
	width: 100%;
	max-width: 1210px;
	padding: 0 100px;
	padding-top: 20px;
	box-sizing: border-box;
	margin: auto;
	@include display-flex-start;
	.journey-label {
		padding-left: 10px;
		box-sizing: border-box; } }

.user-welcome-note {
	height: 110px;
	display: flex;
	align-items: center;
	padding: 0 100px;
	width: 100%;
	max-width: 1210px;
	margin: auto;
	box-sizing: border-box; }
