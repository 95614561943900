.result-cnt {
    margin:	auto;
    border: 1px solid #B9B9B9;
    border-radius: 8px;
    width: 671px;
    margin-top: 50px; }

.result-top-section {
    box-sizing: border-box;
    padding: 14px 65px;
    display: block;
    position: relative; }

.title-details-cnt {
    display: block; }

.error-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    margin-top: 4px;
    padding-top: 1px;
    box-sizing: border-box;
    position: absolute;
    left: 32px;
    padding-left: 1px; }

.warning-icon-cnt {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 32px;
    margin-top: 4px;
    padding-top: 1px;
    padding-left: 1px; }

.resolution-cnt {
    box-sizing: border-box;
    padding: 14px 65px; }

.show-details-icon {
    position: absolute;
    top: 20px;
    right: 29px;
    cursor: pointer; }

.more-details-cnt {
    margin: 20px 0; }

.expand-arrow {
    transition: 600ms; }

@media only screen and (max-width: 480px) {
    .result-cnt {
        width: 350px;
        margin-top: 30px; }
    .result-top-section {
        padding: 14px 14px; }
    .title {
        padding-left: 24px; }
    .error-icon {
        left: 13px; }
    .resolution-cnt {
        padding: 14px 14px; } }
