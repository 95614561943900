@import '~styles/mixins.sass';

.container-class {
    width: 100%;
    padding-bottom: 50px;
    @include display-flex-center-mixin;
    @include display-flex-column; }

.outer-graph-container {
    width: 100%;
    @include center-all;
    @extend %row;
    padding-bottom: 100px; }

.graph-container {
    width: 100%;
    @extend %col-sm-11; }

.graph-content {
    @include center-all;
    @extend %row;
    padding-bottom: 50px; }

.title-header {
    display: flex;
    width: 100%; }

.heat-map-container {
    @extend %col-sm-10;
    @extend %col-md-6;
    @include display-flex-start;
    @include display-flex-column; }

.radar-graph-container {
    @extend %col-sm-10;
    @extend %col-md-6;
    @include display-flex-start;
    @include display-flex-column; }

.bar-graph-container {
    @extend %col-sm-10;
    @extend %col-md-7; }

.outer-dev-graph-container {
    width: 100%;
    @include center-all;
    @extend %row;
    padding-top: 100px; }

.dev-graph-container {
    width: 100%;
    @extend %col-sm-10; }

.dev-graph-content {
    @include center-all;
    @extend %row;
    display: flex;
    padding-right: 0;
    padding-bottom: 75px; }

.course-score-container {
    @extend %col-sm-10;
    @extend %col-md-6;
    @include display-flex-start;
    @include display-flex-column; }

.section-score-container {
    @extend %col-sm-10;
    @extend %col-md-6; }

.display-none {
    display: none; }

.tabs-container {
    width: 100%;
    padding: 0 138px;
    box-sizing: border-box;
    margin-bottom: 86px;
    display: flex; }

.tab-button {
    width: 300px;
    @include display-flex-center-mixin;
    margin-right: 15px;
    height: 50px;
    cursor: pointer; }

