@import '~styles/mixins.sass';

.analytics-card {
    height: 404px;
    max-width: 370px;
    padding: 7px 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; }

.card-graph {
    @include display-flex-center-mixin;
    width: 100%;
    height: 45%;
    // background-image: url('images/stat.png')
    background-position: center;
    background-size: 30% 60%;
    background-repeat: no-repeat; }

.card-content {
    width: 100%;
    height: 55%;
    text-align: center;
    font-size: 16px;
    color: #000000;
    opacity: 0.8;
    line-height: 1.88;
    overflow-y: auto; }

.card-content::-webkit-scrollbar {
    width: 6px; }

/* Track */
.card-content::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 6px; }

/* Handle */
.card-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 6px; }

/* Handle on hover */
.card-content::-webkit-scrollbar-thumb:hover {
    border-radius: 6px;
    background: #555; }
