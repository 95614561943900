@import '~styles/mixins.sass';

.widget-container-class {
	width: 100%;
	min-height: 283px;
	border-radius: 2.9px;
	position: relative;
	padding: 0 8.6px 0 10.2px;
	box-sizing: border-box; }

.widget-title {
	@include display-flex-center-mixin;
	align-items: flex-end;
	width: 100%;
	height: 51px;
	// padding-top: 24px
 }	// padding-bottom: 11px

.widget-content {
	width: 100%;
	@include display-flex-center-mixin;
	height: 170.8px;
	// padding-bottom: 26.8px
 }	// padding-top: 20.8px

.widget-main-content {
	@include display-flex-column;
	padding: 0 35px; }

.widget-description {
	width: 100%;
	height: 59px;
	@include display-flex-center-mixin;
	padding: 0 5px;
	text-align: left;
	box-sizing: border-box; }

.bg-logo {
	position: absolute;
	right: 0;
	bottom: -5px; }

.widget-rank-increase {
	@include display-flex-center-mixin; }

.arrow-up {
	margin-right: 10px;
	width: 0;
	height: 0;
	border-left: 5.1px solid transparent;
	border-right: 5.1px solid transparent; }

.arrow-down {
	margin-right: 10px;
	width: 0;
	height: 0;
	border-left: 5.1px solid transparent;
	border-right: 5.1px solid transparent; }

.score-rank-container {
	min-width: 170px;
	position: relative; }

.score-total-container {
	font-size: 25px;
	font-weight: 500;
	position: absolute;
	right: 0;
	bottom: -20px; }
