@import '~styles/mixins.sass';

.measures-container {
    width: 100%; }

.measures-feedback {
    width: 100%;
    min-height: 291px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #dedede;
    box-sizing: border-box; }

.feedback {
    width: 80%;
    margin-top: 5%;
    margin-bottom: 5%; }

.below-feedback {
    width: 100%;
    height: 10px;
    background-color: rgb(242, 243, 247); }
