.row {
    border-radius: 2px;
    background: #F2F3F7;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between; }

.icon {
    flex-basis: 5%; }

.detail-text {
    flex-basis: 70%;
    .capitalize {
        text-transform: capitalize; } }

.info-button {
    flex-basis: 20%;
    text-align: right;
    cursor: pointer; }

@media only screen and (max-width: 480px) {
    .icon {
        transform: scale(0.6) translateX(-10%) translateY(10%); }
    .more-details-cnt {
        margin: 10px 0; } }
