@import '~styles/mixins.sass';

.lock-container {
    @include display-flex-center-mixin;
    width: 50%;
    box-sizing: border-box;
    min-height: 150px;
    flex-direction: column;
    width: 290px; }

.lock {
    display: flex;
    width: 88px;
    height: 88px;
    background-color: rgba(9, 16, 39, 0.15);
    border-radius: 50%;
    align-items: center;
    justify-content: center; }

.lock-image {
    width: 28px; }

.lock-text {
    opacity: 0.4;
    margin-top: 24px;
    word-break: break-all;
    text-align: center; }
