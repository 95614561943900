.third-party-instructions-cnt {
    margin:	auto;
    box-sizing: border-box;
    padding: 20px 30px;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border: 1px solid #B9B9B9;
    border-radius: 8px; }

.title-details-cnt {
    flex-basis: 95%; }

.error-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
    margin-top: 4px;
    padding-top: 1px;
    box-sizing: border-box; }
