.exit-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }

.exit-message-container {
    width: 363px;
    height: 149px;
    background-color: #f9f9fb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; }

.buttons-holder {
    height: 63px;
    width: 363px;
    background-color: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }

.button-end {
    display: flex;
    justify-content: space-around;
    width: 40%;
    margin-right: 20px; }
