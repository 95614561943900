@import '~styles/mixins.sass';

.loader-container {
    height: 100%;
    width: 100%; }

.single-phase-container {
    width: 100%;
    @extend %row;
    @include center-all;
    padding-left: 0;
    padding-right: 0; }

.phase-column {
    height: 235px;
    @extend %row;
    @include col-start;
    @include phase-item-right;
    box-sizing: border-box; }

.item-column {
    @extend .phase-column;
    height: 190px; }

.single-phase-content {
    @extend .single-phase-container;
    padding: 25px 0;
    margin-bottom: 20px; }
