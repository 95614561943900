@import '~styles/mixins.sass';

.legend-container {
    @include display-flex-center-mixin;
    width: 100%;
    box-sizing: border-box;
    min-height: 27px;
    padding: 0 30px 85px 30px; }

.legend {
    height: 1px; }

.legend-title {
    @include display-flex-center-mixin;
    box-sizing: border-box;
    width: 20%;
    text-transform: uppercase; }
