@import '~styles/mixins.sass';

.duration-container {
    @include content;
    @extend %row;
    height: 285px;
    width: 100%;
    display: flex;
    flex-direction: column; }
.duration-container-row {
    @include content;
    @extend %row;
    display: flex;
    padding-left: 0;
    padding-right: 0;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px; }
.item-column {
    width: 100%;
    padding-left: 0;
    padding-right: 0; }
.item-row {
    @include duration-item;
    padding-left: 0;
    padding-right: 0; }

.score-component-cnt {
    display: flex;
    justify-content: center; }


.score-points {
	font-size: 34px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.6;
	letter-spacing: normal;
	text-align: center;
	color: #d44980; }

.score-text {
	font-size: 12px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.67;
	letter-spacing: normal;
	text-align: center;
	color: #d44980;
	text-transform: uppercase; }
