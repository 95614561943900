@import '~styles/mixins.sass';

.competency-tab-container {
    width: 100%;
    border-radius: 4px; }

.header-content {
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    padding: 0 39px 0 29px;
    box-sizing: border-box; }

.header-left {
    @include display-flex-center-mixin;
    height: 100%; }

.header-right {
    @include display-flex-center-mixin;
    height: 100%; }

.header-arrow {
    width: 24px;
    height: 24px; }

.header-title {
    padding-left: 10px;
    display: flex;
    align-items: center; }

.left-info {
    width: 15px;
    height: 15px;
    margin-left: 15px; }

.separation-line {
    width: 2px;
    height: 25px;
    margin-left: 25px;
    margin-right: 25px; }

.tab-content {
    width: 100%;
    min-height: 100px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-sizing: border-box;
    padding: 44px 53px 44px 60px;
    text-align: left; }

.competency-description {
    padding-bottom: 32px; }

.score-descriptors-heading {
    padding-bottom: 8px; }

.score-descriptors-desc {
    padding-bottom: 32px;
    display: flex;
    align-items: center; }

.score-descriptor-table {
    width: 45%; }

.measured-with-heading {
    padding-bottom: 8px; }

.measured-with-links {
    padding-bottom: 32px;
    display: flex;
    align-items: center; }

.behaviour-heading {
    padding-bottom: 8px; }

.score-descriptor-container {
    // display: flex
    // align-items: center
    // justify-content: flex-start
    padding: 12px 17px;
    width: 100%;
    box-sizing: border-box; }

.score-name-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%; }

.score-descriptor-dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: black; }

.score-descriptor-name {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box; }

.score-descriptor-name .name {
    padding-right: 15px;
 }    // width: 100%

.score-descriptor-score {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box; }

.score-descriptor-tip {
    // width: calc(60% - 15px)
    width: 80%; }

.behaviour-graph {
    width: 55%;
    height: 320px; }

.score-desc-showmore {
    margin-left: 14px; }
