@import '~styles/mixins.sass';

.score-snapshot-container {
    @include col-measures;
    padding-bottom: 64px; }

.score-title {
    width: 100%;
    text-align: left;
    padding-bottom: 10px; }

.score-sub-title {
    width: 100%;
    text-align: left;
    padding-bottom: 45px; }

.score-content {
    width: 100%; }

.competencies-color-legend-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 21px; }

.competency-single-legend {
    display: flex;
    align-items: center;
    justify-content: center; }

.competency-legend-dot {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: grey; }

.competency-legend-text {
    margin-left: 4px;
    margin-right: 40px; }

.table {
    width: 100%;
    margin-bottom: 30px; }

.table-row {
    width: 100%;
    height: 54px;
    @include display-flex-center-mixin; }

.table-left {
    width: 30%;
    height: 100%;
    @include display-flex-center-mixin;
    box-sizing: border-box;
    padding: 0 15px; }

.left-icon {
    width: 24px;
    height: 24px; }

.left-name {
    width: calc(100% - 39px);
    padding-left: 10px;
    box-sizing: border-box;
    text-align: left; }

.left-info {
    width: 15px;
    height: 15px; }

.heading-info {
    width: 15px;
    height: 15px;
    margin-left: 7px; }

.table-right {
    width: 70%;
    height: 100%;
    @include display-flex-center-mixin; }

.main-table-right {
    width: 70%;
    height: 100%;
    padding: 6px 0;
    box-sizing: border-box;
    @include display-flex-center-mixin;
    background-color: white;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }


.table-right-item {
    height: 100%;
    box-sizing: border-box;
    @include display-flex-center-mixin; }

.main-row {
    margin-bottom: 10px; }

.user-dot {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: grey; }

.desirable-range-container {
    // width: 60%
    height: 100%; }

.desirable-range-text {
    width: 100%;
    @inclulde display-flex-center-mixin {} }

.desirable-range-line {
    width: 100%;
    @include display-flex-center-mixin; }

.left-arrow, .right-arrow {
    width: 0;
    height: 0; }

.main-line {
    width: calc(100% - 16px);
    height: 2px; }
