@import '~styles/mixins.sass';

.overlay-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 20;
    overflow: hidden;
    @include noselect; }

.right-side-complete {
    position: absolute;
    width: 65%;
    min-width: 666px;
    max-width: 1024px;
    height: 100%;
    background-color: white;
    overflow-x: hidden;
    overflow-y: auto;
    top: 0;
    right: 0;
    z-index: 21; }

.close-button {
    position: absolute;
    z-index: 22;
    right: 45px;
    top: 30px;
    width: 32px;
    height: 32px; }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
    .right-side-complete {
        width: 100%; } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
    .right-side-complete {
        width: 100%;
        min-width: 550px;
        max-width: 100%; } }
