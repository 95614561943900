@import '~styles/mixins.sass';

.quiz-question-outer-cnt {
	position: relative; }

.quiz-questions-data {
	@extend %row;
	width: 100%;
	height: 100vh;
	padding-top: 64px; }

.question-list-cnt {
	@extend %col-xs-9;
	border: solid 1px #daddea;
	padding-left: 28px;
	padding-right: 28px;
	height: calc(100vh - 64px);
	overflow-y: auto; }

.question-navigator-cnt {
	@extend %col-xs-3;
	padding-left: 49px; }

@media only screen and (max-width: 480px) {
	.question-navigator-cnt {
		display: none; }
	.question-list-cnt {
		flex-basis: 100%;
		max-width: 100%;
		padding-left: 0px;
		padding-right: {}
		height: calc(100vh - 175px); }
	.question-data-cnt {
		padding-left: 10px;
		padding-right: 10px; }
	.quiz-questions-data {
		padding-top: 175px; } }

