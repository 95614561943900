@import '~styles/mixins.sass';

.journey-info-component-cnt {
	width: 100%;
	@extend %row;
	@include center-all;
	.journey-info-content-cnt {
		width: 100%;
		display: flex;
		@include col-journeyDashboard;
		padding-top: 30px;
		padding-bottom: 15px;
		padding-left: 0;
		padding-right: 0;
		margin: auto;
		flex-direction: column;
		.journey-name-cnt {
			width: 100%;
			@include display-flex-start;
			text-align: left;
			line-height: 1.1; }
		.journey-progress-cnt {
			width: auto;
			@include display-flex-start;
			flex-direction: column;
			align-items: flex-start;
			text-align: left;
			.progress-bar-cnt {
				height: 20px;
				width: 20px; }
			.expiry-date-cnt {
				margin-left: 0;
				text-wrap: nowrap; } }
		.journey-action-cnt {
			width: 100%;
			@include display-flex-start;
			height: 60px;
			.btn-cnt {
				margin-left: 20px; } } } }

.show-button-arrow {
	width: 12px;
	height: 8px;
	margin-left: 10px;
	cursor: pointer; }

.main-content {
	position: absolute;
	z-index: 2;
	padding-top: 42px;
	padding-bottom: 42px;
	@extend %row;
	@include center-all;
	box-sizing: border-box;
	width: 100%;
	height: 100%; }

.title-container {

	box-sizing: border-box;
	padding-top: 0;
	padding-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: left; }

.journey-info-content-outer-cnt {
	display: flex;
	flex-direction: row; }

.journey-info-content-inner-cnt {
	width: calc(100% - 205px);
	margin-right: 40px; }

.journey-upcoming-web-sessions-component {
	width: 320px;
	text-align: right;
	position: relative; }

.upcoming-websession-message {
	display: block;
	position: relative;
	padding-right: 13px; }

.upcoming-websession-title {
	display: block; }

.other-upcoming-websessions {
	display: block;
	cursor: pointer; }

.info-icon-cnt {
	height: 10px;
	width: 10px;
	position: absolute;
	right: 0;
	top: 2px;
	cursor: pointer; }

.session-tooltip-outer {
	position: absolute;
	z-index: 4; }


@media screen and (max-width: 480px) {
	.show-button-arrow {
		display: none; }
	.journey-info-content-outer-cnt {
		flex-direction: column !important; }
	.journey-info-content-inner-cnt {
		width: 100%; }
	.journey-progress-cnt {
		flex-direction: row !important; }
	.expiry-date-cnt {
		text-wrap: wrap !important; } }


@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
	.journey-info-content-cnt {
		flex-basis: unset !important;
		max-width: unset !important; }
	.expiry-date-cnt {
		text-wrap: wrap !important; } }
