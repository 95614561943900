@import '~styles/mixins.sass';

.container-class {
    width: 100%;
    @include display-flex-center-mixin;
    @include display-flex-column;
    padding-bottom: 94px; }

.outer-container {
    width: 100%;
    @include center-all;
    @extend %row; }

.outer-content {
    @extend %col-sm-10; }

.button-container {
    width: 100%;
    @include display-flex-space-between;
    padding-left: 28px;
    box-sizing: border-box; }
