@import '~styles/mixins.sass';

.assesment-container {
    width: 100%;
    height: calc(100vh - 59px);
    box-sizing: border-box;
    overflow-x: hidden;
    display: flex;
    flex-direction: column; }

.side-bar-product-container {
    display: flex;
    width: 100%;
    margin-bottom: -1px;
    position: relative;
    height: 100%; }

.menu-container {
    position: absolute;
    height: 100%;
    overflow: hidden;
    z-index: 2;
    background-color: white;
    box-shadow: 1px 0 0 0 #d5d8e2; }

.side-bar-collapsed {
    width: 71px;
    transition-duration: .5s;
    position: fixed;
    height: 100%;
    background-color: #ffffff;
    left: 0;
 }    // top: 67px
.assesment-items-collapsed {
    width: 100%;
    display: flex; }

.side-bar-expanded {
    width: 350px;
    transition-duration: .5s;
    height: 100%;
    position: fixed; }

.assesment-items-expanded {
	display: flex;
	margin-left: 350px;
	width: calc(100% - 350px);
	box-sizing: border-box; }

.content-row {
    @extend %row;
    @include center-all;
    height: 100%;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden; }

.feedback-scrollable-content {
    width: 100%;
    height: calc(100% - 159px);
    overflow-y: auto; }

.feedback-row {
    @extend %row;
    @include center-all;
    min-height: 544px;
    width: 100%; }

.feedback-col {
    @extend %col-lg-10;
    @extend %col-md-10;
    @extend %col-sm-10; }

.feedback-box-container {
    margin-top: 54px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }

.feedback-box-content {
    width: 100%;
    min-height: 195px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.14);
    overflow: hidden;
    border-radius: 4px; }

.main-feedback-box {
    width: 100%;
    height: 195px;
    position: relative; }

.main-feedback-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.main-feedback-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }

.main-feedback-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    padding-top: 30px; }

.show-details-button {
    width: 165px;
    box-sizing: border-box;
    padding: 15px 20px;
    @include display-flex-center-mixin;
    border-radius: 4px;
    text-transform: uppercase;
    margin-top: 40px; }

.assessment-result-text {
    padding-top: 30px; }

.feedback-main-heading {
    padding-top: 11px; }

.next-item {
    width: 330px;
    margin-top: 20px; }

.item-details-holder {
    width: 100%;
    @extend %row;
    padding: 0; }
