.page-content-cnt {
	margin: 25px 0px 40px 0px;
	overflow-x: hidden; }

.mark-complete-cnt {
	text-align: center; }

.mark-complete-btn {
	cursor: pointer; }

.completed-cnt svg {
	margin-right: 3px;
	margin-bottom: -1px; }

.page-content-cnt iframe {
	max-width: 100%; }
