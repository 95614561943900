@import '~styles/mixins.sass';

.content-container {
	width: 100%;
	// height: calc(100% - 194px)
	// overflow-y: auto
	padding-top: 110px;
	box-sizing: border-box;
	min-height: calc(100vh - 266px); }

.row-container {
	@extend %row;
	@include center-all; }

.title-container {
	@include col-journeyDashboard;
	box-sizing: border-box;
	padding-top: 0;
	padding-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.phase-content-container {
	width: 100%;
	@extend %row;
	@include center-all;
	position: relative;
	padding-top: 45px;
	padding-bottom: 85px;
	box-sizing: border-box;
	min-height: 400px; }

.tabs-container {
	position: absolute;
	top: -55px;
	left: 0;
	width: 100%;
	box-sizing: border-box;
	@extend %row;
	@include center-all;
 }	// padding: 0 10%

.tabs-content {
	@include col-journeyDashboard;
	display: flex;
	align-items: center;
	justify-content: flex-start; }

.individual-tab {
	width: 370px;
	height: 80px;
	@include display-flex-center-mixin;
	.tab-name {
		padding-left: 10px;
		box-sizing: border-box;
		text-overflow: ellipsis;
		white-space: nowrap;
		max-width: calc(100% - 28px);
		overflow: hidden; } }

.individual-single-tab {
	width: 370px;
	height: 80px;
	@include display-flex-start; }

.phase-lock-message {
	width: 100%; }

.no-items {
	padding: 20px; }


@media screen and (max-width: 480px) {
	.phase-content-container {
		padding-left: 10px;
		padding-right: 0px; } }
