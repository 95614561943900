@import '~styles/mixins.sass';

.question-data-cnt {
	padding: 68px 29px 79px 49px;
	border-bottom: solid 1px #d5d8e2; }

.question-number-row {
	display: flex;
	justify-content: space-between; }

.flag-and-number-cnt {
	display: flex;
	align-items: center; }

.flag-question-cnt {
	height: 14px;
	width: 15px;
	margin-right: 5px;
	cursor: pointer; }

.reset-icon-cnt {
	width: 15px;
	height: 15px;
	cursor: pointer;
	margin-right: 10px; }

@media only screen and (max-width: 480px) {
	.question-data-cnt {
		padding: 27px 10px 20px 15px; } }
