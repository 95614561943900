@import '~styles/mixins.sass';

.feedback-popup-cnt {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1002;
    .feedback-content-cnt {
        width: 80%;
        max-width: 1280px;
        min-height: 200px;
        max-height: 80vh;
        margin: auto;
        margin-top: 10vh;
        overflow-y: auto;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.19) 0px 14px 16px 0px;
        z-index: 10;
        position: relative;
        border-radius: 4px; }
    .scrollable-cnt {
        height: calc(80vh - 101px);
        overflow-y: auto;
        overflow-x: hidden; } }

.feedback-header, .feedback-subheaders {
    width: 92%;
    min-width: 855px;
    margin: 0 4%;
    text-align: left; }

.feedback-header {
    height: 100px;
    @include display-flex-align-items-center; }

.feedback-subheaders {
    margin-top: 25px; }

.feedback-questions-cnt {
    @include display-flex-center-mixin; }

.feedback-questions {
    width: 855px; }

.loader-cnt {
    @include display-flex-center-mixin;
    width: 75%;
    svg {
        width: 100%; } }

.close-popup-btn {
    position: absolute;
    top: 25px;
    right: 30px;
    width: 40px;
    height: 40px; }

.individual-question-cnt {
    width: 100%;
    max-width: 855px;
    min-height: 163px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    margin: 20px 0;
    padding: 24px;
    box-sizing: border-box; }

.feedback-action-cnt {
    width: 100%;
    max-width: 855px;
    @include display-flex-center-mixin;
    margin: 0 auto;
    margin-bottom: 50px;
    .skip-feedback-cnt, .submit-feedback-cnt {
        height: 48px;
        @include display-flex-center-mixin; }
    .skip-feedback-cnt {
        color: #d44980;
        cursor: pointer; }
    .submit-feedback-cnt {
        margin-left: 30px;
        text-transform: uppercase; } }
