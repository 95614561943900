@import '~styles/mixins.sass';

.reports-btn-cnt {
	// width: 100%
	display: flex;
	justify-content: flex-start; }

.share-on-linked-button {
	min-width: 192px;
	max-width: 192px;
	width: 100%;
	height: 48px;
	padding: 0 10px;
	box-sizing: border-box;
	border-radius: 5.8px;
	@include display-flex-center-mixin;
	cursor: pointer;
	.linked-in-logo {
		width: 20px;
		height: 20px;
		border-radius: 2px;
		margin-right: 10px; } }

.wait-msg-cnt {
	min-width: 200px;
	height: 46px;
	@include display-flex-center-mixin;
	margin-left: 20px; }

@media only screen and (max-width: 480px) {
	.share-on-linked-button {
		margin-top: 15px; } }
