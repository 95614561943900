.outer {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center; }

.success-text {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: rgb(48, 3, 36); }
