@import '~styles/mixins.sass';

@mixin stat-container {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    @include display-flex-column;
    align-items: flex-start;
    padding: 8px 0px;
    box-sizing: border-box; }

.container-class {
    width: 100%;
    padding-bottom: 50px;
    box-sizing: border-box; }

.outer-container {
    width: 100%;
    @include center-all;
    @extend %row; }

.inner-container {
    width: 100%;
    @extend %col-md-10;
    @include center-all;
    padding: 0px; }

.main-container {
    width: 100%;
    @include center-all;
    @extend %row;
    padding: 0px; }

.stats-inner-container {
    width: 100%;
    @extend %col-md-3;
    @extend %col-sm-6; }

.user-stats {
    height: 193px;
    @include stat-container;
    margin-bottom: 15px;
    position: relative; }

.journey-stats {
    height: 317px;
    @include stat-container;
    position: relative; }

.journey-stats-ass {
    height: 177px;
    @include stat-container;
    position: relative; }

.journey-stats-dev {
    height: 200px;
    @include stat-container;
    position: relative; }

.time-spent-container {
    width: 100%;
    @extend  %col-md-6;
    @extend %col-sm-10; }

.time-spent {
    height: 194px;
    display: flex;
    background-color: rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    padding-right: 8px;
    box-sizing: border-box; }

.time-spent-overall {
    width: 100%;
    @include display-flex-column;
    align-items: flex-start;
    padding: 16px 0px;
    box-sizing: border-box; }

.time-spent-phase {
    width: 100%;
    @include display-flex-column;
    align-items: flex-start;
    padding: 16px 0px;
    box-sizing: border-box; }

.finished-overdue {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }

.finish-overdue-ass {
    width: 49%;
    height: 180.7px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 4px 0px;
    box-sizing: border-box;
    position: relative; }

.finish-overdue-dev {
    width: 49%;
    height: 202.2px;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 4px 0px;
    box-sizing: border-box;
    position: relative; }

.active-days-container {
    width: 100%;
    @extend %col-md-3;
    @extend %col-sm-6; }

.active-day-progress-container {
    width: 100%;
    font-size: 24px;
    font-weight: 300;
    color: #4a69e2;
    text-align: left;
    @include display-flex-start;
    align-items: flex-start;
    @include display-flex-column;
    padding-bottom: 15px;
    padding-left: 13px;
    box-sizing: border-box; }

.active-days-progress-graph-container {
    width: 75%;
    padding-top: 5px;
    @include display-flex-space-between; }

.active-days-progress-bar {
    width: 6px;
    height: 29px; }

.active-days-progress {
    display: flex; }

.active-days {
    height: 120px;
    @include stat-container;
    margin-bottom: 11px; }

.active-time {
    height: 120px;
    @include stat-container;
    margin-bottom: 11px; }

.module-ass {
    height: 123px;
    @include stat-container;
    margin-bottom: 0; }

.module-dev {
    height: 143px;
    @include stat-container;
    margin-bottom: 0; }

.icon {
    width: 55px;
    height: 28px;
    box-sizing: border-box;
    padding-left: 28px;
    margin: 8px 0px; }

.title-container {
    width: 100%;
    @include display-flex-column;
    justify-items: flex-end; }

.bottom-title-container {
    width: 100%;
    @include display-flex-column;
    justify-items: flex-end; }

.title {
    width: 100%;
    @include display-flex-start;
    font-size: 12px;
    font-family: 'Open Sans';
    color: #575e79;
    padding: 6px 0 6px 28px;
    text-transform: uppercase;
    box-sizing: border-box; }

.title-separator {
    width: calc(100% - 24px);
    display: flex;
    @include center-all;
    border: solid 1px #2e344b;
    margin: 4px 12px 5px 12px; }

.vertical-separator {
    height: calc(100% - 24px);
    display: flex;
    @include center-all;
    border: solid 1px #2e344b;
    margin: 12px 0px; }

.content-container {
    width: 100%;
    align-items: flex-start;
    @include display-flex-column;
    font-size: 16px;
    font-family: 'Open Sans';
    color: #979eb7;
    box-sizing: border-box;
    padding-left: 18px;
    z-index: 1; }

.module-content-container {
    width: 100%;
    align-items: flex-start;
    @include display-flex-column;
    font-size: 12px;
    font-family: 'Open Sans';
    color: #4a69e2;
    box-sizing: border-box;
    padding-left: 18px;
    z-index: 1;
    justify-content: center;
    align-items: flex-start;
    height: 70%; }

.module-content-bold {
    font-size: 28px; }

.module-content {
    font-size: 28px;
    font-weight: 300; }

.content-bold-container {
    @include display-flex-column;
    @include display-flex-center-mixin;
    box-sizing: border-box;
    padding: 10px; }

.content-bold-container-mod {
    padding-right: 10px; }

.content-bold-mod {
    align-items: flex-start;
    opacity: 0.95;
    font-family: 'Open Sans';
    font-size: 24px;
    font-weight: 600;
    text-align: right;
    padding-right: 4px;
    padding-left: 10px;
    padding-bottom: 10px;
    box-sizing: border-box; }

.content-bold {
    align-items: flex-start;
    opacity: 0.95;
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 600;
    text-align: right;
    padding-right: 6px;
    padding-left: 10px;
    padding-bottom: 10px;
    box-sizing: border-box; }

.content {
    padding-bottom: 12px;
    box-sizing: border-box;
    display: flex;
    align-items: baseline; }

.content-column {
    @include display-flex-column; }

.content-text {
    box-sizing: border-box;
    padding-top: 4px;
    padding-left: 10px;
    font-family: 'Open Sans';
    font-size: 12px;
    color: #575e79;
    text-align: left; }

.bg-container {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 97px; }

.bg-logo {
    height: 100%; }
