@import '~styles/mixins.sass';

.container-class {
    width: 100%;
    padding-top: 50px;
    padding-left: 30px;
    box-sizing: border-box;
    @include display-flex-start;
    @include display-flex-column;
    align-items: flex-start; }

.title-container {
    padding-bottom: 20px; }

.outer-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    @include display-flex-space-between; }

.inner-content {
    width: 45%;
    min-width: 466px;
    padding: 0; }

