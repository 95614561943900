.sq-root-cb {
    background-color: white;
}

.sq-root {
	display: flex;
   flex-wrap: wrap;
   flex-basis: 50%;
   width: 100%;
}

.sv-nav-btn {
   display: none;
}

.sv-row {
    padding: 20px 29px 20px 49px
}
.sv-row:not(:last-child) {
    border-bottom: 1px solid #d5d8e2;
}

.sv-body {
    border-top: none !important;
}

.sv-header {
    background-color: #d44980;
    padding-left: 20px;
    opacity: 0.5;
    color: white !important;
}

.sv-progress {
    width: 50%;
    margin: auto;
    text-align: center;
}

.sv-progress-bar {
    min-width: 0px;
    background-color: pink;
    height: 100%;
}

.sv-nav {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.sq-title {
    font-size: 20px !important;
    font-family: Opensans;
}

.sq-title-required {
    color: red;
}

.sq-cb-input {
    margin-right: 10px !important;
    min-width: 16px !important;
    min-height: 16px !important;
}

.sq-cb-input:after {
	content: " ";
	background-color: #5C2799;
	display: inline-block;
	visibility: visible;
}
.sq-cb-input:checked:after {
	content: url("data:image/svg+xml, %3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 9 8'%3E%3Cpath fill='%232C4BB7' fill-rule='evenodd' d='M3.205 6.724L8.62.71a.527.527 0 0 1 .783.706L3.891 7.538a.527.527 0 0 1-.68.088.527.527 0 0 1-.688-.084L.393 5.177a.527.527 0 0 1 .784-.706l2.028 2.253z'%3E%3C/path%3E%3C/svg%3E");
	height: 16px;
	width: 16px;
	border-radius: 2px;
	background: #fff;
	margin-right: 16px;
	padding: 0 2px;
	box-sizing: border-box;
	color: #2C4BB7
 }

.sv-question:nth-last-child() {
    margin-bottom: 10px;
}

.sv-question {
    margin-bottom: 10px;
}

.sv-question fieldset {
    border: none;
    margin: 0px;
    padding: 0px;
}

.sq-option-row {
    display: flex;
    align-items: center;
    min-height: 56px;
	 padding: 4px 24px;
	 border-radius: 2px;
}


.sq-item {
	max-width: calc(50% - 10px);
	display: flex;
   align-items: center;
   min-height: 56px;
	margin: 8px 0px;
	padding-right: 0px !important
}

.sq-item:hover {
	background-color: rgba(213, 217, 231, 0.2)
}

.sq-item:nth-of-type(odd) {
	margin-right: 10px !important
}

.sq-item:nth-of-type(even) {
	margin-left: 10px !important;
}

.sv-rg-control-item {
	visibility: hidden;  
}

.sv-checked-option:hover {
	background-color: #2c4bb7 !important;
}