.fullscreen-options-cnt {
    height: 40px;
    background: rgba(0,0,0,0.75);
    color: #ffffff;
    display: flex; }

.fullscreen-options-cnt-inner {
    width: 170x;
    margin-left: auto;
    margin-right: 10px;
    padding-right: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center; }

.option {
    cursor: pointer; }

.scorm-cnt {
    width: 100vw;
    height: calc(100vh - 40px);
    border: none; }

.launched {
    position: fixed;
    z-index: 1000001;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgb(250, 250, 250); }

.launch-btn-cnt {
    display: flex;
    flex-direction: column;
    align-items: center; }

.scorm-embedded-iframe-cnt {
    height: calc(100vh - 400px);
    min-height: 480px; }

.scorm-embedded-iframe {
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    border: none; }

.exit-cnt {
    height: 16px;
    display: flex;
    width: 60px; }

.loader-cnt > div {
    height: 200px !important;
    width: 200px;
    margin: 0 auto; }
