@import '~styles/mixins.sass';

.skills-container {
	width: 100%;
	height: 700px;
	box-sizing: border-box;
	@extend %row;
	position: relative;
	overflow-x: hidden; }

.skills-background {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	@include display-flex-center-mixin;
	flex-direction: column; }

.skills-background-1 {
	width: 100%;
	height: 67%; }

.skills-background-2 {
	width: 100%;
	height: 33%; }

.skills-content {
	@extend %row;
	@include center-all;
	padding-top: 66px;
	padding-bottom: 80px;
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%; }

.skills-title {
	@include col-landingpage;
	@extend %row;
	@include center-all;
	padding-bottom: 60px; }

.cards-navigation-container {
	width: 100%;
	@extend %row;
	align-items: center;
	justify-content: space-around;
	padding-left: 0;
	padding-right: 0; }

.cards-container {
	@include col-landingpage;
	@include display-flex-center-mixin;
	// @extend %row
 }	// align-items: center

.all-cards {
	width: 85%;
	display: flex;
	padding-bottom: 10px;
	overflow-x: hidden;
	justify-content: space-between; }

.navigation-box-left {
	@include side-landingpage;
	@extend %row;
	padding: 0;
	@include col-end; }

.navigation-box-right {
	@include side-landingpage;
	@extend %row;
	padding: 0;
	@include col-start; }

.arrow {
	width: 40px;
	height: 40px;
	cursor: pointer; }

.arrow-disabled {
	pointer-events: none;
	cursor: default; }

.skill-card-container {
	height: 458px;
	width: 334px;
	min-width: 334px;
	padding: 0 15px;
	box-sizing: border-box;
	@include display-flex-center-mixin; }

.skill-loader-container {
	margin: 0; }

.mobile-skills-list-cnt {
	width: 100%;
	padding: 10px; }

.mobile-skill-item {
	margin-top: 10px; }

@media screen and (max-width: 480px) {
	.skills-container {
		height: unset;
		min-height: 150px; }
	.skills-content {
		justify-content: center;
		padding-top: 40px;
		padding-bottom: 30px; }
	.skills-title {
		height: 35px;
		padding-bottom: 30px; }
	.navigation-box-left {
		margin-bottom: 20px; }
	.navigation-box-right {
		margin-bottom: 20px; }
	.skills-content {
		height: unset;
		position: static; }
	.skills-background-1 {
		height: 100%; }
	.skills-background-2 {
		display: none; } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
	.skills-container {
		height: unset;
		min-height: 150px; }
	.skills-content {
		justify-content: center;
		padding-top: 40px;
		padding-bottom: 30px; }
	.skills-title {
		height: 35px;
		padding-bottom: 30px; }
	.navigation-box-left {
		margin-bottom: 20px; }
	.navigation-box-right {
		margin-bottom: 20px; }
	.skills-content {
		height: unset;
		position: static; }
	.skills-background-1 {
		height: 100%; }
	.skills-background-2 {
		display: none; } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
	.skills-container {
		height: unset;
		min-height: 150px; }
	.skills-content {
		justify-content: center;
		padding-top: 40px;
		padding-bottom: 30px; }
	.skills-title {
		height: 35px;
		padding-bottom: 30px; }
	.navigation-box-left {
		margin-bottom: 20px; }
	.navigation-box-right {
		margin-bottom: 20px; }
	.skills-content {
		height: unset;
		position: static; }
	.skills-background-1 {
		height: 100%; }
	.skills-background-2 {
		display: none; } }
