@import '~styles/mixins.sass';

.single-phase-container {
    width: 100%;
    @extend %row;
    @include center-all;
    padding-left: 0;
    padding-right: 0;
    position: relative; }

.single-phase-item-container {
    width: 100%;
    @extend %row;
    @include center-all;
    padding-left: 0;
    padding-right: 0; }

.left-side {
    @extend %row;
    @include center-all;
    @include phase-item-left; }

.left-side-line {
    position: relative;
    width: 1px;
    height: 100%; }

.main-phase-dot {
    position: absolute;
    z-index: 2;
    top: -8px;
    left: -8px;
    width: 16px;
    height: 16px;
    box-sizing: border-box; }

.main-phase-dot-outside {
    z-index: 1;
    position: absolute;
    left: -15px;
    top: -15px;
    width: 30px;
    height: 30px; }

.right-side {
    @extend %row;
    @include col-start;
    @include phase-item-right;
    box-sizing: border-box; }

.main-phase-box {
    width: 100%;
    // min-height: 269px
    box-sizing: border-box;
    padding: 30px 0;
    // padding-bottom: 30px
    display: flex;
    flex-direction: column; }

.title-container {
    width: 100%;
    // padding-bottom: 32px
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left; }

// .main-phase-box .title
//     // width: %
//     // padding-bottom: 32px

.duration-container {
    display: flex;
    align-items: center;
    justify-content: center; }

.calendar-image {
    width: 32px;
    height: 28px; }

.timeline-content {
    box-sizing: border-box;
    padding-left: 16px;
    text-align: left; }

.duration-text {
    padding-bottom: 2px; }

.main-phase-box .desc {
    width: 100%;
    // min-height: 50px
    padding-top: 20px;
    // padding-bottom: 40px
    text-align: left;
    p {
        margin: unset; } }

.main-phase-box .add-details {
    width: 100%;
    padding-top: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap; }

.intelligent-rec-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 20px; }

.intel-rec-image {
    width: 24px;
    height: 24px; }

.intel-rec-desc {
    padding-left: 16px;
    text-align: left; }

.sub-phase-dot {
    position: absolute;
    top: 45px;
    left: -10px;
    width: 20px;
    height: 20px;
    box-sizing: border-box; }

.sub-phase-last-dot {
    position: absolute;
    bottom: 0;
    left: -5px;
    width: 11px;
    height: 11px;
    box-sizing: border-box; }

.sub-phase-box {
    width: 100%;
    min-height: 100px;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 20px;
    text-align: left; }

.sub-phase-box-loader {
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    text-align: left;
    overflow: hidden;
    margin-top: -5px; }

.sub-phase-box-desc-with-duration {
    width: 85%;
    min-height: 100px;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 20px;
    text-align: left; }

.sub-phase-box-duration {
    width: 15%;
    min-height: 100px;
    box-sizing: border-box;
    padding-top: 40px;
    padding-bottom: 20px;
    text-align: left; }

.sub-phase-box .title {
    padding-bottom: 1px; }

.sub-phase-box .desc {
    padding-top: 15px;
    min-height: 80px; }

.journey-show-container {
	width: 100%;
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	bottom: 0;
	left: 0;
	padding-bottom: 20px; }

.journey-show-button-container {
    width: 220px;
    @include display-flex-center-mixin; }

.journey-show-button {
    @include display-flex-center-mixin;
    width: 177px;
    height: 48px;
    cursor: pointer; }

.journey-show-line {
    width: calc((100% - 220px)/2);
    height: 1px; }

.single-item-type {
    @include display-flex-center-mixin;
    box-sizing: border-box; }

.single-item-image {
    width: 25px;
    height: 25px; }

.single-item-text {
    padding-left: 10px;
    padding-right: 10px;
    text-transform: capitalize; }


@media screen and (max-width: 480px) {
    .left-side-line {
        display: none; }
    .sub-phase-box:last-child {
        padding-bottom: unset !important; } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
    .left-side {
        display: none; }
    .sub-phase-box:last-child {
        padding-bottom: unset !important; } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
    .left-side {
        display: none; }
    .sub-phase-box:last-child {
        padding-bottom: unset !important; } }
