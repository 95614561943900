@import '~styles/mixins.sass';

.heading-container {
    width: 100%;
    @extend %row;
    position: relative; }

.heading-image {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.product-title-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.heading-content {
    padding-top: 35px;
    padding-bottom: 35px;
    @extend %col-lg-10;
    @extend %col-md-10;
    @extend %col-sm-10;
    justify-content: flex-start;
    text-align: left;
    z-index: 2; }

.heading-sub-title {
    padding-bottom: 10px; }

.heading-title {
    padding-bottom: 5px; }

.back-button-container {
    @include phase-item-left;
    @include display-flex-center-mixin; }

.back-button-svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    z-index: 2; }
