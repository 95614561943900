.journey-feedback-heading {
    margin-left: 30px; }

.container {
    height: calc(100% - 5px); }

.list-outer-container {
    margin: 50px 50px 0px; }

.list-inner-container {
    height: 384px;
    overflow-y: auto;
    position: relative; }

.stats {
    display: flex; }

.stat {
    padding: 10px 0px;
    margin-right: 35px; }

.entity-row {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    height: 96px;
    align-items: center; }

.bottom-actions-container {
    margin: 10px 50px 0px;
    display: flex;
    justify-content: flex-end; }

.download-btn-container {
    width: 270px; }

.stepper-connector {
    width: 1px;
    position: absolute;
    top: 48px;
    left: 30px;
    z-index: 1; }

.connector-circle {
    height: 16px;
    width: 16px;
    margin-right: 12px;
    border-radius: 50%;
    z-index: 2; }


.entity-name-container {
    display: flex;
    align-items: center; }

@media only screen and (max-width: 480px) {
    .journey-feedback-heading {
        margin-left: unset; }
    .list-outer-container {
        margin: 0;
        margin-top: 15px; }
    .connector-circle {
        display: none; }
    .bottom-actions-container {
        margin: 0 auto; } }
