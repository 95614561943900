@import '~styles/mixins.sass';

.drop-down {
	width: 160px;
	border-radius: 4px;
	border: solid 1px #d5d8e2;
	background-color: #333950;
	color: rgba(255, 255, 255, 0.65);
	font-size: 16px;
	height: 32px;
	padding: 4px 12px;
	margin-left: 16px; }

.drop-down-option {
	&:hover {
		background-color: rgba(255, 255, 255, 0.1); } }
