@import '~styles/mixins.sass';

.journey-catalogue-cnt {
	width: 100%;
	max-width: 1280px;
	margin: auto;
	margin-bottom: 50px;
	.category-wise-journeys-cnt {
		margin-top: 35px;
		width: 100%;
		padding: 0 130px;
		box-sizing: border-box; }
	.journey-cards-cnt {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		> div {
			margin-left: 15px;
			margin-top: 22px; } }

	.journey-filters-and-search-cnt {
		width: 100%;
		min-height: 50px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid rgba(87, 86, 86, 0.1);
		box-sizing: border-box;
		padding: 0 130px 20px 130px;
		margin-top: 15px;
		.journey-filters-cnt {
			width: 100%;
			display: flex; }
		.journey-search-cnt {
			width: 262px;
			height: 38px;
			margin-left: 20px;
			input {
				width: 100%;
				height: 100%;
				padding: 0 10px;
				box-sizing: border-box;
				border: 1px solid rgba(87, 86, 86, 0.1);
				border-radius: 20px; } } } }

.no-journey-error-comp {
	width: 100%;
	min-height: 200px;
	.image-cnt {
		width: 275px;
		height: 199px;
		margin: auto; }
	.no-journey-label {
		@include display-flex-center-mixin;
		margin-top: 50px; } }

.bdPassportSearchAndFilter {
	flex-direction: row-reverse;
	justify-content: flex-end;
	gap: 65px; }

@media only screen and (max-width: 480px) {

	.journey-catalogue-cnt {
		.category-wise-journeys-cnt {
			padding: 0 30px; }
		.journey-filters-and-search-cnt {
			padding: 10px 15px 10px 15px;
			flex-direction: column-reverse;
			gap: 30px;
			align-items: flex-start;
			.journey-filters-cnt {
				flex-direction: column;
				& > div {
					margin-bottom: 10px; } }
			.journey-search-cnt {
				margin-left: unset;
				width: 100%; } }
		.journey-cards-cnt {
			> div {
				margin-left: unset; } } } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
	.journey-catalogue-cnt {
		.category-wise-journeys-cnt {
			padding: 0 30px; }
		.journey-filters-and-search-cnt {
			padding: 0 15px 10px 15px;
			flex-direction: column;
			.journey-filters-cnt {
				flex-direction: column;
				& > div {
					margin-bottom: 10px; } }
			.journey-search-cnt {
				margin-left: unset;
				width: 100%; } }
		.journey-cards-cnt {
			justify-content: center;
			> div {
				margin-left: unset; } } } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
	.journey-catalogue-cnt {
		.category-wise-journeys-cnt {
			padding: 0 50px;
			padding: 0 20px; }
		.journey-filters-and-search-cnt {
			padding: 0 15px 10px 15px;
			flex-direction: column;
			.journey-filters-cnt {
				flex-direction: column;
				& > div {
					margin-bottom: 10px; } }
			.journey-search-cnt {
				margin-left: unset;
				width: 100%; } }
		.journey-cards-cnt {
			justify-content: center;
			> div:nth-child(odd) {
				margin-left: unset; } } } }


@media only screen and (min-device-width : 1024px) {
	.journey-catalogue-cnt {
		.journey-cards-cnt {
			> div:nth-child(3n+1) {
					margin-left: unset; } } } }
