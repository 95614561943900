@import '~styles/mixins.sass';

.container-class {
    width: 100%;
    padding-bottom: 50px; }

.outer-graph-container {
    width: 100%;
    @include center-all;
    @extend %row; }

.graph-container {
    width: 100%;
    @extend %col-sm-11; }

.graph-content {
    @include center-all;
    @extend %row;
    display: flex; }

.heat-map-container {
    @extend %col-sm-10;
    @extend %col-md-6;
    @include display-flex-start;
    @include display-flex-column; }

.pie-graph-container {
    @extend %col-sm-10;
    @extend %col-md-6; }
