.video-player-container {
  width: 952px;
  height: 480px; }


.loader-container {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1; }
