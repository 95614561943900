@import '~styles/mixins.sass';

.org-list-item {
	width: 405px;
	display: flex;
	.org-name {
		min-width: 100px;
		max-width: 270px;
		padding: 5px;
		box-sizing: border-box; }
	.org-set-active {
		padding: 5px;
		margin-left: 5px; } }

.details-box {
    width: 100%;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 0 8px 10px 8px;
    margin-bottom: 20px;
    .details-header {
        height: 55px;
        width: 100%;
        @include display-flex-space-between;
        align-items: center;
        padding: 0 15px;
        box-sizing: border-box; }
    .details-content {
        width: 100%;
        @include display-flex-start;
        flex-wrap: wrap;
        border-top: 1px solid rgba(151, 151, 151, 0.4);
        padding: 20px 15px;
        box-sizing: border-box; } }

.org-status-action {
    padding: 5px;
    margin-left: 5px;
    text-align: center; }

.loader-cnt {
    width: 60px;
    height: 60px;
    margin: auto;
    margin-top: 100px; }

.organizations-list-cnt-overflow {
    max-height: 26vh;
    overflow-x: hidden;
    overflow-y: auto; }
