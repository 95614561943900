@import '~styles/mixins.sass';

.sidebar-feedback-item-cnt {
	width: 100%;
	min-height: 40px;
	padding: 0 7px;
	box-sizing: border-box;
	background-color: #f6f7f9;
	padding-top: 20px;
	box-sizing: border-box; }

.sidebar-feedback-item {
	width: 100%;
	height: 40px;
	border-radius: 4px;
	background-color: white;
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0 13px;
	box-sizing: border-box; }

.feedback-icon-cnt {
	height: 15px;
	width: 16px;
	margin-right: 10px; }
