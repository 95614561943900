@import './toastify.css';
@import 'https://webassets.knolskape.com/products-assets/fonts/fontFaces/Muktav13.css';
@import 'https://webassets.knolskape.com/products-assets/fonts/fontFaces/Merriweatherv30.css';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  position: relative;
  overflow: hidden;
  background: #ffffff;
}

.userInfoNavigation-enter {
  opacity: 0;
  transform: translate(100%, 0);
}

.userInfoNavigation-enter.userInfoNavigation-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: opacity .5s 0s ease-in, transform .5s 0s ease-in;
}

.userInfoNavigation-leave {
  opacity: 1;
  transform: translate(0, 0);
}
  
.userInfoNavigation-leave.userInfoNavigation-leave-active {
  opacity: 0;
  transform: translate(100%, 0);
  transition: opacity .5s 0s ease-in, transform .5s 0s ease-in;
}


.overlayOpacity-enter {
  opacity: 0;
}

.overlayOpacity-enter.overlayOpacity-enter-active {
  opacity: 1;
  transition: opacity .5s 0s ease-in;
}

.overlayOpacity-leave {
  opacity: 1;
}

.overlayOpacity-leave.overlayOpacity-leave-active {
  opacity: 0;
  transition: opacity .5s 0s ease-in;
}


@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
  .bp-container{
    height: calc(100vh - 450px) !important;
  }
}}

/* .freshwidget-dialog{
  top: -35px !important;
  transform: scale(0.85);
} */

.capitalize{
  text-transform: capitalize;
}

.cursor-pointer{
  cursor: pointer;
}

.adblocker-warning{
  margin: 20px;
  width: 671px;
}

.center-audio {
  display: block;
  margin: 0 auto;
}

.module-item-bottom-sidebar-open > .footer-cnt {
  padding-left: 25vw;
}

@media only screen and (max-width: 768px) {
  .mobile-widget-close {
    top: 1.5% !important;
    left: 88% !important;
  }
}