@import '~styles/mixins.sass';

.loader-container {
    height: calc(100vh - 140px);
    @extend %row;
    @include center-all; }

.loader-content {
    @include col-journeyDashboard;
    @include display-flex-center-mixin;
    flex-direction: column; }
