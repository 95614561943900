@import '~styles/mixins.sass';


.user-profile-and-options {
	width: 100%; }

.user-profile-container {
	width: 288px;
	z-index: 25;
	position: absolute;
	right: -15px;
	top: 30px; }

.individual-profile-section {
	width: 100%;
	font-size: 16px;
	height: 54px;
	padding-left: 25px;
	box-sizing: border-box;
	@include display-flex-center-mixin;
	justify-content: flex-start;
	cursor: pointer; }

.user-profile-section {
	width: 100%;
	height: 88px;
	@include display-flex-center-mixin;
	justify-content: flex-start;
	padding-left: 25px;
	box-sizing: border-box;
	cursor: default; }

.user-profile-image-container {
	height: 100%;
	@include display-flex-center-mixin;
	padding-right: 4px;
	box-sizing: border-box; }

.user-profile-image {
	height: 48px;
	width: 48px;
	border-radius: 100%;
	overflow: hidden; }

.user-profile-details-container {
	height: 100%;
	@include display-flex-center-mixin;
	flex-direction: column;
	padding-left: 4px;
	box-sizing: border-box; }


.user-profile-username {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 2px;
	width: 100%;
	text-align: left; }

.user-profile-email {
	opacity: 0.7;
	font-size: 14px;
	margin-top: 2px;
	width: 100%;
	text-align: left;
	padding-right: 15px;
	word-break: break-all;
	box-sizing: border-box; }

.arrow-container {
	width: 100%;
	padding-right: 20px;
	box-sizing: border-box;
	display: flex;
	box-sizing: border-box;
	justify-content: flex-end;
	align-items: flex-end; }

.arrow-up {
	width: 0;
	height: 0; }
