@import '~styles/mixins.sass';

.module-item-outer-cnt {
    @extend %row;
    padding: 0;
    min-height: calc(100vh - 124px); }

.module-item-inner-cnt {
    @extend %col-xs-10;
    @extend %col-lg-10;
    padding-left: 104px;
    min-height: calc(100vh - 20px);// - 165px)
    margin-left: 71px;
    margin-bottom: 40px;
    // margin: 0 auto
    // box-sizing: border-box
    // width: 100%
 }    // overflow-x: hidden

.module-item-loading {
    min-height: calc(100vh - 20px); }// - 165px)

.menu-container {
    @extend %col-xs-1;
    max-width: 71px;
    z-index: 2;
    background-color: white;
    transition-duration: .5s;
    position: fixed;
    height: 100vh;
    width: 71px; }

.sidebar-open .module-item-inner-cnt {
    @extend %col-xs-8;
    padding-left: 104px;
    margin-left: 340px; }

.sidebar-open .menu-container {
    @extend %col-xs-3;
    max-width: 340px;
    min-width: 340px;
    transition: width 500ms;
    padding: 0; }

.bottom {
    width: 100%;
 }    //z-index: 10

.locked-message {
    margin: 20px 0px;
    font-size: 20px; }

.all-content-row {
    @extend %row;
    width: 100%;
    flex-direction: column;
    min-height: calc(100vh - 67px); }

.feedback-header-cnt {
    height: auto;
    text-align: left;
    .feedback-header {
        margin: 10px 0; }
    .feedback-sub-header {
        display: flex;
        align-items: center; } }

.loader-cnt {
    @include display-flex-center-mixin;
    width: 100%;
    height: calc(100vh - 67px);
    svg {
        width: 100%; } }

.course-completion-action-cnt {
    @include display-flex-center-mixin;
    flex-direction: column;
    width: 100%;
    max-width: 855px;
    margin: 10px 0;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px rgba(151, 158, 183, 0.27);
    padding: 30px 0;
    box-sizing: border-box;
    .course-completion-msg-cnt {
        @include display-flex-center-mixin;
        .tick-mark {
            width: 48px;
            height: 48px;
            margin-right: 10px; } }
    .course-completion-cnt-action {
        @include display-flex-center-mixin;
        margin-top: 15px;
        margin-left: -7px;
        .go-to-measures-btn div {
            text-transform: uppercase; }
        .go-to-dashboard-btn {
            margin-left: 20px;
            cursor: pointer; } } }

.scorm-feedback-header {
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    border-bottom: 1px solid #d5d8e2;
    .back-to-home-button {
        height: 32px;
        border: solid 1px rgba(212, 73, 128, 0.8);
        display: flex;
        align-items: center;
        border-radius: 4px;
        margin-right: 15px;
        padding: 0 10px;
        cursor: pointer;
        &:hover {
            background-color: rgb(212, 73, 128); }
        &:hover div {
            color: white !important; }
        .back-icon-cnt {
            height: 11px;
            width: 7px;
            margin-right: 5px; } }
    .feedback-name-duration-cnt {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .feedback-icon-cnt {
            height: 14px;
            width: 14px; }
        .feedback-name-cnt {
            padding: 0 10px; } } }

.continue-learning-btn {
    margin-top: 15px; }

.next-item-btn-cnt {
    height: 67px; }

@media only screen and (max-width: 480px) {
    .module-item-inner-cnt {
        margin-left: 0;
        padding-left: 60px;
        max-width: 100%;
        flex-basis: 100%;
        width: 100%; }
    .menu-container {
        width: 60px;
        max-width: 60px; } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
    .module-item-inner-cnt {
        margin-left: 0;
        padding-left: 60px;
        max-width: 100%;
        flex-basis: 100%;
        width: 100%; }
    .menu-container {
        width: 60px;
        max-width: 60px; } }


@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
    .module-item-inner-cnt {
        margin-left: 0;
        padding-left: 60px;
        max-width: 100%;
        flex-basis: 100%;
        width: 100%; }
    .menu-container {
        width: 60px;
        max-width: 60px; } }
