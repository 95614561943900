@import '~styles/mixins.sass';

.single-phase-container {
    @include col-journeyDashboard;
    // width: 100%
    @include display-flex-center-mixin; }

.left-side {
    width: 105px;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    @include display-flex-center-mixin; }

.right-side {
    width: calc(100% - 105px); }

.timeline-left-container {
    z-index: 2;
    height: 100%; }

.individual-phase-timeline-container {
    padding: 16px 0;
    width: 100%;
    box-sizing: border-box;
    // cursor: pointer
    @include display-flex-center-mixin;
    height: 209px; }

.individual-phase-timeline-container.feedback {
    height: 74px; }

.individual-timeline-content {
    min-height: 177px;
    width: 100%;
    @include display-flex-center-mixin;
    height: 100%; }

.individual-timeline-item-selected {
    border-radius: 50%; }

.individual-timeline-item {
    width: 32px;
    height: 32px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 4px solid #f2f3f7;
    background-color: #4a90e2; }

.timeline-line {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 4px;
 }    // background-color: black

.individual-phase-item-container {
    padding: 16px 0;
    width: 100%;
    box-sizing: border-box; }

.individual-phase-item-container.feedbabck {
    height: 74px; }

.phase-feedback-link-cnt {
    height: 100%;
    max-width: 320px;
    background-color: white;
    border-radius: 28px;
    border: solid 1px rgba(151, 158, 183, 0.25);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 32px;
    &:hover {
        box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.11), 0 4px 16px 0 rgba(0, 0, 0, 0.06); } }

.individual-phase-item-container-report {
    padding-bottom: 16px;
    width: 100%;
    box-sizing: border-box; }

.individual-phase-item {
    width: 100%;
    height: 135px;
    box-sizing: border-box;
    padding: 24px 33px 24px 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
        box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.11), 0 4px 16px 0 rgba(0, 0, 0, 0.06); } }


.image-content-container {
    width: 65%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start; }

.image-container {
    width: 48px;
    height: 48px;
    @include display-flex-center-mixin;
    overflow: hidden;
    border-radius: 4px;
    margin-right: 18px; }

.content-container {
    width: calc(100% - 65px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: left; }

.button-container {
    padding: 15px 20px;
    text-transform: uppercase; }

.feedback-icon-cnt {
	height: 15px;
	width: 16px;
	margin-right: 10px; }
