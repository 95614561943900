@import '~styles/mixins.sass';

.individual-phase-item {
    width: 100%;
    height: 177px;
    box-sizing: border-box;
    padding: 18px 32px 20px 33px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    &:hover {
        box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.11), 0 4px 16px 0 rgba(0, 0, 0, 0.06); } }

.image-container {
    width: 129px;
    height: 100%;
    @include display-flex-center-mixin;
    overflow: hidden;
    border-radius: 4px;
    position: relative; }

.completed-tag {
    position: absolute;
    z-index: 2;
    width: 101px;
    height: 20px;
    top: 12px;
    left: 0;
    @include display-flex-center-mixin;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }

.locked-div {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    @include display-flex-center-mixin;
    border-radius: 4px; }

.locked-icon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); }

.content-container {
    width: calc(100% - 129px);
    height: 100%;
    box-sizing: border-box;
    padding: 5px 0 2px 16px;
    text-align: left; }

.title {
    padding: 10px 0px 5px;
    width: 100%;
    border-top: 1px solid rgba(60, 62, 69, 0.15);
    box-sizing: border-box; }

.desc {
    padding-bottom: 14px; }

.progress-container {
    height: 35px; }

.outer-bar {
    width: 100%;
    height: 8px;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 8px; }

.inner-bar {
    border-radius: 8px;
    height: 100%;
    min-width: 8px; }

.progress-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }

.text-bold {
    font-weight: bold; }

.progress-status-content {
    text-transform: uppercase;
    position: absolute;
    right: 0;
    top: 12px;
    padding: 0 5px;
    border-radius: 4px 0 0 4px; }

.metadata-cnt {
    display: flex;
    height: 20px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    .phase-item-icon {
        height: 15px;
        width: 15px; }
    .phase-item-sub-metadata {
        padding: 0 5px;
        text-transform: lowercase;
        &:first-letter {
            text-transform: uppercase; } }
    .phase-item-sub-metadata.border {
        border-left: 1px solid #ccc; } }

.item-details-outer-cnt {
    display: flex;
    padding-left: 1px;
    height: 100%;
    justify-content: flex-start;
    width: 100%;
    text-align: left;
    .item-details-cnt {
        width: 100%;
        padding-right: 24px; }
    .item-thumbnail-cnt {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 4px;
        overflow: hidden; } }

.desc > p {
	margin: unset; }
