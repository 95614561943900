@import '~styles/mixins.sass';

.star {
    height: 24px;
    width: 24px;
    cursor: pointer;
    margin: 0 4px; }

.rating-stars {
    min-width: 195px;
    height: 24px;
    @include display-flex-center-mixin;
    margin: 15px auto; }

.assesment-container {
    width: 100%;
    height: calc(100vh - 59px);
    box-sizing: border-box;
    overflow-x: hidden;
    display: flex;
    flex-direction: column; }

.side-bar-product-container {
    display: flex;
    width: 100%;
    margin-bottom: -1px;
    position: relative;
    height: 100%; }

.menu-container {
    position: absolute;
    height: 100%;
    overflow: hidden;
    z-index: 2;
    background-color: white;
    box-shadow: 1px 0 0 0 #d5d8e2; }

.side-bar-collapsed {
    width: 71px;
    transition-duration: .5s;
    position: fixed;
    height: 100%;
    background-color: #ffffff;
    left: 0;
 }    // top: 67px
.assesment-items-collapsed {
    width: 100%;
    display: flex; }

.side-bar-expanded {
    width: 350px;
    transition-duration: .5s;
    height: 100%;
    position: fixed; }

.assesment-items-expanded {
	display: flex;
	margin-left: 350px;
	width: calc(100% - 350px);
	box-sizing: border-box; }

.content-row {
    @extend %row;
    @include center-all;
    height: 100%;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden; }

.feedback-scrollable-content {
    width: 100%;
    height: calc(100% - 159px);
    overflow-y: auto; }

.feedback-row {
    @extend %row;
    @include center-all;
    min-height: 544px;
    width: 100%; }

.feedback-col {
    @extend %col-lg-10;
    @extend %col-md-10;
    @extend %col-sm-10; }

.feedback-box-container {
	width: 100%;
	// position: fixed
	// top: 65vh
 }	// right: 7vw

.feedback-box-content {
	position: relative;
	padding: 5px;
	background-color: #f9f9fb;
	box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.06), 0 10px 11px 0 rgba(0, 0, 0, 0.09);
	border: solid 0.5px rgba(212, 73, 128, 0.07); }

.main-feedback-box {
    width: 100%;
    height: 195px;
    position: relative; }

.main-feedback-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.main-feedback-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }

.main-feedback-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3; }

.show-details-button {
    width: 165px;
    box-sizing: border-box;
    padding: 15px 20px;
    @include display-flex-center-mixin;
    border-radius: 4px;
    text-transform: uppercase;
    margin-top: 40px; }

.assessment-result-text {
    padding-top: 25px;
    text-align: center;
    color: black; }

.feedback-main-heading {
    padding-top: 11px; }
