@import '~styles/mixins.sass';

.congratulations-message-container {
	width: 100%;
	padding-top: 30px; }

.congratulations-message-inner {
	width: 100%;
	@extend %row;
	@include center-all; }

.inner-content {
	width: 100%;
	@extend %col-lg-8;
	@extend %col-md-10;
	@extend %col-sm-10;
	min-height: 155px;
	@include display-flex-center-mixin;
	flex-direction: column;
	border-bottom: solid 1px #d5d9e7; }


.title-content {
	width: 100%;
	padding-bottom: 13px; }

.subtitle-content {
	width: 100%;
	// padding: 0 300px
	box-sizing: border-box; }

.report-svg {
	width: 34px;
	padding-bottom: 16px; }

.button-container {
	padding-top: 24px;
	padding-bottom: 44px;
	width: 100%;
	@include display-flex-center-mixin; }

.button-style {
	width: 288px;
	height: 48px;
	border-radius: 4px;
	text-transform: uppercase;
	@include display-flex-center-mixin; }
