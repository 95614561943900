@import '~styles/mixins.sass';

.overall-container {
    @include col-measures;
    padding-bottom: 64px; }

.overall-heading {
    width: 100%;
    padding-bottom: 32px;
    text-align: left; }

.overall-content {
    width: 100%;
    height: 175px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 24px;
    text-align: left; }

.overall-score {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }

.overall-desc {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }

.social-box {
    width: 15%;
    height: 100%; }

.desc-title {
    padding-bottom: 16px; }

.desc-sub-title {
    padding-bottom: 5px; }
