@import '~styles/mixins.sass';

.info-filters-container {
    width: 100%;
    min-height: 75px; }

.info-content {
    width: 100%;
    box-sizing: border-box;
    padding-left: 30px;
    text-align: left;
    padding-bottom: 10px; }

.title-content {
    width: 100%;
    padding-bottom: 15.4px; }

.subtitle-content {
    width: 100%;
    display: flex; }

.border-filter {
    border-right: solid 1px #979eb7; }

.logo-container {
    display: flex;
    padding-right: 6px; }
