@import '~styles/mixins.sass';

.next-plan-container {
    width: 100%;
    @extend %row;
    @include center-all; }

.next-plan-content {
    padding-top: 32px;
    padding-bottom: 38px;
    box-sizing: border-box;
    @include col-measures;
    @extend %row; }

.center-content {
    width: 240px;
    height: 100%;
    margin: 0 auto; }

.sub-title {
    padding-bottom: 15px; }

.title {
    padding-bottom: 5px; }

.button {
    width: 240px;
    height: 48px;
    @include display-flex-center-mixin; }
