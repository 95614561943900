
.tool-items-cnt {
	width: 100%;
	min-height: 85px;
	padding: 10px 4px 10px 14px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-height: 155px;
	// overflow: hidden
	position: relative;
	&:hover .full-screen-icon-cnt {
		display: flex; }
	&:hover .input-box-cnt .input-box {
		background-color: white;
		color: #00000080; }
	&:hover {
		.input-box::placeholder {
			color: #00000080; }
		.input-box::-moz-placeholder {
			color: #00000080; }
		.input-box:-ms-input-placeholder {
			color: #00000080; } } }

.items-list-scrollable-cnt {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	padding-right: 10px;
	box-sizing: border-box; }

/* width */
.items-list-scrollable-cnt::-webkit-scrollbar {
	position: absolute;
	right: -10px;
	top: 0;
	width: 2px; }

/* Track */
.items-list-scrollable-cnt::-webkit-scrollbar-track {
	border-radius: 3px; }

/* Handle */
.items-list-scrollable-cnt::-webkit-scrollbar-thumb {
	background: rgba(255, 255, 255, 0.3);
	border-radius: 3px; }

/* Handle on hover */
.items-list-scrollable-cnt::-webkit-scrollbar-thumb:hover {
	background: rgba(255, 255, 255, 0.2); }

.individual-item {
	width: 100%;
	// for multiple lines to be shown
	min-height: 35px;
	border-top: solid 1px rgba(255, 255, 255, 0.1);
	padding: 5px 0;
	box-sizing: border-box;
	cursor: pointer;
	//till here
	// height: 35px uncomment this and below code and comment above block for single line with elipsis
	display: flex;
	justify-content: space-between;
	align-items: center;
	&:first-child {
		border-top: none; }
	.text {
		// width: calc(100% - 20px)
		// white-space: nowrap
		// overflow: hidden
		// text-overflow: ellipsis
		width: 100%; }
	.close-btn-cnt {
		width: 15px;
		height: 15px;
		visibility: hidden; }
	&:hover .close-btn-cnt {
		visibility: visible; } }

.input-box-cnt {
	width: 100%;
	margin-top: 5px;
	padding-right: 10px;
	box-sizing: border-box;
	.input-box {
		width: 100%;
		border-radius: 16px;
		border: none;
		height: 31px;
		padding: 5px 10px;
		box-sizing: border-box;
		background-color: rgba(255, 255, 255, 0.1);
		outline: none;
		color: #ffffff99;
		&:focus {
			background-color: white;
			color: #00000080; } }
	.input-box::placeholder {
		color: #ffffff99; }
	.input-box::-moz-placeholder {
		color: #ffffff99; }
	.input-box:-ms-input-placeholder {
		color: #ffffff99; } }

.full-screen-icon-cnt {
	height: 29px;
	width: 29px;
	position: absolute;
	top: 0;
	right: -29px;
	display: none;
	align-items: center;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 0 15px 15px 0;
	cursor: pointer; }
