@import '~styles/mixins.sass';

.menu-footer-container {
    display: flex;
    width: 350px;
    height: 48px;
    align-items: center;
    justify-content: space-around; }

.menu-footer-container-no-elements {
    display: flex;
    width: 350px;
    height: 55px;
    box-shadow: none; }

.menu-footer-container-closed {
    @include display-flex-center-mixin;
    width: 100%;
    height: 66px;
    box-shadow: 0 0 4px 0 rgba(136, 146, 165, 0.28);
    cursor: pointer; }

.icon-open {
    width: 13px;
    height: 13px;
    margin-bottom: 5px; }

.icon-closed {
    width: 20px;
    height: 20px;
    margin-top: 10px;
    margin-bottom: 10px; }

.icon-and-text-container {
    @include display-flex-center-mixin;
    width: 50%;
    height: 32px;
    cursor: pointer;
    position: relative; }
.text {
    padding-left: 5px;
    box-sizing: border-box; }
.line {
    height: 34px;
    opacity: 0.1;
    border: solid 1px #979797; }

.icon-border {
    width: 51px;
    opacity: 0.1;
    border: solid 1px #979797; }

.tooltip {
	position: absolute;
	// min-width: 191px
	// max-width: 291px
	width: 170px;
	height: auto;
	bottom: 100%;
	left: 0;
	z-index: 35; }

.tooltip-arrow-container {
	width: 100%;
	height: 10px;
	@include display-flex-center-mixin; }

.tooltip-content {
    padding: 8px 12px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: black;
    word-break: break-word; }


@media screen and (max-width: 480px) {
    .menu-footer-container-closed {
        box-shadow: none;
        position: absolute;
        top: 68px;
        left: 1px; } }

@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait) {
    .menu-footer-container-closed {
        box-shadow: none;
        position: absolute;
        top: 68px;
        left: 1px; } }

@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
    .menu-footer-container-closed {
        box-shadow: none;
        position: absolute;
        top: 68px;
        left: 1px; } }
