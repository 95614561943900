.status-details {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 20px; }

.item-status {
    display: flex; }

.icon {
    height: 22px;
    width: 22px; }

.completed {
    padding-left: 10px;
    box-sizing: border-box; }

.completion-note {
    width: 100%;
    height: 100px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center; }
