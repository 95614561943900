@import '~styles/mixins.sass';

.content-container {
	width: 100%;
	min-height: 178px;
	position: relative; }

.main-content {
	position: absolute;
	z-index: 2;
	padding-top: 42px;
	padding-bottom: 42px;
	@extend %row;
	@include center-all;
	box-sizing: border-box;
	width: 100%;
	height: 100%; }

.main-background {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%; }

.main-background-image {
	position: absolute;
	right: 50px;
	bottom: 0;
	width: 219px;
	height: 151px; }

.title-container {
	@include col-journeyDashboard;
	box-sizing: border-box;
	padding-top: 0;
	padding-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: left; }

.description-container {
	@include col-journeyDashboard;
	box-sizing: border-box;
	padding-top: 0;
	padding-bottom: 5px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	text-align: left; }

.close-container {
	width: 12px;
	height: 12px;
	position: absolute;
	z-index: 3;
	top: 20px;
	right: 20px; }

.content-container-only-heading {
	@extend .content-container;
	min-height: 85px; }
