@import '~styles/mixins.sass';

.container-class {
    width: 100%;
    padding-bottom: 83px; }

.area-graph-container {
    width: 100%;
    @include center-all;
    @extend %row; }

.area-graph {
    @extend %col-md-10; }

.legend-container {
    @include display-flex-center-mixin; }

.legend-content:nth-child(2n+1) {
    padding-right: 24px; }

.legend-circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 5px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5); }
