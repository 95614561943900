@import '~styles/mixins.sass';

.feedback-scrollable-content {
    padding-top: 60px;
    width: 100%;
    // height: calc(100% - 159px)
    overflow-y: auto;
    box-sizing: border-box;
 }    // z-index: 2

.feedback-row {
    @extend %row;
    @include center-all;
    min-height: calc(100vh - 328px);
    width: 100%; }

.feedback-col {
    @extend %col-lg-10;
    @extend %col-md-10;
    @extend %col-sm-10;
    max-width: 1280px; }

.feedback-box-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }

.feedback-box-content {
    margin-top: 48px;
    width: 100%;
    min-height: 195px;
    box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.14);
    overflow: hidden;
    border-radius: 4px; }

.main-feedback-box {
    width: 100%;
    height: 195px;
    position: relative; }

.main-feedback-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.main-feedback-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2; }

.main-feedback-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3; }

.feedback-main-heading {
    padding-top: 11px; }

.item-details-holder {
    width: 100%;
    @extend %row;
    padding: 0; }

.feedback-questions-outer-cnt {
    position: fixed;
    bottom: 200px;
    right: 35px;
    z-index: 1000; }

@media screen and (min-width: 1440px) {
    .feedback-questions-outer-cnt {
        right: 130px; } }

.feedback-questions-inner-cnt {
	position: relative;
	padding: 5px;
	background-color: #f9f9fb;
	box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.06), 0 10px 11px 0 rgba(0, 0, 0, 0.09);
	border: solid 0.5px rgba(212, 73, 128, 0.07); }

.feedback-inner-cnt {
	position: relative;
	padding: 5px;
	background-color: #f9f9fb;
	box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.06), 0 10px 11px 0 rgba(0, 0, 0, 0.09);
	border: solid 0.5px rgba(212, 73, 128, 0.07); }

.close-feedback-button {
	position: absolute;
	height: 16px;
	width: 16px;
	top: -7px;
	right: -5px; }

.minimized-feedback {
	width: 34px;
	height: 34px;
	opacity: 0.8;
	border: solid 1px #d5d8e2;
	background-color: rgba(222, 224, 232, 0.51);
	border-radius: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer; }

.feedback-icon-cnt {
	width: 14px;
	height: 13px; }

.thank-you-note-cnt {
	display: flex;
	align-items: center; }

.tickmark-cnt {
	width: 24px;
	height: 24px;
	margin: 0 5px; }
