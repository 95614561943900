.container {
    width: 488px;
    display: flex;
    justify-content: space-between; }

.give-feedback-button {
    width: 171px;
    height: 48px;
    max-width: 250px;
    padding-bottom: 2px;
    box-sizing: border-box;
    & svg {
        margin-right: 8px;
        transform: translateY(4px); } }

.give-feedback-message {
    width: 258px;
    display: flex;
    align-items: center; }

@media only screen and (max-width: 480px) {
    .container {
        flex-direction: column;
        justify-content: end;
        align-items: center;
        width: 100%; }
    .give-feedback-message {
        margin-top: 15px; }
    .give-feedback-button {
        margin-left: -10%; } }


@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait) {
    .container {
        flex-direction: column;
        justify-content: end;
        align-items: center;
        width: 100%; }
    .give-feedback-message {
        margin-top: 15px; } }
