@import '~styles/mixins.sass';

.content-cnt {
	width: 100%;
	box-sizing: border-box;
	height: calc(100vh - 59px);
	overflow-y: auto;
	.content-header {
		width: 100%;
		position: relative;
		height: 110px;
		padding-top: 25px;
		box-sizing: border-box;
		.content {
			@include col-journeyDashboard;
			@include display-flex-align-items-center;
			padding: 0;
			margin:	auto;
			align-items: center;
			height: auto;
			margin-top: 8px; }
		.breacrumbs-cnt {
			@include col-journeyDashboard;
			padding: 0;
			margin:	auto;
			display: flex;
			align-items: flex-start;
			width: 100%;
			height: 20px;
			position: relative;
			span {
				padding: 0 10px; }
			span:not(:first-child) {
				border-left: 0.72px solid rgba(151, 151, 151, 0.3); }
			span:first-child {
				padding-left: unset; } } }
	.content {
		width: 100%;
		box-sizing: border-box;
		.journey-card {
			min-width: 325px;
			max-width: 325px;
			background: linear-gradient(180deg, #FFFFFF 0%, #FAFAFF 100%);
			border: 0.72px solid rgba(151, 151, 151, 0.158062);
			box-sizing: border-box;
			box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.163723);
			border-radius: 2px;
			padding: 0 6px;
			position: relative;
			.journey-info-cnt {
				margin: 10px 0;
				display: flex;
				align-items: flex-start;
				.journey-icon {
					width: 74px;
					height: 74px;
					@include display-flex-align-items-center;
					img {
						height: 100%;
						min-width: 74px;
						width: 74px;
						border-radius: 2px;
						object-fit: cover; } }
				.journey-name {
					width: 100%;
					height: 100%;
					margin-left: 14px;
					overflow: hidden; } }
			.download-btn-cnt {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				min-height: 100px;
				border-top: 1px solid #BFC3D2;
				padding: 10px 0;
				box-sizing: border-box;
				> div {
					margin: auto; }
				> div:first-child {
					margin-bottom: 15px; } } } } }

.journey-cards-cnt {
	width: 100%;
	@include display-flex-start;
	flex-wrap: wrap;
	box-sizing: border-box;
	> div {
		margin-left: 16px;
		margin-bottom: 20px;
		&:nth-child(3n+1) {
			margin-left: unset; } } }

.section-cnt {
	@include col-journeyDashboard;
	width: 100%;
	margin:	auto;
	.section-header {
		width: 100%;
		margin-top: 50px;
		margin-bottom: 30px; }
	.section-content {
		width: 100%; } }
