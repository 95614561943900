@import '~styles/mixins.sass';

.tooltip-header {
	border-right: 0.5px solid rgba(9, 16, 39, 0.2);
	font-size: 10px;
	opacity: 0.8;
	padding-right: 5px;
	width: 40%; }

.tooltip-content {
	width: 60%;
	padding: 4px; }
