.question-text {
    fontWeight: normal;
    color: #2e3447;
    lineHeight: 1.5; }

.answer-box-outer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1vw; }

.answer-text {
    height: 15vh;
    width: 40vw;
    max-width: 90%;
    font-size: 17px; }

.save-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: rgb(44, 75, 183);
    color: white;
    width: 5vw;
    height: 2vw;
    border-radius: 4px;
    cursor: pointer; }

.disabled {
    pointer-events: none;
    opacity: 0.5; }

@media only screen and (max-width: 480px) {
    .answer-box-outer {
        flex-direction: column;
        align-items: flex-start;
        gap: 1vh; }
    .answer-text {
        width: 100%;
        max-width: 100%; }
    .save-btn {
        height: 3vh;
        width: 15vw; } }
