@import '~styles/mixins.sass';

.container-class {
    @extend %row;
    width: 100%;
    @include center-all;
    text-align: left;
    height: 138px; }

.user-details-text-container {
    @extend %col-md-10;
    padding-top: 48px;
    height: 100%; }

.dashboard-info {
    width: 100%;
    opacity: 0.5;
    // font-family: 'Open Sans'
    font-size: 14px;
    color: #979eb7;
    box-sizing: border-box;
    padding-top: 12px;
    @include display-flex-space-between; }

.update-text {
    font-style: italic;
    opacity: 1; }
