@import '~styles/mixins.sass';

.module-item-card-cnt {
	padding: 0 32px 10px 32px;
	box-sizing: border-box;
	height: 142px;
	border-radius: 4px;
	margin-bottom: 40px;
	min-width: 800px;
	position: relative;
	text-decoration: none !important;
	cursor: pointer;
	&:hover {
        box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.11), 0 4px 16px 0 rgba(0, 0, 0, 0.06); } }

.thumbnail-cnt {
	height: 100%;
	width: 172px;
	min-width: 172px;
	position: relative;
	overflow: hidden; }

.thumbnail-cnt img {
	height: 100%;
	border-radius: 5px 0 0 5px; }

.module-item-metadata-cnt {
	@include display-flex-space-between;
	height: 40px;
	width: 100%;
	align-items: center;
	.module-item-metadata, .module-item-status-cnt {
		display: flex;
		align-items: center; } }

.module-type-cnt {
	display: flex;
	// padding-top: 4px
	box-sizing: border-box;
	.module-type-icon-cnt {
		margin-top: 3px;
		height: 15px;
		width: 15px; } }

.lock-msg-cnt, .due-date-cnt {
	line-height: 1.2;
	max-width: 450px;
	text-align: left; }

.module-item-details-cnt {
	min-width: 250px;
	width: 100%;
	padding-left: 24px;
	box-sizing: border-box;
	height: 100%; }

.item-desc-cnt {
	height: 100%;
	overflow: hidden; }

.module-item-metadata-row {
	height: 39px;
	min-height: 39px;
	@extend %row;
	padding: 0; }

.module-item-metadata-col {
	@extend %col-xs-6;
	line-height: 47px; }

.metadata-col-row {
	@extend %row;
	padding: 0; }


.separator {
	margin: 0 12px; }

.left-align {
	text-align: left; }

.right-align {
	text-align: right; }

.completion-status, .expired-msg-cnt {
	background-color: #76ca1a;
	color: white;
	height: 20px;
	border-radius: 4px;
	padding: 0 7px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 4px 0 0 4px;
	text-transform: uppercase;
	margin-right: -32px;
	display: flex;
	align-items: center; }

.expired-msg-cnt {
	background-color: rgb(154, 158, 175);
	text-transform: uppercase; }

.desc-and-competency-cnt {
	height: 91px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	text-align: left;
	width: calc(100% - 70px);
	padding-right: 20px;
	box-sizing: border-box; }

.bottom-border {
	height: 1px;
	width: 90%;
	margin-bottom: 3px; }

.row {
	@extend %row;
	padding: 0;
	height: calc(100% - 39px);
	overflow: hidden; }

.row.relative {
	position: relative; }

.bigger-module-icon-cnt {
	width: 84px;
	position: absolute;
	padding: unset;
	height: 82px;
	right: 20px;
	bottom: 20px;
	overflow: hidden; }

.bigger-module-icon {
	position: absolute;
	width: 100%;
	height: 100%; }

.item-image {
	width: 100%;
	height: 100%;
	border-radius: 4px; }

.item-title, .item-sub-title {
	height: 100%;
	display: flex;
	align-items: center; }

.competencies-cnt {
	@extend %row;
	padding: 0;
	height: 25px; }

.competency-capsule {
	height: 20px;
	border-radius: 10px;
	border: solid 1px #efefef;
	padding: 0 10px;
	margin-right: 6px;
	margin-bottom: 10px; }

.module-item-cicle-marker {
	position: absolute;
	height: 22px;
	width: 22px;
	border-radius: 100%;
	padding: 4px;
	top: 76px;
	left: -79px;
	z-index: 2;
	box-sizing: border-box; }

.module-item-cicle-marker div {
	border-radius: 100%;
	height: 22px;
	width: 22px; }

.module-item-cicle-marker div {
	border-radius: 100%; }

.recommended-cnt {
	display: flex;
	// padding-top: 4px
	box-sizing: border-box;
	.recommended-icon {
		margin-top: 3px;
		height: 13px;
		width: 12px; } }

.locked-div {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    @include display-flex-center-mixin;
    border-radius: 4px; }

.locked-icon {
	width: 26px;
	height: 32px; }

.completion-tag {
    color: white;
    height: 20px;
    border-radius: 4px;
    padding: 0 7px;
    box-sizing: border-box;
    position: absolute;
    top: 12px;
    left: 0px;
    border-radius: 0 4px 4px 0;
    text-transform: uppercase; }
