@import '~styles/mixins.sass';

.assesment-container {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column; }

.side-bar-product-container {
    display: flex;
    width: 100%;
    margin-bottom: -1px;
    position: relative;
    height: 100%; }

.menu-container {
    // position: absolute
    height: 100%;
    overflow: hidden;
    z-index: 2;
    background-color: white;
    box-shadow: 1px 0 0 0 #d5d8e2; }

.side-bar-collapsed {
    width: 71px;
    // position: fixed
    height: 100vh;
    background-color: #ffffff;
    left: 0;
    top: 0;
 }    // top: 67px
.assesment-items-collapsed {
    width: 100%;
    display: flex;
    margin-left: 71px;
    width: calc(100% - 71px); }

.side-bar-expanded {
    width: 350px;
    // height: 100vh
    // position: fixed
    top: 0;
    left: 0; }

.assesment-items-expanded {
	display: flex;
	margin-left: 350px;
	width: calc(100% - 350px);
	box-sizing: border-box; }

.content-row {
    @extend %row;
    @include center-all;
    height: 100%;
    flex-direction: column;
    overflow-y: hidden;
    padding-left: 0;
    padding-right: 0;
    z-index: 0; }

.all-content-row {
    width: 100%;
    min-height: calc(100vh - 67px);
    padding-left: 135px;
    box-sizing: border-box; }

.phase-feedback-questions-list {
    width: 100%;
 }    // height: calc(100% - 43px)

.save-feedback-btn-cnt {
    max-width: 855px;
    display: flex;
    justify-content: center; }

.question {
    @include item-feedback-ques;
    @include center-all; }

.feedback-header-cnt {
    height: auto;
    text-align: left;
    .feedback-header {
        margin: 10px 0; }
    .feedback-sub-header {
        display: flex;
        align-items: center; } }

.individual-question-cnt {
    width: 100%;
    max-width: 855px;
    min-height: 163px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08), 0 0 4px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    margin: 20px 0;
    padding: 24px;
    box-sizing: border-box; }

.feedback-action-cnt {
    width: 100%;
    max-width: 855px;
    @include display-flex-center-mixin;
    margin-bottom: 50px;
    .skip-feedback-cnt, .submit-feedback-cnt {
        height: 48px;
        @include display-flex-center-mixin; }
    .skip-feedback-cnt {
        color: #d44980;
        cursor: pointer; }
    .submit-feedback-cnt {
        margin-left: 30px;
        text-transform: uppercase; } }

.loader-cnt {
    @include display-flex-center-mixin;
    width: 100%;
    height: calc(100vh - 67px);
    svg {
        width: 100%; } }

.tickmark-cnt {
	width: 24px;
	height: 24px;
	margin: 0 5px; }

.completion-status-cnt {
    @include display-flex-center-mixin;
    width: 100%;
    max-width: 855px;
    min-height: 125px;
    position: relative;
    margin: 30px 0;
    border-radius: 4px;
    overflow: hidden;
    .completion-status-content {
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center; }
    .feedback-completion-tick-mark-cnt {
        width: 33px;
        height: 33px; }
    .completion-bg {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        min-height: 125px;
        width: 100%; } }

.phase-completion-action-cnt {
    @include display-flex-center-mixin;
    flex-direction: column;
    width: 100%;
    max-width: 855px;
    margin: 10px 0;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.06);
    border: solid 1px rgba(151, 158, 183, 0.27);
    padding: 30px 0;
    box-sizing: border-box;
    .phase-completion-msg-cnt {
        @include display-flex-center-mixin;
        .tick-mark {
            width: 48px;
            height: 48px;
            margin-right: 10px; } }
    .phase-completion-cnt-action {
        @include display-flex-center-mixin;
        margin-top: 30px;
        .go-to-measures-btn div {
            text-transform: uppercase; }
        .go-to-dashboard-btn {
            margin-left: 20px;
            cursor: pointer; } } }
