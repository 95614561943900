@import '~styles/mixins.sass';

.video-overlay-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 40;
    overflow: hidden;
    @include noselect;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 1; }

.video {
    height: calc(100vh - 59px);
    background-color: black;
    opacity: 1;
    width: 100%;
    display: flex;
    justify-content: center; }

.journey-title {
    margin-left: 30px; }

.menu-title {
    height: 44px;
    display: flex;
    width: 100%;
    cursor: pointer;
    opacity: 0.85;
    background-color: black;
    padding-left: 30px;
    padding-top: 15px; }

.icon {
    width: 24px;
    height: 24px; }
